import React, { useEffect, useState } from "react"
import moment from 'moment'
import Spinner from "components/utilities/spinner"
import { OrderListSaleByPage } from "api/order";
import SaleAdd from "./saleAdd";
import {ModalWithHtml} from "../Modal"

const PageVentas = ({ pageID }) => {
  const [orders, setOrder] = useState([])
  const [loaded, setLoaded] = useState(true)
  const [actionAdd, setAction] = useState(false)

  useEffect(() => {
    const InitialData = async () => {
      setLoaded(true)
      const result = await OrderListSaleByPage(pageID)
      if (result.orders) {
        setOrder(result.orders)
      }
      setLoaded(false)
    }

    InitialData()
  }, [pageID])

   const onDataInitial = async () => {
      setLoaded(true)
      const result = await OrderListSaleByPage(pageID)
      if (result.orders) {
        setOrder(result.orders)
      }
      setLoaded(false)
    }

    const viewDetail = async(orders) => {
        const html = (
            <div>
            <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
              <tbody className="bg-white divide-y divide-gray-200">
                {
                  orders.map((order, key) =>(
                    <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={key}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-10 h-10">
                            <img className="w-10 h-10" src={order.product.featured_images[0]?order.product.featured_images[0]:"https://picsum.photos/id/10/50/50"} alt="" />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{order.product.name}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.qt}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <small>S/.</small>
                        <strong>{Number.parseFloat(order.total).toFixed(2)}</strong>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
        </div>
        )
        await ModalWithHtml(html);
    }

    const onViewStatus = (status) => {
      let result;
      switch(status){
        case 'PENDING':{
          result = (<span className='inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 rounded-full'> En proceso </span>)
          break;
        }
        case 'APPROVED': {
          result = (<span className='inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full'> Aprobado </span>)
          break;
        }
        case 'REJECTED': {
          result = (<span className='inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full'> Rechazado </span>)
          break;
        }
        default: {
          result = (<span className='inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 rounded-full'> En proceso </span>)
        }
      }
      return (result)
    }

  return (
    <div className='bg-white p-4 sm:p-8'>
      {loaded ? (
        <Spinner />
      ) : (
        <>
          {
            actionAdd ?
            <SaleAdd pageId={pageID} onFinish={()=>{setAction(false); onDataInitial()}} />
            :
            <div className=''>
            <div className='actions bg-white flex items-center justify-between'>
              <div className='flex w-full gap-8 items-end'>
                <div className='flex-1 '>
                  <h3 className='font-bold text-gray-500 mb-1'>Buscar Ventas</h3>
                  <input aria-label='Buscar' className='w-full bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2' placeholder='Buscar Producto' type='search' value='' />
                </div>

                <div className='flex-1 flex'>
                  <button onClick={()=> setAction(true)} className='ml-auto bg-green-600 text-white hover:bg-red-600 rounded py-2 px-3 md:px-6'>Registrar nuevo</button>
                </div>
              </div>
            </div>
            <div className='overflow-x-auto'>
              <div className='inline-block min-w-full pb-2 align-middle'>
                <div className='border-b border-gray-200 rounded-md shadow-md'>
                  <table className='min-w-full overflow-x-scroll divide-y divide-gray-200'>
                    <thead className='bg-gray-50 text-xs text-left text-gray-500 uppercase'>
                      <tr>
                        <th scope='col' className='px-6 py-3'>
                          Nombre
                        </th>
                        <th scope='col' className='px-6 py-3'>
                          Fecha
                        </th>
                        <th scope='col' className='px-6 py-3'>
                          Estado
                        </th>
                        <th scope='col' className='px-6 py-3'>
                          Tipo de venta
                        </th>
                        <th scope='col' className='px-6 py-3'>
                          Precio
                        </th>
                        <th scope='col' className='px-6 py-3'>
                          Opciones
                        </th>
                      </tr>
                    </thead>
                    <tbody className='bg-white divide-y divide-gray-200'>
                      {
                        orders.map((order, key) => (
                          <tr className='transition-all hover:bg-gray-100 hover:shadow-lg' key={key}>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              <div className='text-sm font-medium text-gray-900'> {order.client.name} </div>
                              <div className='text-sm text-gray-500 italic'>Productos ({order.items})</div>
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              <div className='text-sm text-gray-900'>
                                <strong>{moment(order.createdAt).format('DD/MM/YYYY')}</strong>
                              </div>
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              {onViewStatus(order.status)}
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              <span> {order.sale_type==="ONLINE"?"Online":"Tienda"} </span>
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              Total <small>S/.</small>
                              <b>{Number.parseFloat(order.total).toFixed(2)}</b>
                            </td>
                            <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                              <div className=' items-center space-x-3'>
                                <button onClick={()=>viewDetail(order.orders)} className='cursor-pointer bg-blue-500 text-white flex w-full items-center justify-center px-3 py-1'>+ Detalle</button>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          }
        </>
      )}
    </div>
  )
}

export default PageVentas
