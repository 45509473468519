import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export const ModalConfirm = (action, msg, msgActionSucces='Fue eliminado exitosamente', msgActionRejected='No fue eliminado exitosamente') => {
    MySwal.fire({
        title: 'Atencion!',
        text: msg,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Acptar',
        cancelButtonText: 'Cancelar'
      }).then(async(result) => {
        if (result.isConfirmed) {
          const result = await action();
          if(result){
            await MySwal.fire(
              '',
              msgActionSucces,
              'success'
            )
          } else {
            await MySwal.fire(
              '',
              msgActionRejected,
              'error'
            ) 
          }
          
        }
      })
}

export const ModalWithHtml = (html) => {
    MySwal.fire({
      html,
      
    })
}

export const ModalCheck = (title, value, label, btnmsg, action) => {
  MySwal.fire({
    title: title,
    input: 'checkbox',
    inputValue: value,
    inputPlaceholder: label,
    confirmButtonText: btnmsg,
  }).then((result) => {
    if(result.isConfirmed){
      if(action) {
        action(result.value);
      }
    }
  })
}

export const ModalImage = (title, image) => {
  MySwal.fire({
    title: title,
    imageUrl: image,
    imageWidth: 500,
    imageHeight: 300,
    imageAlt: 'Custom image',
  })
}
