import React, { useEffect, useState } from "react"
import { PageGet } from "api/page"
import Spinner from "components/utilities/spinner"
import PageAdd from "./PageCreate"

import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa"

const PageSetting = ({ pageID, onChange }) => {
  const [page, setPage] = useState({})
  const [pageAction, setPageAction] = useState({ isActive: false, action: "" })
  const [loaded, setLoaded] = useState(true)

  const InitalData = async () => {
    setLoaded(true)
    const result = await PageGet(pageID)
    if (result) {
      setPage(result)
    }
    setLoaded(false)
  }

  useEffect(() => {
    const InitalData = async () => {
      setLoaded(true)
      const result = await PageGet(pageID)
      if (result) {
        setPage(result)
      }
      setLoaded(false)
    }
    InitalData()
  }, [pageID])

  return (
    <>
      {pageAction.isActive ? (
        <PageAdd
          action={pageAction}
          onFinish={() => {
            setPageAction({ isActive: false, action: "" })
            InitalData()
            onChange()
          }}
        />
      ) : (
        <div className='featured-item featured-item-inner bg-gray-100'>
          {loaded ? (
            <Spinner />
          ) : (
            <div className='bg-white p-4 sm:p-8'>
              <div className='left-area bg-white sm:col-span-3 mb-6'>
                <h3 className='font-bold text-xl mb-4'>{page.business_name}</h3>
                <div className='flex flex-col space-y-4'>
                  <address>
                    <FaMapMarkerAlt className='inline-block mr-2' /> {page.business_address}
                  </address>

                  <span>
                    <FaPhoneAlt className='inline-block mr-2' /> {page.business_phone}
                  </span>

                  <span>
                    <FaEnvelope className='inline-block mr-2' /> {page.business_email}
                  </span>
                </div>
              </div>

              <button
                className='bg-green-600 text-white hover:bg-red-600 rounded py-2 px-6 md:px-12'
                onClick={() => {
                  setPageAction({ isActive: true, action: "update", pageId: pageID })
                }}
              >
                Actualizar
              </button>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default PageSetting
