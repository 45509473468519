import Toast from "components/Toast";
import React, { createContext, useState } from "react";

export const ShoppingCartContext = createContext({
    products: [],
    total: {qt: 0, price: 0},
    count_products: 0,
    onAddProduct: () => {},
    onDeleteProduct: () => {}
});

export const ShoppingCartProvider = ({children}) => {
    const initialProduct = localStorage.getItem('shopping_cart')?JSON.parse(localStorage.getItem('shopping_cart')) : [];
    let previewTotal = {qt: 0, price: 0}
    for (const item of initialProduct) {
        previewTotal.qt += Number(item.qt);
        previewTotal.price += Number(item.price)
    }
    const [openCart, setOpen] = useState(false);
    const [products, setProduct] = useState(initialProduct);
    const [ProductsCount, setProductCount] = useState(initialProduct.length);
    const [total, setTotal] = useState(previewTotal);

    /* useEffect(() => {
        const checkAuth = async() => {
            const token = await localStorage.getItem("token");
            console.log(token)
            if(token){
                setProduct(true);
            }
          }
          checkAuth();
    }) */

    const onChangeTotal = async(data) => {
        let previewTotal = {qt: 0, price: 0}
        for (const item of data) {
            previewTotal.qt += Number(item.qt);
            previewTotal.price += Number(item.price)
        }
        setTotal(previewTotal)
        setProductCount(data.length)
    }

    const SetCart = (data) => {
        setProduct(data)
        localStorage.setItem('shopping_cart', JSON.stringify(data))
    }

    const onAddProduct = (data) => {
        const newProduct = [...products];
        const findProduct =  newProduct.find(product => product.name === data.name);
        const findPageId = newProduct.find(product => product.pageId === data.pageId)
        if(newProduct.length === 0 || (newProduct.length > 0  && findPageId)){
            if(findProduct) {
                const findIndexProd = newProduct.findIndex(product => product.name === data.name);
                newProduct[findIndexProd].price = Number.parseFloat(data.price)+Number.parseFloat(newProduct[findIndexProd].price);
                newProduct[findIndexProd].price = Number.parseFloat(newProduct[findIndexProd].price).toFixed(2);
                newProduct[findIndexProd].qt = Number.parseInt(data.qt+newProduct[findIndexProd].qt);
            } else {
                newProduct.push(data);
            }
            setOpen(true)
        } else {
            Toast('warning', 'No puede agregar productos de un Negocio distinto')
        }
        SetCart(newProduct)
        onChangeTotal(newProduct)
    };

    

    const onDeleteProduct = (index) =>{
        const newCart = products.filter((product,key) => key !==index);
       SetCart(newCart)
        onChangeTotal(newCart);
    };

    const onChangeQtProduct = (simb, key) => {
        const newProduct = [...products];
        if(newProduct[key].qt === 0 && simb === '-'){
        return
      }

        console.log("haber ", simb, key, newProduct[key],  simb === '+' ?newProduct[key].qt++: newProduct[key].qt--)
        newProduct[key].qt = simb === '+' ?newProduct[key].qt++: newProduct[key].qt--;

        newProduct[key].price = Number.parseInt(newProduct[key].priceUnit)*Number.parseInt(newProduct[key].qt);
        newProduct[key].price = Number.parseFloat(newProduct[key].price).toFixed(2);
        SetCart(newProduct)
        onChangeTotal(newProduct)
      
    }

    const onDeleteAllCart = () => {
        SetCart([])
        onChangeTotal([])
    };

    return (
        <ShoppingCartContext.Provider value={{openCart, setOpen, products, total, onAddProduct, onDeleteProduct, ProductsCount, onChangeQtProduct, onDeleteAllCart }}>
            {children}
        </ShoppingCartContext.Provider>
    )
}