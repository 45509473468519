import React, { useEffect, useState } from "react"
import Toast from "../Toast";
import {ProductListByPage} from "../../api/product"
import {UserList} from "../../api/user";
import {OrderRegisterSale} from "../../api/order"
 
const SaleAdd = ({pageId, onFinish}) => {
    const [clients, setClients] = useState([])
    const [productsSearch, setProductsSearch] = useState([])
    const [search, setSearch] = useState({client:"", product:""})
    const [products, setProduct] = useState([])
    const [client, setClient] = useState({name:"",address:"", phone:""})
    const [total,setTotal] = useState(0);

    const onAlert = (icon, msg) => {
        Toast(icon, msg);
    }
    
    useEffect(() => {
        const initialProduct = async() => {
            const result = await ProductListByPage(pageId, search.product, true,"");
            if(result.products && search.product.length > 0){
                setProductsSearch(result.products.map(product => ({
                    featured_image: '/images/affiliate-12.jpg',
                    ...product,
                    price: Number.parseFloat(product.price).toFixed(2),
                    priceUnit: Number.parseFloat(product.descount ? product.price_descount : product.price).toFixed(2),
                    total: Number.parseFloat(product.descount ? product.price_descount : product.price).toFixed(2),
                    mount_commission: Number.parseFloat(product.mount_commission).toFixed(2),
                })))
            }
            
        }
        if(search.product.length > 0){
            initialProduct()
        } else {
            setProductsSearch([])
        }
    },[search.product, pageId])

    useEffect(() => {
        const initialClient = async() => {
            const result = await UserList(search.client);
            if (result.users && search.client.length > 0) {
                setClients(result.users);
            }
        }
        if(search.client.length > 0){
            initialClient()
        } else {
            setClients([]);
        }
    },[search.client])

    const onChangeData = ({target: {value, name}}) => setSearch({...search, [name]: value})
    
    const onSelectClient = (data) => {
        setClient({id: data._id, name: data.name, address: data.address, phone: data.phone, email: data.email})
        onChangeData({target: {value: "", name: "client"}})
    }

    const onTotalSale = (data) => {
        let previewTotal = 0;
        for (const product of data) {
            previewTotal += Number.parseFloat(product.price)
        }
        setTotal(previewTotal);
    }
    const onAddProduct = (product) => {
        const productsNew =[...products];
        const findProduct = productsNew.find(data => data.name === product.name);
        if (findProduct) {
            const findIndexProd = productsNew.findIndex(product => product.name === product.name);
            productsNew[findIndexProd].price = Number.parseFloat(product.total) + Number.parseFloat(productsNew[findIndexProd].price);
            productsNew[findIndexProd].price = Number.parseFloat(productsNew[findIndexProd].price).toFixed(2);
            productsNew[findIndexProd].qt = Number.parseInt(1 + productsNew[findIndexProd].qt);
        } else {
            productsNew.push({...product,productId: product._id, qt: 1, price: product.total});
        }
        setProduct(productsNew)
        onChangeData({target: {value: "", name: "product"}})
        onTotalSale(productsNew)
    }

    const onDeleteProduct = (index) => {
        const productsNew = products.filter((product, key) => key !== index);
        setProduct(productsNew)
        onTotalSale(productsNew)
    }

    const onChangeQtProduct = (key, qt) => {
        const newProduct = [...products];
        newProduct[key].qt = qt;
        newProduct[key].price = Number.parseFloat(newProduct[key].priceUnit) * qt;
        newProduct[key].price = Number.parseFloat(newProduct[key].price).toFixed(2);
        console.log(newProduct[key])
        setProduct(newProduct)
        onTotalSale(newProduct)
    }

    const onSubmitData = async(e) => {
        e.preventDefault();
        try {
            if (client.name === "" || products.length === 0) {
                onAlert("warning", "Complete los datos por favor")
                return;
            }
            const data = {
                products,
                client: client.id,
                delivery_method: 'shopping',
                sale_type: 'PERSONAL',
                status: 'APPROVED',
                page: pageId,
                total,
                items: products.length,
            }
            console.log(data);
            const result = await OrderRegisterSale(data)
            if (result.saleId) {
                await onAlert('success', 'Venta realizada con exito');
                setTimeout(() => {
                    onFinish()
                }, [4800])
            } else {
                await onAlert('error', result.message)
            }
        } catch (error) {
            console.error(error.message)
        }
    }

    return(
        <>
            <div className=''>
                <div className='actions bg-white flex items-center justify-between'>
                    <div className='flex w-full gap-8'>
                        <div className='flex-1 '>
                            <h3 className='font-bold text-gray-500 mb-1'>Buscar Cliente</h3>
                            <input aria-label='Buscar' className='w-full bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2' placeholder='Buscar' type='search' value={search.client} name="client" onChange={onChangeData} />
                            {
                                clients.length > 0 &&
                                <ul className='results bg-gray-100 shadow-md divide-y divide-gray-300 text-sm'>
                                {
                                    clients.map((client, key) => (
                                    <li key={key} onClick={() =>onSelectClient(client)}>
                                        <span className='cursor-pointer hover:bg-gray-200 block py-2 px-4'>{client.name} - {client.code}</span>
                                    </li>
                                    
                                ))
                                }
                                </ul>
                            }
                            {
                                client.name !== "" &&
                                <div className='client-info text-gray-600 pt-4'>
                                <h4 className='text-base font-bold'>Cliente:</h4>
                                <p>{client.name}</p>
                                <p>{client.email}</p>
                                <p>{client.phone}</p>
                                <span className='city'>{client.address}</span>
                            </div>
                            }
                        </div>
                        <div className='flex-1'>
                            <h3 className='font-bold text-gray-500 mb-1'>Buscar Productos</h3>
                            <input aria-label='Buscar' className='w-full bg-gray-100 rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2' placeholder='Buscar Producto' type='search' value={search.product} name="product" onChange={onChangeData} />
                            {
                                productsSearch.length > 0 &&
                                <ul className='results bg-gray-100 shadow-md divide-y divide-gray-300 text-sm'>
                                   {
                                       productsSearch.map((product, key) => (
                                         <li key={key} onClick={()=>onAddProduct(product)}>
                                            <span className='cursor-pointer hover:bg-gray-200 block py-2 px-4'>{product.name} - S/ {product.total}</span>
                                        </li>
                                       ))
                                   }
                                </ul>
                            }
                        </div>
                    </div>
                </div>
                {
                    products.length > 0 &&
                    <div className='overflow-x-auto mt-4'>
                        <div className='inline-block min-w-full pb-2 align-middle'>
                            <div className='border-b border-gray-200 rounded-md shadow-md'>
                                <table className='min-w-full overflow-x-scroll divide-y divide-gray-200'>
                                    <thead className='bg-gray-50 text-xs text-left text-gray-500 uppercase'>
                                        <tr>
                                            <th scope='col' className='px-6 py-3'>
                                            Nombres
                                            </th>
                                            <th scope='col' className='px-6 py-3'>
                                            Cantidad
                                            </th>
                                            <th scope='col' className='px-6 py-3'>
                                            P. Unit
                                            </th>
                                            <th scope='col' className='px-6 py-3'>
                                            Total
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='bg-white divide-y divide-gray-200'>
                                        {
                                            products.map((product, key) => (
                                                <tr key={key} className='transition-all hover:bg-gray-100 hover:shadow-lg'>
                                                    <td className='px-6 py-4 whitespace-nowrap'>
                                                        <div className='text-sm font-medium text-gray-900'> {product.name} </div>
                                                    </td>
                                                    <td className='px-6 py-4 whitespace-nowrap'>
                                                        <div className='text-sm text-gray-900'>
                                                            <input className='w-14 bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 pr-1 py-2' value={product.qt} type='number' onChange={(e) => onChangeQtProduct(key, e.target.value)} />
                                                        </div>
                                                    </td>
                                                    <td className='px-6 py-4 whitespace-nowrap'>
                                                        <div className='text-sm text-gray-900'>
                                                            <small>S/.</small>
                                                            <strong>{product.priceUnit}</strong>
                                                        </div>
                                                    </td>
                                                    <td className='px-6 py-4 whitespace-nowrap relative'>
                                                        <small>S/.</small>
                                                        <b>{product.price}</b>
                                                        <button onClick={()=>onDeleteProduct(key)} className='text-xs cursor-pointer bg-red-500 text-white flex  items-center font-bold justify-center px-2  py-1 absolute right-1 top-1/4 mt-2'>x</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        <tr className='transition-all hover:bg-gray-100 hover:shadow-lg'>
                                            <td className='px-6 py-4 whitespace-nowrap'></td>
                                            <td className='px-6 py-4 whitespace-nowrap'></td>
                                            <td className='py-4 whitespace-nowrap text-right'>
                                            <button onClick={onSubmitData} className='bg-green-600 text-white hover:bg-red-600 rounded py-2 px-6 md:px-12'>Guardar Ahora</button>
                                            </td>
                                            <td className='px-6 py-4 whitespace-nowrap relative text-center'>
                                            <strong>Total</strong> <small>S/.</small>
                                                <b className='text-xl'>{Number.parseFloat(total)}</b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default SaleAdd;