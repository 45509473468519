import React, { useContext } from 'react';
import { AuthContext } from 'context/authContext';
import { Route,Redirect } from 'react-router-dom';

const PrivateRoute = ({children, ...rest}) => {
    const {isLoggedIn} = useContext(AuthContext)
    console.log(isLoggedIn)

    return (
        isLoggedIn?
            <Route {...rest}>
                {children}
            </Route>
        :
            <Redirect to='/login' />
    )
}

export default PrivateRoute