import React from 'react'

import MetaTags from 'react-meta-tags'

const Comofunciona = () => {
  return (
    <>
      <MetaTags>
        <title>¿Cómo funciona?</title>
        <meta name='description' content='' />
      </MetaTags>

      <section class='text-gray-700 body-font border-t border-gray-200'>
        <div class='container px-5 py-24 mx-auto'>
          <div class='flex flex-wrap w-full mb-20 flex-col  '>
            <h1 class='sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 text-center'>¿Cómo funciona?</h1>
            <br />
            <small>Última actualización: 13 de Diciembre de 2021</small>
            <br />
            <p class='leading-relaxed text-base mb-4'>
              <strong>Gane com</strong>prando y <strong>Gane com</strong>partiendo los productos y/o servicios que siempre as consumido y encuentra las mejores ofertas y promociones en los numerosos Locales Aliadas a Ganecom.
            </p>

            <h3 className='font-bold text-xl'>Ganecom</h3>
            <p className='mb-4'>La nueva manera de comprar lo de siempre, de ahorrar y a su vez te permite obtener ganancias por cada compra de bienes y/o servicios que realices, además te permite obtener ganancias extras al referir lo que consumes con las personas que conoces. </p>

            <h3 className='font-bold text-xl mb-3'>Pasos a seguir para una compra exitosa </h3>
            <h3 className='font-bold text-xl'>Compra online</h3>
            <p className='mb-4'>1.- localizas el producto de tu preferencia en HYPERLINK "http://www.ganecom.com" www.ganecom.com . </p>
            <p className='mb-4'>2.- llenas el formulario de tu pedido, seleccionando la forma de pago y entrega</p>
            <p className='mb-4'>3.- esto se reflejara en tu plataforma en la opción “mis pedidos” y veras en el estado que se encuentra para dar seguimiento</p>
            <p className='mb-4'>4.- al recibir el producto conforme a lo pactado con el Negocio (compra confirmada) se visualizara en tiempo real tus ganancias </p>

            <h3 className='font-bold text-xl'>Compra en físico </h3>
            <p className='mb-4'>1.-. Eliges el producto y/o servicio que este promocionado en HYPERLINK "http://www.ganecom.com" www.ganecom.com que más te gusta.</p>
            <p className='mb-4'>2.- Te apersonas al Negocio Aliada a Ganecom que promociona el producto de tu interés</p>
            <p className='mb-4'>3.- Al momento de realizar el pago te identificas con tu nombre de usuario y automáticamente recibirás un mensaje de “Compra Confirmada” y se visualizara en tiempo real tus ganancias</p>

            <h3 className='font-bold text-xl'>Estado de Cuenta Ganecom</h3>
            <p className='mb-4'>El Estado de Cuenta Ganecom es una plataforma en la cual podrás visualizar tus saldos hacer retiros en caso sea necesario Según vayas acumulando tus ingresos, podrás retirar el dinero de diversas formas: transferencia bancaria, cobro en efectivo, yape, giro, para lo cual ganecom no se hace cargo de las comisiones que puedan generar dichas transferencias ya que cada entidad maneja su propia política de transferencias.</p>

            <br />
            <i className='text-center'>
              Ganecom <strong>"gane comprando, gane compartiendo"</strong>
            </i>
          </div>
        </div>
      </section>
    </>
  )
}

export default Comofunciona
