import React from 'react';
import MetaTag from "react-meta-tags"

const MetaTags = ({titlePage, content}) => (
    <MetaTag>
        <title>{titlePage}</title>
        <meta name='description' content={content} />
    </MetaTag>
)

export default MetaTags
