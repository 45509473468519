import React, { useEffect, useState } from 'react'
import Toast from 'components/Toast'
import { ProductGet, ProductUpdateCommission } from 'api/product'
import Spinner from 'components/utilities/spinner'

const ProductCommission = ({pageID, onFinish, productid}) => {
  const [product, setProduct] = useState({productId:'',name: '', description: '', price: '', stock: 0,page: pageID, offer: false, descount: false, type_descount: '%', mount_descount: 0})
  const [loaded, setLoaded] = useState(true)
  
  useEffect(() => {
    const getProduct = async() => {
      await setLoaded(true)
      console.log(productid);
      const result = await ProductGet(productid);
      if(result._id){
        setProduct({
          ...result
        })
      }
      await setLoaded(false)
    }
      getProduct();
  }, [productid])

  const onAlert = async (icon, msg) => {
    Toast(icon, msg);
  }

  const onChangeData = ({target: {value, name}}) => {
    setProduct({
      ...product,
      [name]: value
    })
  }

  const onChangeDataCheck = ({target: {checked, name}}) => {
    setProduct({
      ...product,
      [name]: checked
    })
  }

  const onSubmitData = async(e) => {
    e.preventDefault();
    try {

      if(product.commission && product.mount_commission === 0){
        await onAlert('warning', 'Complete los datos de comision por favor');
        return;
      }
        const result = await ProductUpdateCommission(product, product._id);
        if(result.productId) {
          await onAlert('success', 'Comision del producto actualizado con exito');
          setTimeout(() => {
            onFinish();
          }, [4800])
        } else {
          await onAlert('error', result.message)
        }
      
    } catch (error) {
      console.log(error)
    }
  }
  
  return (
    <>
      <div className='featured-item featured-item-inner bg-gray-100'>
        <div className='container mx-auto px-4'>
          <div className='sm:grid sm:grid-cols-4 sm:gap-6'>
            <div className='left-area bg-white sm:col-span-3'>
             
           
            <div className='grid grid-flow-col gap-4'>
              {
                loaded?
                <Spinner/>
                :
                <form className='p-10'>
                <h1 className='sm:text-3xl text-2xl font-medium title-font mb-6'>Comision del producto {product.name}, negocio: {product.page.business_name}</h1>
               

               <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full px-3'>
                    <label className='inline-flex items-center mt-3'> <input aria-label='comision' type='checkbox' className='form-checkbox h-5 w-5 text-teal-600' name='commission' onChange={onChangeDataCheck} checked={product.commission}/><span className='ml-2 text-gray-700'>Comision por Venta</span> </label>
                  </div>
                </div>

                {
                  product.commission &&
                  <>
                    <div className='flex flex-wrap -mx-3 mb-6'>
                      <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                        <label className='inline-flex items-center mt-3'> <input name='type_commission' aria-label='type_commission' type='radio' className='form-radio h-5 w-5 text-blue-600' value='%' checked={product.type_commission=== '%'} onChange={onChangeData}/><span className='ml-2 text-gray-700'>Comision por %</span> </label>
                      </div>
                     {/*  <div className='w-full md:w-1/2 px-3'>
                        <label className='inline-flex items-center mt-3'> <input name='type_commission' aria-label='type_commission' type='radio' className='form-radio h-5 w-5 text-blue-600' value='S' checked={product.type_commission=== 'S'} onChange={onChangeData}/><span className='ml-2 text-gray-700'>Comision por venta</span> </label>
                      </div> */}
                    </div>

                    <div className='flex flex-wrap -mx-3 mb-6'>
                      <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='mount_commission'> Comision </label>
                        <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='mount_commission' type='number' placeholder='' name='mount_commission' value={product.mount_commission} onChange={onChangeData}/>
                      </div>
                    </div>
                  </>
                }


                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='flex justify-between w-full px-3'>
                    <button onClick={onSubmitData} className='bg-red-500 text-white font-bold py-2 px-4 w-full rounded hover:bg-red-600 border-b-4 border-red-700'>Publicar Ahora</button>
                  </div>
                </div>
              </form>
              }
            </div>


            </div>
          </div>
        </div>
      </div>
     
    </>
  )
}

export default ProductCommission
