import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}api/subcategories`,
    headers: {
        'content-type': 'application/json',
    }
})

export const SubCategoryRegister = async(data) => {
    const result = await api.post('/register', data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const SubCategoryList = async(category) => {
    const result = await api.get(`/list/${category}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const SubCategoryGet = async(subCategory) => {
    const result = await api.get(`/${subCategory}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const SubCategoryUpdate = async(data) => {
    const result = await api.put(`/${data.subCategoryId}`, data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const SubCategoryDelete = async (subCategoryId) => {
    const result = await api.delete(`/${subCategoryId}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}