import { ProductGetOffer } from 'api/product'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Spinner from 'components/utilities/spinner'
import { ShoppingCartContext } from 'context/shoppingCartContext'

const ProductOfferRandom = () => {
  const [product, setProduct] = useState({})
  const [loaded, setLoaded] = useState(true)
  const history = useHistory()

  const { onAddProduct } = useContext(ShoppingCartContext)

  useEffect(() => {
    const InitialData = async () => {
      setLoaded(true)
      const result = await ProductGetOffer()
      console.log('result offer ', result.products)
      if (result?.products?.length > 0) {
        setProduct({
          ...result.products[0],
          featured_image: result.products[0].featured_images[0] ? result.products[0].featured_images[0] : 'https://picsum.photos/id/10/50/50',
          price: Number.parseFloat(result.products[0].price).toFixed(2),
          total: Number.parseFloat(result.products[0].descount ? result.products[0].price_descount : result.products[0].price).toFixed(2),
          mount_commission: Number.parseFloat((result.products[0].commission_product * 40) / 100).toFixed(2),
        })
      }
      setLoaded(false)
    }
    InitialData()
  }, [])

  const AddCart = async () => {
    const cart = { productId: product._id, name: product.name, description: product.description, priceUnit: product.total, price: Number.parseFloat(product.total).toFixed(2), image: product.featured_image || 'images/affiliate-12.jpg', qt: 1, userId: localStorage.getItem('user'), pageId: product.page._id }
    onAddProduct(cart)
  }

  return (
    <div className='featured-item bg-gray-100'>
      {loaded ? (
        <Spinner />
      ) : (
        <div className='container mx-auto px-4'>
          <div className='bg-white lg:items-center md:flex'>
            <div className='thumb w-full max-w-4xl relative'>
              <img className='w-full h-full object-cover' src={product.featured_image} alt='' />

              <div className='caption max-w-xl absolute bottom-3 left-3 text-white bg-gray-500 bg-opacity-80 p-3'>
                <p>{product.name}</p>
              </div>
            </div>
            <div className='info p-6 xl:p-8'>
              <h3 className=' font-bold text-2xl leading-none mb-3'>{product.name}</h3>
              {/* <p className='text-gray-500 mb-4'>{product.description}</p> */}

              <div className='price flex items-center justify-between mb-4'>
                <div className='left mr-2'>
                  <strong className='price text-2xl'>S/ {product.total}</strong>
                  {product.commission && (
                    <strong className='block text-sm xl:text-lg whitespace-nowrap'>
                      Gane <span className='text-yellow-500'>S/ {product.mount_commission}</span>
                    </strong>
                  )}
                </div>

                <div className='right flex'>
                  <button
                    onClick={() => {
                      history.push(`/detalle/${product.name.replace(/ /g, '-')}`, { productId: product._id })
                    }}
                    className='more-details block bg-gray-200 hover:bg-gray-600 hover:text-white text-gray-700 py-2 px-1 border-b-4 border-red-700 hover:border-gray-500 rounded transition duration-300 ease-in-out'
                  >
                    Más detalles
                  </button>

                  <button onClick={AddCart} className='bg-red-500 hover:bg-red-600 text-white py-2 px-2 border-b-4 border-red-700 hover:border-red-500 rounded transition duration-300 ease-in-out ml-2'>
                    Comprar
                  </button>
                </div>
              </div>

              <div className='values bg-gray-200 rounded px-5 py-3 flex items-center justify-between text-center uppercase mb-5'>
                <div className='item'>
                  <strong className='block lg:text-lg'>S/ {product.price}</strong> <small className='block text-gray-500'>Precio</small>
                </div>
                <div className='item'>
                  <strong className='block lg:text-lg'>% {product.mount_descount}</strong> <small className='block text-gray-500'>Descuento</small>
                </div>
                <div className='item'>
                  <strong className='block lg:text-lg'>S/ { Number.parseFloat(product.price - product.total).toFixed(2)}</strong> <small className='block text-gray-500'>Ahorro</small>
                </div>
              </div>

              {/* <div className='count text-center'>
                <h5 className='uppercase text-sm lg:text-base mb-2 text-gray-500'>Date prisa, solo quedan algunas ofertas</h5>

                <div className='time flex items-center justify-center'>
                  <img className='mr-2' width='20' height='20' src='/images/icons/reloj.svg' alt='' />
                  <div className='text-xl'>
                    8 <span className='text-gray-400'>días /</span> 20 <span className='text-gray-400'>min. /</span> 58 <span className='text-gray-400'>sec.</span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductOfferRandom
