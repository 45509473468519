import React from "react"
import moment from "moment";

const PageInit = ({credit, sales, products, orders}) => {
  const productActive = products.filter(product => product.active);

    return (
        <>
        <div className="bg-white">
        <div className="p-4 sm:p-8">
                    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mb-8">
                      <div className="p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-green-500 text-white">
                        <div className="flex items-start justify-between">
                          <div className="flex flex-col space-y-2">
                            <strong className="text-xl font-bold">S/ {parseFloat(credit).toFixed(2)}</strong>
                            <span className="text-sm">Saldo disponible</span>
                          </div>
                          <div className="p-8 bg-gray-200 rounded-md"></div>
                        </div>
                      </div>
                      <div className="p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-gray-100">
                        <div className="flex items-start justify-between">
                          <div className="flex flex-col space-y-2">
                            <strong className="text-xl font-bold text-green-600">S/ {parseFloat(sales).toFixed(2)}</strong>
                            <span className="text-sm">Ventas realizadas</span>
                          </div>
                          <div className="p-8 bg-gray-300 rounded-md"></div>
                        </div>
                      </div>
                      <div className="p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-gray-100">
                        <div className="flex items-start justify-between">
                          <div className="flex flex-col space-y-2">
                            <strong className="text-xl font-bold text-blue-700">{productActive.length}</strong>
                            <span className="text-sm">Productos Activos</span>
                          </div>
                          <div className="p-8 bg-gray-300 rounded-md"></div>
                        </div>
                      </div>
                    </div>

          <div className='grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3'>
            <div className='p-4 transition-shadow border rounded-lg shadow-sm col-span-2'>
              <div className='flex-grow flex flex-col'>
                <div className='flex-grow'>
                  <div className='actions bg-white flex items-center justify-between p-4'>
                    <div className='flex items-center space-x-2'>
                      <h3 className='font-bold text-gray-500'>PEDIDOS RECIENTES</h3>
                    </div>
                    <div className='right'>
                      <button className='bg-green-600 text-white hover:bg-red-600 rounded py-2 px-6 md:px-12'>Ver Todo</button>
                    </div>
                  </div>
                  <div className='overflow-x-auto'>
                    <div className='inline-block min-w-full pb-2 align-middle'>
                      <div className='border-b border-gray-200 rounded-md'>
                        <table className='min-w-full overflow-x-scroll divide-y divide-gray-200'>
                          <tbody className='bg-white divide-y divide-gray-200'>
                            {orders.map((order, key) => (
                              <tr key={key} className='transition-all hover:bg-gray-100 hover:shadow-lg'>
                                <td className='px-6 py-4 whitespace-nowrap'>
                                  <div className='flex items-center'>
                                    <div className='flex-shrink-0 w-10 h-10'>
                                      <img className='w-10 h-10' src={order.client.profile?order.client.profile:"/images/affiliate-3.jpg"} alt='' />
                                    </div>
                                    <div className='ml-4'>
                                      <div className='text-sm font-medium text-gray-900'>
                                        {order.client.name} - S/ {parseFloat(order.total).toFixed(2)}
                                      </div>
                                      <div className='text-sm text-gray-500'>{moment(order.createdAt).format('DD/MM/YYYY')}</div>
                                    </div>
                                  </div>
                                </td>

                                <td className='px-6 py-4 whitespace-nowrap'>
                                  <span className='inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full'> {order.delivery_method} </span>
                                </td>
                                
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-gray-100'>
              <p>Info.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageInit
