import React, { useEffect, useState } from "react"
import { FaAngleDown, FaAngleUp, FaPaperclip } from "react-icons/fa"
import moment from 'moment'
import { OrderListByPage, OrderUpdateStatus } from "api/order";
import Spinner from "components/utilities/spinner";
import { ModalConfirm } from "components/Modal";
import { ModalImage } from "components/Modal";


const PagePedidos = ({pageID}) => {
    const [orders,setOrder] = useState([])
    const [loaded, setLoaded] = useState(true);
    const [detailActive, setDetail] = useState(null);
    const status = [
        {
            value: 'REJECTED',
            name: 'Rechazado',
            className: 'rechazado'
        },
         {
            value: 'APPROVED',
            name: 'Aceptado',
            className: 'aceptado'
        },
        {
            value: 'PENDING',
            name: 'En proceso',
            className: 'enproceso'
        },
    ]

    const onOrderStatus = async(orderId, status, ordersNew) => {
        console.log(status)
        const result = await OrderUpdateStatus({orderId, status})
        if(result.balanceId){
            setOrder(ordersNew);
            return true
        } else {
            return false
        }
    }

    const onChangeStatus= async(id, value, dataPreview) => {
        const orderIndex = orders.findIndex(order => order._id === id);
        if(dataPreview === "PENDING"){
            const ordersNew = [...orders];
            ordersNew[orderIndex].status = value;
            await ModalConfirm(() => onOrderStatus(id, value, ordersNew), 'Seguro de cambiar el status de la orden', 'Status cambiado exitosamente', 'Cambio no realizado')
        }
    }

    const StatusOrder = (id) => {
        const order = orders.find(order => order._id === id);
        const statuSelected = status.find(data => data.value === order.status)
        return(
            <ul className="status">
                <li className={statuSelected.className}>
                    <span> {statuSelected.name} </span> <FaAngleDown className='inline-block'/>

                    <ul>
                        {
                            status.map((data) => (
                                <li>
                                    <button onClick={() =>{ onChangeStatus(id, data.value, statuSelected.value)}} className={`btn ${data.className} ${data.value === statuSelected.value? 'active': ''}`}>
                                        {data.name}
                                    </button>
                                </li>
                            ))
                        }    
                    </ul>
                </li>
            </ul>
        )
    }

    const onReferenceView = (id, url) => {
        ModalImage(`Referencia de orden de compra ${id}`, url);
    }

    useEffect(() => {
        const InitialData = async() => {
            setLoaded(true)
            const result = await OrderListByPage(pageID);
            if(result.orders){
                setOrder(result.orders)
                console.log('orders=> ', result.orders)
            }
            setLoaded(false)
        }

        InitialData();
    },[pageID])

  return (
    <div className='bg-white p-4 sm:p-8'>
        {
            loaded?
            <Spinner />
            :

            orders.map((order, key) => (
                <div className='items_lists' key={key}>
                    <div className={`item ${detailActive === key? 'active': ''}`}>
                        <div className="who">
                            <div className="cols picture">
                                <img src={order.client.profile?order.client.profile:"/images/affiliate-3.jpg"} alt="" />
                            </div>
                            <div className="cols dets">
                                <h3>{order.client.name}</h3>
                                <button className="lets-chat">Chatear</button>
                                <address>
                                    {order.client.address}
                                </address>
                            </div>
                        </div>
        
                        <div className="cbtns">
                            <div className="timer">
                                {moment(order.createdAt).format('DD/MM/YYYY - hh:mm a')}
                            </div>
                           {(order.payment_method !== null &&
                            <button className="notes" aria-label="notas" onClick={()=>onReferenceView(order._id, order.reference)}>
                                <span>notas</span> <FaPaperclip className='inline-block'/>
                            </button>
                            )}
                            <button className="view-details" onClick={() => {setDetail(detailActive === key?null: key)}}>
                                Ver detalle {key === detailActive? <FaAngleUp className='inline-block mr-2'/> : <FaAngleDown className='inline-block mr-2'/>}
                            </button>
        
                            <div className="more-opts">
                                <div className="price">
                                    <small>Total</small>
                                    <strong><span>S/.</span> {Number.parseFloat(order.total).toFixed(2)}</strong>
                                </div>
                                {StatusOrder(order._id)}
                            </div>
                        </div>
        
                        <div className="more-details">
                            <div className="full-info">
                                <div className="left">
                                    
                                    {order.hasOwnProperty('delivery') ? <div>
                                    <div style={{ display: "flex" }}> <strong>Delivery </strong></div>
                                    <div style={{ display: "flex" }}> {order.delivery.name}</div>
                                    <div style={{ display: "flex" }}>  {order.delivery.address}</div>
                                    <div style={{ display: "flex" }}> {order.delivery.phone}</div>
                                    <div style={{ display: "flex" }}> {order.client.email}</div>
                                    </div> : <strong>{order.client.name} / {order.client.email}</strong>
                                    }
                                    <address>
                                        {order.client.address}
                                    </address>
                                </div>
                                <div className="right">
                                    <button className="btn phone">
                                        <i className="fas fa-phone"></i>
                                        {order.client.phone}
                                    </button>
                                </div>
                            </div>
                            <div className="list-items">
                                {
                                   order.orders.map((product, item) => (
                                    <div className="list-item" key={item}>
                                        <img
                                            src={product.product.featured_images[0]?product.product.featured_images[0]:"https://picsum.photos/id/10/50/50"}
                                            alt=""
                                            className="pic"
                                        />
                                        <h4>{product.product.name} - ({product.qt})</h4>
                                        <div className="prices">
                                            <small>S/.</small>
                                            <strong>{Number.parseFloat(product.total).toFixed(2)}</strong>
                                        </div>
                                    </div>
                                   ))
                                }                                
                            </div>
                        </div>
                    </div>
                </div>
            ))
        }
    </div>
  )
}

export default PagePedidos
