import React, {useContext, useState} from "react"
// eslint-disable-next-line
import { Link, useHistory  } from "react-router-dom"
import MetaTags from 'components/MetaTags'
import ImageAuth from 'components/Auth/Image';
import Toast from 'components/Toast';
import { SignIn } from "api/user";
import { AuthContext } from "context/authContext";

const Login = () => {
  const [user, setUser] = useState({email: '', password: ''});
  const { onLogin } = useContext(AuthContext);

  const history = useHistory();


  const onChangeData = ({target: {value, name}}) => {
    setUser({
      ...user,
      [name]: value
    })
  }

  const onAlert = async (icon, msg) => {
    Toast(icon, msg);
  }

  const onSubmitData = async(e) => {
    try {
      e.preventDefault();
      if(user.email === '' || user.password === ''){
        console.log('data not complete');
        await onAlert('warning', 'Complete los datos por favor');
        return;
      }
      const result = await SignIn(user);
      console.log(result)
      if(result.token){
        await localStorage.setItem("token", result.token);
        await localStorage.setItem("user", result.id)
        await onAlert('success', 'Bienvenido');
        setTimeout(() => {
          onLogin();
          if(history.location.state){
            history.push(`/${history.location.state.page}`)
          }else {
            history.push('/profile')
          }
        }, [4800])
      } else {
        await onAlert('error', result.message)
      }
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <>
      <MetaTags titlePage='Acceder a tu Cuenta' content='Iniciar Sesion' />

      <div className='py-12 container mx-auto px-4'>
        <div className='flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto '>
          <ImageAuth />
          <div className='w-full p-8 lg:w-1/2'>
            <div className='mt-4 flex items-center justify-between'>
              <span className='border-b w-1/5 lg:w-1/4'></span>
              <h1 className='font-bold text-2xl text-center text-gray-500 '>Iniciar Sesión</h1>
              <span className='border-b w-1/5 lg:w-1/4'></span>
            </div>
            <div className='mt-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2'>Email </label>

              <input aria-label='Email' className='bg-gray-100 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none' type='email' name='email' value={user.email} onChange={onChangeData} />
            </div>
            <div className='mt-4'>
              <div className='flex justify-between'>
                <label className='block text-gray-700 text-sm font-bold mb-2'>Contraseña</label>

                {/* <a href='#()' className='text-xs text-gray-500'>
                 Clave olvidada?
                </a> */}
              </div>
              <input aria-label='Contraseña' className='bg-gray-100 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none' type='password' name='password' value={user.password} onChange={onChangeData} />
            </div>

            <div className='flex items-center mt-4'>
              <input aria-label='Recordar Clave' type='checkbox' id='agree' />
              <label htmlFor='agree' className='ml-2 text-gray-700 text-sm'>
                Recordar Contraseña
              </label>
            </div>

            <div className='mt-8'>
              <button onClick={onSubmitData} className='bg-red-500 text-white font-bold py-2 px-4 w-full rounded hover:bg-red-600 border-b-4 border-red-700'>Iniciar Sesión</button>
            </div>
            <div className='mt-4 flex items-center flex-col justify-between'>
              <div className='w-full mt-4 flex items-center justify-between'>
                <span className='border-b w-1/5 md:w-1/4'></span>
                <Link to='/register' className='text-xs text-gray-500 uppercase font-bold'>
                  o Crear Cuenta
                </Link>
                <span className='border-b w-1/5 md:w-1/4'></span>
              </div>
              <div className='w-full mt-4 flex items-center justify-between'>
                <span className='border-b w-2/5 md:w-1/4'></span>
                <Link to='/recovery-password' className='text-xs text-gray-500 uppercase font-bold'>
                ¿Olvido su contraseña?
                </Link>
                <span className='border-b w-2/5 md:w-1/4'></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
