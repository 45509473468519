import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import PanelAdmin from 'components/panel'
import MetaTags from 'components/MetaTags'
import { AuthContext } from 'context/authContext'
import UserInit from './init'
import UserAffiliate from './affiliate'
import UserProfile from './profile'
import UserPedidos from './orders'
import UserSetting from './setting'
import UserWithdrawal from './withdrawal'
import { UserGet } from 'api/user'
import UserGain from './gain'

const Profile = () => {
  const {onLogout} = useContext(AuthContext)
  const [user, setUser] = useState({name: "", affiliates:[], pages:[] });
  const [loaded, setLoaded] = useState(true)
  const optionSelected ={name: 'init', title: 'Inicio', component:(<UserInit affiliates={user.affiliates || []} gain={user.gain || 0} pages={user.pages || []} />)}
 
  const history = useHistory()

  const logoutApp = async() =>{
    await localStorage.clear();
    await history.replace('/');
    await onLogout()
  }

  const InitalData = async() => {
    setLoaded(true)
    const token = await localStorage.getItem('token')
    const result = await UserGet(token);
    if(!result.message){
      setUser({...result, affiliates: result.affiliates});
    } else {
      logoutApp()
    }
    setLoaded(false)
  }

  const options = [
    {
      name: 'init',
      title: 'Inicio',
      option: 'Inicio',
      component:(<UserInit affiliates={user.affiliates} gain={user.gain} pages={user.pages} />)
    },
    {
      name: 'pedidos',
      title: 'Mis Pedidos',
      option: 'Mis Pedidos',
      component:(<UserPedidos/>)
    },
    {
      name: 'profile',
      title: 'Perfil',
      option: 'Mis Datos',
      component:(<UserProfile user={user} onDataInitial={() => {InitalData()}} />)
    },
    {
      name: 'affiliate',
      title: 'Afiliado',
      option: 'Afiliados',
      component:(<UserAffiliate affiliates={user.affiliates} code={user.code} onDataInitial={() => {InitalData()}}/>)
    },
    {
      name: 'gain',
     title: 'Ganancias',
     option: 'Ganancias',
     component:(<UserGain/>)
   },
    {
       name: 'withdrawal',
      title: 'Retiro',
      option: 'Retiros',
      component:(<UserWithdrawal gain={user.gain} onDataInitial={() => {InitalData()}}/>)
    },
    {
      name: 'setting',
      title: 'Configuración',
      option: 'Configuraciones',
      component:(<UserSetting  code={user.code} />)
    },
    {
      name: 'admin',
      title: '',
      option: user.type_user === 'ADMIN' ?'Panel Administrativo': '',
      action: () => history.push('/admin'),
      component:(<></>)
    },
    {
      name: 'exit',
      title: '',
      option: 'Cerrar sesion',
      action: () => {logoutApp()},
      component:(<></>)
    },
  ]


  useEffect(() => {
    const logoutApp = async() =>{
      await localStorage.clear();
      await history.replace('/');
      await onLogout()
    }
    const InitalData = async() => {
      setLoaded(true)
      const token = await localStorage.getItem('token')
      const result = await UserGet(token);
      if(!result.message){
        setUser({...result, affiliates: result.affiliates});
      } else {
        logoutApp()
      }
      setLoaded(false)
    }
    InitalData()
  },[history, onLogout])

  
  return (
    <>
      <MetaTags titlePage='Perfil' content='Descripcion' />

      {
        !loaded &&
        <PanelAdmin file={user.profile} name={user.name} menus={options} initialState={optionSelected} loaded={loaded} />
      }
     
    </>
  )
}

export default Profile
