import React, { useState } from 'react'
import UpdateProfile from './updateProfile'

const UserProfile = ({ user, onDataInitial }) => {
  const [actionUpdate, setAction] = useState(false)

  return (
    <div className='bg-white p-4 sm:p-8'>
      {actionUpdate ? (
        <UpdateProfile
          onFinish={() => {
            setAction(false)
            onDataInitial()
          }}
        />
      ) : (
        <div className='space-y-4'>
          <p>
            <strong> Nombre:</strong> {user.name}
          </p>
          <p>
            <strong>Apellido:</strong> {user.last_name}
          </p>
          <p>
            <strong> Email:</strong> {user.email} - Link de Afiliado: https://ganecom.com/{process.env.REACT_APP_URL_FRONT}register/{user.code}
          </p>
          <p>
            <strong> Celular:</strong> {user.mobile} - Telefono: {user.phone}
          </p>
          <p>
            <strong>Direccion:</strong> {user.address}
          </p>
          <br />
          <button className='bg-green-600 text-white hover:bg-red-600 rounded py-2 px-6 md:px-12 ' onClick={() => setAction(true)}>
            Actualizar datos
          </button>
        </div>
      )}
    </div>
  )
}

export default UserProfile
