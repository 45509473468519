import React, {useEffect, useState} from 'react';
import { CategoryList } from 'api/category';
import Spinner from 'components/utilities/spinner';

const Categories = ({onChangeCategory, onChangeSubCategory}) => {
  const [openSubCategories, setOpen] = useState(null);
  const [categories, setCategories] = useState([])
  const [loaded, setLoaded] = useState(true)

  useEffect(() => {
    const InitialData = async() => {
      setLoaded(true)
      const result = await CategoryList();
      if(result.categories){
        const data = result.categories.map((category) => ({
          id: category._id,
          name: category.name,
          subCategories: category.subCategories.map(subCategory => ({
            id: subCategory._id,
            name: subCategory.name
          }))
        }))
        setCategories(data)
      }
      setLoaded(false)
    }

    InitialData();
  },[])


  return (
    <div className='aside mb-8'>
      <h3 className='bg-gray-200 p-3 py-4 lg:text-xl mb-3 font-bold'>Todas las categorías</h3>

      <ul className='leading-none mb-5'>
        {
          loaded?
          <Spinner />
          :
          categories.map((category, key) => (
            <li className='bg-gray-200 p-3 mb-2' key={key}>
              <div className='flex items-center cursor-pointer' onClick={() => {setOpen(openSubCategories !== key?key:null); onChangeCategory(openSubCategories !== key?category.id:"")}}>
              <i className='lni lni-gift mr-3'></i> <span>  {category.name} </span> <i className={`lni ml-auto ${category.subCategories.length > 0 && openSubCategories===key?'lni-chevron-left':'lni-chevron-right'}`}></i>
              </div>
              {
                category.subCategories.length > 0 &&
                  <ul className={`pl-5 pt-3 ${category.subCategories.length > 0 && openSubCategories===key? 'block': 'hidden'}`}>
                  {
                    category.subCategories.map((subCategory, index) => (
                      <li className='mb-1' key={index} onClick={() => {onChangeSubCategory(subCategory.id)}}>
                        <span className='block border-gray-400 px-3 py-2 border-l-2 hover:text-red-500 transition duration-300 ease-in-out transform hover:translate-x-1'>
                          {subCategory.name}
                        </span>
                      </li>
                    ))
                  }
                </ul>
              }
            </li>
          ))
        }
        
      </ul>

      <div className='sample-box bg-gray-400 h-48 flex text-white items-center justify-center'>box</div>
    </div>
  ); 
}

export default Categories