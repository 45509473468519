import React from 'react'
import { Link } from 'react-router-dom'

const CardProduct = ({ product, key }) => {
  return (
    <div className='item w-full py-4 relative' key={key}>
      <>
        {product.descount && (
          <span className='in-offer flex items-center justify-center font-bold w-14 h-14 text-xl text-white border-dashed border-2 bg-green-500 rounded-full absolute -left-3 top-1 z-10'>
            -{product.mount_descount}
            {product.type_descount}
          </span>
        )}
        {product.offer && (
          <div className='ribbon ribbon-top-right'>
            <span className='bg-yellow-500'>Orferta</span>
          </div>
        )}
      </>
      <div className='bg-white shadow-xl rounded-lg overflow-hidden'>
        <Link to={{ pathname: `/detalle/${product.name.replace(/ /g, '-')}`, state: { productId: product._id } }} className='thumb block overflow-hidden'>
          <img className='pimage w-full relative transform transition duration-300 ease-in-out' src={product.featured_image} alt='thum' />
          <span className='hidden'>View</span>
        </Link>

        <p className='p-4 border-l-4 border-green-500 tracking-wide text-sm text-gray-700'> {product.name} </p>
        <div className='price p-4 flex items-center justify-between'>
          <div className='left leading-none'>
            <s className='text-2x1 text-gray-400 whitespace-nowrap'>
              <small>S/</small> {product.price[0]}
              <small>.{product.price[1]}</small>
            </s>
            {product.commission && (
              <strong className='block text-sm xl:text-lg whitespace-nowrap'>
                Gane <span className='text-yellow-500'>S/ {product.mount_commission}</span>
              </strong>
            )}
          </div>
          <p className='text-3xl text-green-500 whitespace-nowrap font-bold'>
            <small>S/</small>
            {product.total ? product.total[0] : 50}
            <small>.{product.total > 0 ? product.total[1] : '00'}</small>
          </p>
        </div>

        <div className='p-3 border-t border-gray-300 bg-gray-100'>
          <div className='flex items-center justify-between'>
            <div className='time flex items-center'>
              <img className='mr-2' width='15' height='15' src={product.page.cover ? product.page.cover : '/images/company.png'} alt='' />
              {/* <div className='text-xs'>
                        {product.offer_day} <span className='text-gray-400'>días /</span> {product.offer_min} <span className='text-gray-400'>min. /</span> {product.offer_seg} <span className='text-gray-400'>sec.</span>
                        </div> */}
            </div>
            <Link to={{ pathname: `/detalle/${product.name.replace(/ /g, '-')}`, state: { productId: product._id } }} className='bg-yellow-500 border-yellow-600 text-white hover:bg-yellow-600 py-2 px-3 border-b-4 hover:border-yellow-500 rounded transition duration-300 ease-in-out'>
              Ver <span className='md:hidden xl:inline-flex'> Detalle</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardProduct
