import React, { useEffect, useState } from 'react';
import { UserList } from 'api/user';
import Spinner from 'components/utilities/spinner';
import { ModalWithHtml } from 'components/Modal';

const AdminUser = () => {
    const [users, setUsers] = useState([])
    const [loaded, setLoaded] = useState(true)

    useEffect(() => {
        const InitialData = async() => {
            setLoaded(true)
            const result = await UserList();
            if(result.users){
                setUsers(result.users);
            }
            setLoaded(false)
        }
        InitialData()
    },[])

    const viewDetail = async(id) => {
        const user = await users.find(user => user._id === id);
        const html = (
            <div>
                <div>Nombre: {user.name} apellido: {user.last_name}</div>
                <div>Correo: {user.email} codigo: {user.code}</div>
                <div>Movil: {user.mobile} telefono: {user.phone}</div>
                <div>direccion: {user.address}</div>
            </div>
        )
        await ModalWithHtml(html);
    }

    return(
        <div className="bg-white">
            <div className="p-4 sm:p-8">
            {
                loaded?
                <Spinner />
                :
                <div>
                    <div>Usuarios</div>
                    {
                        <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200">
                        {
                            users.map((user, id) => (
                                <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0 w-10 h-10">
                                            <img className="w-10 h-10" src={user.profile?user.profile:"https://avatars0.githubusercontent.com/u/57622665?s=460&amp;u=8f581f4c4acd4c18c33a87b3e6476112325e8b38&amp;v=4"} alt="" />
                                            </div>
                                            <div className="ml-4">
                                            <div className="text-sm font-medium text-gray-900">{user.name}</div>
                                            <div className="text-sm text-gray-500">{user.email}</div>
                                            </div>
                                        </div>
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`inline-flex px-2 text-xs font-semibold leading-5 ${user.email_confirm?"text-green-800 bg-green-100": "text-red-800 bg-red-100"} rounded-full`}> {!user.email_confirm && "No "}Verificado </span>
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                        <button className="flex items-center space-x-3" onClick={()=>{viewDetail(user._id)}}>
                                            <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded">Detalle</span>
                                        </button>
                                    </td>
                                </tr>
                            ))
                          }

                        </tbody>
                      </table>
/* 
                        users.map((user, id) => (
                            <div key={id}>
                                User:
                                <br />
                                name: {user.name} - email: {user.email} - url: http://localhost:3000/register/{user.code}
                                {
                                    user.affiliates.length > 0 &&
                                    <div>
                                    Afiliados:
                                    {
                                        user.affiliates.map((affiliate, key) => (
                                            <div key={key}>
                                                name: {affiliate.user.name} - email: {affiliate.user.email}
                                            </div>
                                        ))
                                    }
                                </div>
                                }
                                {
                                    user.pages.length > 0 &&
                                    <div>
                                    Negocio:
                                    {
                                        user.pages.map((page, key) => (
                                            <div key={key}>
                                                name: {page.business_name}
                                            </div>
                                        ))
                                    }
                                </div>
                                }
                                <hr />
                            </div>
                        )) */
                    }
                </div>
            }
            </div>
        </div>
    )
}

export default AdminUser;