import React, { useState, useEffect } from 'react'
import { useHistory  } from "react-router-dom"
import MetaTags from 'components/MetaTags'
import Toast from 'components/Toast';
import { PageGet, PageRegister, PageUpdate } from 'api/page';
import { CategoryList } from 'api/category';
import { SubCategoryList } from 'api/subCategory';
import Spinner from 'components/utilities/spinner';
import { DepartamentList } from 'api/departament';

const PageAdd  = ({action, onFinish}) => {
  const [page, setPage] = useState({business_name: '', departament: '', business_email: '', business_phone: "", business_address: '', slug: '', small_description: '', cover: null, category: '', subCategory: '', payments: [{name_method: '', data: ''}]})
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [departaments, setDepartaments] = useState([])
  const [loaded, setLoaded] = useState(true)
  const history = useHistory();

  useEffect(() => {
      const DataInitial = async() => {
        setLoaded(true)
        const result = await CategoryList();
        if(result.categories) {
          setCategories(result.categories.map(category => ({id: category._id, name: category.name})));
        }
        const result2 = await DepartamentList();
        if(result2.departaments){
          setDepartaments(result2.departaments.map(departament=> ({id: departament._id, name: departament.name})));
        }
        if(action){
          if (action.action === "update"){
            const resPage = await PageGet(action.pageId)
            if(resPage){
              setPage(resPage);
            }
          }
        }
        setLoaded(false)
      }
      DataInitial();
    
  }, [action])

  useEffect(() => {
    const onDataSubCategory =async() => {
      if(page.category) {
        const result = await SubCategoryList(page.category);
        console.log(result)
        if(result.SubCategories){
          setSubCategories(result.SubCategories.map(subCategory => ({ id: subCategory._id, name: subCategory.name})))
        }
      } else {
        setSubCategories([])
      }
    }
    onDataSubCategory();
  }, [page.category])

  const onchangeData = ({target: {name, value}}) => {
    setPage({
      ...page,
      [name]: value
    })
  }

  const onChangeFile = ({target: {files}}) => setPage({...page, cover: files[0]});

  const onChangeDataPayment = ({target: {value, name}}) => {
    const payments = [...page.payments];
    const id = name.split('.');
    payments[id[0]][id[1]] = value
    setPage({
      ...page,
      payments
    })
  }

  const onAddPayment = (e) => {
    e.preventDefault();
    let payments = [...page.payments]
    payments.push({name_method: '', data: ''})
    setPage({
      ...page,
      payments
    })
  }

  const onDeletePayment = (e, key) => {
    e.preventDefault();
    const payments = page.payments.filter((pay, id) => id !== key)
    setPage({
      ...page,
      payments
    })
  }

  const onChangeDataCheck = ({target: {checked, name}}) => {
    setPage({
      ...page,
      [name]: checked
    })
  }

  const onAlert = async (icon, msg) => {
    Toast(icon, msg);
  }

  const onSubmitData = async(e) => {
    e.preventDefault();
    try {
      if(page.business_name === '' ||  page.slug === '' || page.departament === ""){
        console.log('completed data required');
        await onAlert('warning', 'Complete los datos requeridos por favor');
        return;
      }
      const form = new FormData();
      for(const key in page){
        if(key === 'payments'){
          for (const item of page[key]) {
            form.append(key, JSON.stringify(item));
          }
        } else {
          form.append(key, page[key])
        }
      }
      if(action){
        if(action.action === "update"){
          const result = await PageUpdate(form, page._id);
          console.log("result->", result);
          if(result.pageId){
            await onAlert('success', 'Pagina actualizada con exito');
            setTimeout(() => {
              onFinish()
            }, [4800])
          } else {
            await onAlert('error', result.message)
          }
        }
      } else {
        const token = await localStorage.getItem('token');
        const result = await PageRegister(form, token);
        console.log("result->", result);
        if(result.pageId){
          await onAlert('success', 'Pagina creada con exito');
          setTimeout(() => {
            history.push('/page/'+ result.pageId);
          }, [4800])
        } else {
          await onAlert('error', result.message)
        }
      }
    } catch (error) {
      
    }
  }

  return (
    <>
    <MetaTags titlePage='Crear Pagina' content='Descripcion' />

      <div className='featured-item featured-item-inner bg-gray-100'>
        <div className='container mx-auto px-4'>
          <div className='sm:grid sm:grid-cols-4 sm:gap-6'>
            <div className='left-area bg-white sm:col-span-3'>
             
           
            <div className='grid grid-flow-col gap-4'>
              {
                loaded?
                <Spinner />
                :
                <form className='p-10'>
                <h1 className='sm:text-3xl text-2xl font-medium title-font mb-6'>{action && action.action?"Actualiza": "Crea"} una Página</h1>
                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='nombre-p'> Nombre del Negocio </label>
                    <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='nombre-p' type='text' placeholder='Jane' name='business_name' value={page.business_name} onChange={onchangeData} />
                     <p className='text-red-500 text-xs italic'>Requerido.</p>
                  </div>
                  <div className='w-full md:w-1/2 px-3'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='precio'> SLUG (Url) </label>
                    <input className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' id='precio' type='text' placeholder='Doe' name='slug' value={page.slug} onChange={onchangeData}/>
                  </div>
                </div>

                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='categoria'> Categoría </label>

                    <select className='block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='' name='category' value={page.category} onChange={onchangeData}>
                      <option className='' value="">Seleccione Categoría</option>
                      {
                        categories.map((category, key) => (
                          <option value={category.id} key={key} className=''>{category.name}</option>
                        ))
                      }
                    </select>
                     <p className='text-red-500 text-xs italic'>Requerido.</p>
                  </div>
                  <div className='w-full md:w-1/2 px-3'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='sub'> Sub Categoría</label>
                    <select className='block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='' name='subCategory' value={page.subCategory} onChange={onchangeData}>
                      <option className='' value="">Seleccione Sub Categoría</option>
                      {
                        subCategories.map((subCategory, key) => (
                          <option value={subCategory.id} key={key} className=''>{subCategory.name}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='email'> Email </label>
                    <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='email' type='email' placeholder='Email' name='business_email' value={page.business_email} onChange={onchangeData}/>
                  </div>
                  <div className='w-full md:w-1/2 px-3'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='titular'> Titular </label>
                    <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='titular' type='text' placeholder=''/>
                  </div>
                </div>

                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='text'> Celular </label>
                    <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='text' type='text' placeholder='' name='business_phone' value={page.business_phone} onChange={onchangeData}/>
                  </div>
                  <div className='w-full md:w-1/2 px-3'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='phone'> Teléfono </label>
                    <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='phone' type='text' placeholder=''/>
                  </div>
                </div>

                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full md:w-1/2 px-3'>
                   <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='categoria'> Departamento </label>
                    <select className='block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='' name='departament' value={page.departament} onChange={onchangeData}>
                      <option className='' value="">Seleccione Departamento</option>
                      {
                        departaments.map((departament, key) => (
                          <option value={departament.id} key={key} className=''>{departament.name}</option>
                        ))
                      }
                    </select>
                    <p className='text-red-500 text-xs italic'>Requerido.</p>
                  </div>
                  <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='direccion'> Dirección </label>
                    <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='direccion' type='text' placeholder='' name='business_address' value={page.business_address} onChange={onchangeData}/>
                  </div>
                </div>
                {
                  action && (
                      action.action ==="update" &&
                      <div className='flex flex-wrap -mx-3 mb-6'>
                        <div className='w-full px-3'>
                          <label className='inline-flex items-center mt-3'> <input aria-label='active' type='checkbox' className='form-checkbox h-5 w-5 text-teal-600' name='active' onChange={onChangeDataCheck} checked={page.active}/><span className='ml-2 text-gray-700'>Activo</span> </label>
                        </div>
                      </div>
                  )
                }

                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full px-3'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for=''> Logo </label>
                    <input aria-label='imagen' className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' id='grid-email' type='file' placeholder='' name='cover' onChange={onChangeFile}/>
                  </div>
                </div>

               <div>Metodos de pagos</div>
               {
                 page.payments.map((payment, key) => (
                    <div className='flex flex-wrap -mx-3 mb-6' key={key}>
                      <div className='w-full px-3  flex items-end'>
                        <button className='ml-auto' onClick={(e) => {onDeletePayment(e, key)}}>X</button>
                      </div>
                      <div className='w-full px-3'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for=''> Tipo de Pago </label>
                        <input aria-label='imagen' className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' id='grid-email' type='text' placeholder='' name={`${key}.name_method`} value={payment.name_method} onChange={onChangeDataPayment}/>
                      </div>
                      <div className='w-full px-3'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='grid-password'> Datos </label>
                        <textarea rows='10' className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' name={`${key}.data`} value={payment.data} onChange={onChangeDataPayment}> </textarea>
                      </div>
                    </div>
                 ))
                 
               }

               <div>
                <div className='flex justify-between w-full px-3'>
                  <button onClick={onAddPayment} className='bg-red-500 text-white font-bold py-2 px-4 w-full rounded hover:bg-red-600 border-b-4 border-red-700'>Agregar Metodo de Pago</button>
                </div>
               </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full px-3'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='grid-password'> Descripcion </label>
                    <textarea rows='10' className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' name='small_description' value={page.small_description} onChange={onchangeData}> </textarea>
                  </div>
                  <div className='flex justify-between w-full px-3'>
                    <button onClick={onSubmitData} className='bg-red-500 text-white font-bold py-2 px-4 w-full rounded hover:bg-red-600 border-b-4 border-red-700'>Crear Ahora</button>
                  </div>
                </div>
              </form>
              }
            </div>




            </div>

  
          </div>
        </div>
      </div>
     
    </>
  )
}

export default PageAdd
