import { UserConfirm } from 'api/user';
import Toast from 'components/Toast';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const EmailConfirm = () =>{
    const {token} = useParams();
    const history = useHistory()
    useEffect(() => { 
        const InitialData =async() => {
            console.log(token)
            const result = await UserConfirm(token)
            if(result.userId){
                Toast("success","Email confirmado, gracias")
                setTimeout(() =>{
                    history.push("/")
                })
            } else {
                Toast("warning", "El email no pudo ser confirmado, contacte al Admin")
            }
        }
        InitialData()
    },[token])

    return(
        <>
        </>
    )
}

export default EmailConfirm;