import { ShoppingCartContext } from 'context/shoppingCartContext'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { FaShoppingCart, FaWindowClose, FaRegTrashAlt } from 'react-icons/fa'

const ShoppingCart = ({ isOpen }) => {
  const { products, total, onDeleteProduct } = useContext(ShoppingCartContext)
  return (
    <>
      {/* <div className={`fixed w-full  h-full rounded-b border-t-0 z-10 ${!isOpen ? 'hidden' : ''}`}>
        <div className={`shadow-xl w-72 h-1/2 bg-white`}>
          <div className='h-5/6 overflow-auto'>
            {products.map((product, key) => (
              <div key={key} className='p-2 flex hover:bg-gray-100 cursor-pointer border-b border-gray-100'>
                <div className='p-2 h-auto w-2/5'>
                  <img src={product.image} alt='img product' />
                </div>
                <div className='flex-auto text-black text-sm w-2/6'>
                  <div className='font-bold'>{product.name}</div>
                  <div className='truncate'>{product.description}</div>
                  <div className='text-gray-400'>Qt: {product.qt}</div>
                </div>
                <div className='flex flex-col w-3/12 font-medium items-end text-black'>
                  <div
                    className='w-4 h-4 mb-6 hover:bg-red-200 rounded-full cursor-pointer text-red-700'
                    onClick={() => {
                      onDeleteProduct(key)
                    }}
                  >
                    <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' className='feather feather-trash-2 '>
                      <polyline points='3 6 5 6 21 6'></polyline>
                      <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
                      <line x1='10' y1='11' x2='10' y2='17'></line>
                      <line x1='14' y1='11' x2='14' y2='17'></line>
                    </svg>
                  </div>
                  S/ {product.price}
                </div>
              </div>
            ))}
          </div>
          <div className='p-1 pl-2 justify-around flex text-black text-base'>
            <div className='flex-col'>
              <div>
                <span className='font-bold'>Cantidad:</span> {total.qt}{' '}
              </div>
              <div>
                <span className='font-bold'>Total:</span> S/ {total.price}
              </div>
            </div>
            <div>
              <Link to={'/checkout'} className='bg-blue-500 border-blue-600 text-white hover:bg-blue-600 py-2 px-3 border-b-4 hover:border-blue-500 rounded transition duration-300 ease-in-out'>
                Realizar <span className='md:hidden xl:inline-flex'> Pago</span>
              </Link>
            </div>
          </div>
        </div>
      </div> */}

      <div className={`acart w-full fixed z-50 right-0 top-0 ${!isOpen ? 'hidden' : ''}`}>
        <div className='overlay fixed bg-gray-700 h-full w-full opacity-50 top-0 left-0'></div>
        <div className='acart-box w-full relative pb-0 text-gray-800 bg-white shadow-lg ml-auto'>
          <div className='flex flex-col p-4 h-screen overflow-auto'>
            <div className='acart-top overflow-auto flex-1 pt-10'>
              <div className='acart-header bg-white absolute w-full left-0 top-0 z-10 px-4 py-2 flex items-center'>
                <button className='close inline-flex text-xl  hover:text-red-700 mr-2'>
                  <FaWindowClose />
                </button>

                <h2 className='inline-flex text-xl font-bold p-2 items-center space-x-3'>
                  <span>Carrito de compras</span> <FaShoppingCart />
                </h2>

                <hr />
              </div>

              <table className='w-full text-sm' cellspacing='0'>
                <thead>
                  <tr className='h-10 uppercase text-left'>
                    <th className=''></th>
                    <th className=''>Producto</th>
                    <th className=''>
                      <span className='' title='Cantidad'>
                        Cant.
                      </span>
                    </th>
                    <th className='pl-2'>Total</th>
                    <th className=''></th>
                  </tr>
                </thead>
                <tbody className=''>
                  {products.map((product, key) => (
                    <tr key={key}>
                      <td className='pb-3'>
                        <img width='60' src={product.image} alt={product.name} />
                      </td>
                      <td className='pl-1 pr-1 pb-3'>
                        <div className='acart-pname'>{product.name} </div>
                        {/* {product.description} */}
                      </td>
                      <td className='pb-3'>
                        <div className='w-11'>
                          <div className='relative flex flex-row w-full h-8 justify-center'>
                            {/* <input type='number' value='1' className='w-full font-semibold text-center text-gray-700 bg-gray-200 outline-none' /> */}
                            {product.qt}
                          </div>
                        </div>
                      </td>

                      <td className='pl-2 pb-3'>
                        <span className='text-sm font-medium whitespace-nowrap'>S/. {product.price}</span>
                      </td>

                      <td className='text-center pl-2 pb-3'>
                        <button
                          className='text-sm hover:text-red-700'
                          onClick={() => {
                            onDeleteProduct(key)
                          }}
                        >
                          <FaRegTrashAlt />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='buttom'>
              <div className='pt-3'>
                <div className='text-sm '>
                  <div className='p-4 bg-gray-100 rounded-full'>
                    <h1 className='ml-2 font-bold uppercase'>Detalles del Orden</h1>
                  </div>
                  <div className='p-4 '>
                    <div className='flex justify-between font-bold border-b'>
                      <div className='m-2 text-gray-800'>Cantidad</div>
                      <div className='m-2 text-gray-900'>{total.qt}</div>
                    </div>

                    <div className='flex justify-between font-bold border-b'>
                      <div className='m-2 text-gray-800'>Total</div>
                      <div className='m-2 text-lg text-gray-900'>S/. {total.price}</div>
                    </div>

                    <hr />

                    <Link to={'/checkout'} className='flex items-center justify-center w-full px-10 py-3 mt-6 font-medium text-white uppercase bg-gray-800 rounded-full shadow item-center hover:bg-gray-700 focus:shadow-outline focus:outline-none'>
                      <svg aria-hidden='true' data-prefix='far' data-icon='credit-card' className='w-8 mr-3' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'>
                        <path fill='currentColor' d='M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z' />
                      </svg>
                      Realizar Pedido
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShoppingCart
