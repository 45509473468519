import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import PanelAdmin from "components/panel"
import MetaTags from "components/MetaTags"
import ProductsPage from "./products"
import PedidosPage from "./orders"
import VentasPage from "./sales"
import PageInit from "./page"
import PageSetting from "./Setting"
import { PageGet } from "api/page"
import { OrderListByPage, OrderTotalByPage } from "api/order"

const PageHome = () => {
  const { pageID } = useParams()
  const [page, setPage] = useState({sales: 0, orders: []})
  const [loaded, setLoaded] = useState(true)

  const InitalData = async () => {
    setLoaded(true)
    const result = await PageGet(pageID)
    const data = await OrderTotalByPage(pageID)
    const resultOrders = await OrderListByPage(pageID)
    if (result) {
      setPage({...result, sales: data.result, orders: resultOrders.orders})
  
    }
    setLoaded(false)
  }
  const options = [
    {
      name: "page",
      title: "Inicio",
      option: "Inicio",
      component: <PageInit sales={page.sales} credit={page.credit} products={page.products} orders={page.orders} />,
    },
    {
      name: "ventas",
      title: "Ventas",
      option: "Ventas",
      component: <VentasPage pageID={pageID} />,
    },
    {
      name: "pedidos",
      title: "Pedidos",
      option: "Pedidos",
      component: <PedidosPage pageID={pageID} />,
    },
    {
      name: "product",
      title: "Producto",
      option: "Productos",
      component: <ProductsPage page={pageID} />,
    },
    {
      name: "setting",
      title: "Configuración",
      option: "Configuraciones",
      component: <PageSetting pageID={pageID} onChange={InitalData} />,
    },
  ]

  const optionSelected = options[0];

  useEffect(() => {
    const InitalData = async () => {
      setLoaded(true)
      const result = await PageGet(pageID)
      const data = await OrderTotalByPage(pageID)
      const resultOrders = await OrderListByPage(pageID)
      if (result) {
        setPage({...result, sales: data.result, orders: resultOrders.orders.slice(0,4)})
    
      }
      setLoaded(false)
    }
    InitalData()
  }, [pageID])

  return (
    <>
      <MetaTags titlePage='Dashboard' content='Descripcion' />
      {!loaded && <PanelAdmin file={page.cover} initialState={optionSelected} name={page.business_name} loaded={loaded} menus={options} />}
    </>
  )
}

export default PageHome
