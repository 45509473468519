import React, { useEffect, useState } from 'react'
import Toast from 'components/Toast'
import { ProductGet, ProductRegister, ProductUpdate } from 'api/product'
import Spinner from 'components/utilities/spinner'

const ProductAdd = ({pageID, onFinish, data}) => {
  const [product, setProduct] = useState({productId:'',name: '', description: '', price: '', stock: 0,page: pageID, offer: false, descount: false, type_descount: '%', mount_descount: 0})
  const [loaded, setLoaded] = useState(true)
  const [price, setPrice] = useState(null)
  
  useEffect(() => {
    const getProduct = async() => {
      await setLoaded(true)
      console.log(data);
      const result = await ProductGet(data.productid);
      if(result._id){
        setProduct({
          ...result,
          productId: data.productid,
          page: result.page._id
        })
        setPrice(result.price)
      }
      await setLoaded(false)
    }
    if(data.action === "update"){
      getProduct();
    } else {
      setLoaded(false);
    }
  }, [data])

  const onAlert = async (icon, msg) => {
    Toast(icon, msg);
  }

  const onChangeData = ({target: {value, name}}) => {
    setProduct({
      ...product,
      [name]: value
    })
  }

  const onChangeFile = ({target: {files}}) => setProduct({...product, featured_images: files[0]})

  const onChangeDataCheck = ({target: {checked, name}}) => {
    setProduct({
      ...product,
      [name]: checked
    })
  }

  const onSubmitData = async(e) => {
    e.preventDefault();
    try {
      if(product.name === '' || product.price === '' || product.stock === 0 || product.description === '' || product.page === ''){
        await onAlert('warning', 'Complete los datos requeridos por favor');
        return;
      }

      if(product.offer && !product.descount){
        await onAlert('warning', 'Debe indicar la cantidad de descuento');
        return;
      }

      if(product.descount && product.mount_descount === 0){
        await onAlert('warning', 'Complete los datos de descuento por favor');
        return;
      }
      const form = new FormData();
      for (const key in product) {
        form.append(key, product[key])
      }
      if(data.action === "update"){
        let priceActual = parseInt(product.price)
        if(priceActual > price){
          await onAlert('error', 'Precio no puede ser mayor al anterior')
          return
        }{
        const result = await ProductUpdate(form, product._id);
        if(result.productId) {
          await onAlert('success', 'Producto actualizado con exito');
          setTimeout(() => {
            onFinish();
          }, [4800])
        } else {
          await onAlert('error', result.message)
        }
      }
      } else {
        const result = await ProductRegister(form);
        if(result.productId) {
          await onAlert('success', 'Producto creado con exito');
          setTimeout(() => {
            onFinish();
          }, [4800])
        } else {
          await onAlert('error', result.message)
        }
      }

    } catch (error) {
      console.log(error)
    }
  }
  
  return (
    <>
      <div className='featured-item featured-item-inner bg-gray-100'>
        <div className='container mx-auto px-4'>
          <div className='sm:grid sm:grid-cols-4 sm:gap-6'>
            <div className='left-area bg-white sm:col-span-3'>
             
           
            <div className='grid grid-flow-col gap-4'>
              {
                loaded?
                <Spinner/>
                :
                <form className='p-10'>
                <h1 className='sm:text-3xl text-2xl font-medium title-font mb-6'>{data.action==="update"?"Actualizar":"Publicar"} Producto</h1>
                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='nombre-p'> Nombre del Producto </label>
                    <input className='appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='nombre-p' type='text' placeholder='Producto' name='name' value={product.name} onChange={onChangeData} />
                    <p className='text-red-500 text-xs italic'>Requerido.</p>
                  </div>
                  <div className='w-full md:w-1/2 px-3'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='precio'> Precio S/.</label>
                    <input className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' id='precio' type='number' placeholder='Precio' name='price' value={product.price} onChange={onChangeData}/>
                    {/* <input id='precio2' type='text' name='price' value={product.price}/> */}
                  </div>
                </div>

                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='stock'> Stock </label>
                    <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='stock' type='number' placeholder='' name='stock' value={product.stock} onChange={onChangeData}/>
                  </div>
                </div>
                {
                  data.action ==="update" &&
                  <div className='flex flex-wrap -mx-3 mb-6'>
                    <div className='w-full px-3'>
                      <label className='inline-flex items-center mt-3'> <input aria-label='active' type='checkbox' className='form-checkbox h-5 w-5 text-teal-600' name='active' onChange={onChangeDataCheck} checked={product.active}/><span className='ml-2 text-gray-700'>Activo</span> </label>
                    </div>
                  </div>
                }

               {/*  <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full px-3'>
                    <label className='inline-flex items-center mt-3'> <input aria-label='comision' type='checkbox' className='form-checkbox h-5 w-5 text-teal-600' name='commission' onChange={onChangeDataCheck} checked={product.commission}/><span className='ml-2 text-gray-700'>Comision por Venta</span> </label>
                  </div>
                </div> */}

                {/* 
                  product.commission &&
                  <>
                    <div className='flex flex-wrap -mx-3 mb-6'>
                      <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                        <label className='inline-flex items-center mt-3'> <input name='type_commission' aria-label='type_commission' type='radio' className='form-radio h-5 w-5 text-blue-600' value='%' checked={product.type_commission=== '%'} onChange={onChangeData}/><span className='ml-2 text-gray-700'>Comision por %</span> </label>
                      </div>
                      <div className='w-full md:w-1/2 px-3'>
                        <label className='inline-flex items-center mt-3'> <input name='type_commission' aria-label='type_commission' type='radio' className='form-radio h-5 w-5 text-blue-600' value='S' checked={product.type_commission=== 'S'} onChange={onChangeData}/><span className='ml-2 text-gray-700'>Comision por venta</span> </label>
                      </div>
                    </div>

                    <div className='flex flex-wrap -mx-3 mb-6'>
                      <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='mount_commission'> Comision </label>
                        <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='mount_commission' type='number' placeholder='' name='mount_commission' value={product.mount_commission} onChange={onChangeData}/>
                      </div>
                    </div>
                  </>
                */}

                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full px-3'>
                    <label className='inline-flex items-center mt-3'> <input aria-label='descuento' type='checkbox' className='form-checkbox h-5 w-5 text-teal-600' name='descount' onChange={onChangeDataCheck} checked={product.descount}/><span className='ml-2 text-gray-700'>Descuento </span> </label>
                  </div>
                </div>

                {
                  product.descount && 
                  <>
                    <div className='flex flex-wrap -mx-3 mb-6'>
                      <div className='w-full px-3'>
                        <label className='inline-flex items-center mt-3'> <input aria-label='ofertar-producto' type='checkbox' className='form-checkbox h-5 w-5 text-teal-600' name='offer' onChange={onChangeDataCheck} checked={product.offer}/><span className='ml-2 text-gray-700'>Ofertar producto</span> </label>
                      </div>
                    </div>
                    <div className='flex flex-wrap -mx-3 mb-6'>
                      <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                        <label className='inline-flex items-center mt-3'> <input name='type_descount' aria-label='type_descount' type='radio' className='form-radio h-5 w-5 text-blue-600' value='%' checked={product.type_descount=== '%'} onChange={onChangeData}/><span className='ml-2 text-gray-700'>Descuento por %</span> </label>
                      </div>
                      <div className='w-full md:w-1/2 px-3'>
                        <label className='inline-flex items-center mt-3'> <input name='type_descount' aria-label='type_descount' type='radio' className='form-radio h-5 w-5 text-blue-600' value='S' checked={product.type_descount=== 'S'} onChange={onChangeData}/><span className='ml-2 text-gray-700'>Descuento por venta</span> </label>
                      </div>
                    </div>

                    <div className='flex flex-wrap -mx-3 mb-6'>
                      <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='mount_descount'> Descuento </label>
                        <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='mount_descount' type='number' placeholder='' name='mount_descount' value={product.mount_descount} onChange={onChangeData}/>
                      </div>
                    </div>
                  </>
                }

                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full px-3'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for=''> Imagen </label>
                    <input aria-label='imagen' className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' id='grid-email' type='file' placeholder='' onChange={onChangeFile}/>
                  </div>
                </div>

                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full px-3'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='grid-password'> Descripción </label>
                    <textarea rows='10' className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' name='description' value={product.description} onChange={onChangeData}> </textarea>
                  </div>
                  <div className='flex justify-between w-full px-3'>
                    <button onClick={onSubmitData} className='bg-red-500 text-white font-bold py-2 px-4 w-full rounded hover:bg-red-600 border-b-4 border-red-700'>Publicar Ahora</button>
                  </div>
                </div>
              </form>
              }
            </div>


            </div>

            {/* <div className='seller-info pt-3'>
              <strong>Producto ofrecido por:</strong>
              <h3>Tecnología Store SAC.</h3>
              <br />
              <strong>Dirección</strong>
              <address>he aqui va la direccion de la empresa que ofrece</address>
              <strong>Informes:</strong>
              <span>+51952535555</span> <br />
              <strong>Email:</strong>
              <span>info@company.com</span>
              <div className='cbtn-area text-center pt-5 mb-5'>
                <span className='block mb-3'>Ver todos los productos de esta tienda</span>

                <a href='#()' className='more-details block bg-gray-200 hover:bg-gray-600 hover:text-white text-gray-700 py-2 px-3 border-b-4 border-red-700 hover:border-gray-500 rounded transition duration-300 ease-in-out'>
                  Ver Más{' '}
                </a>
              </div>
              <div className='payment-option'>
                <strong>Paga con Tarjetas</strong>

                <div className='cards flex justify-evenly text-4xl'>
                  <i className='lni lni-visa'></i>
                  <i className='lni lni-mastercard'></i>
                  <i className='lni lni-amex'></i>
                  <i className='lni lni-paypal'></i>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
     
    </>
  )
}

export default ProductAdd
