import { BalanceListByUser } from 'api/balance';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Spinner from '../utilities/spinner'

const UserGain = () => {
    const [balances,setBalance] = useState([])
    const [loader, setLoader] = useState(true)
    const token = localStorage.getItem('token')

    useEffect(() => {
        const InitialData = async() => {
            setLoader(true)
            const result = await BalanceListByUser(token, 'GAIN');
            console.log(result.balances)
            if(result.balances.length > 0){
                await setBalance(result.balances)
            }
            setLoader(false)
        }
        InitialData();
    },[token])
    

    const onStatusView = (status) => {
        let result
        switch (status) {
            case "PENDING":
                result = (<span className="inline-flex px-2 text-sm font-semibold leading-5 text-gray-800 bg-gray-200">Pendiente</span>)
                break;
            case "REJECTED":
                result = (<span className="inline-flex px-2 text-sm font-semibold leading-5 text-red-800 bg-red-200">Rechazado</span>)
                break;
        
            case "APPROVED":
                result = (<span className="inline-flex px-2 text-sm font-semibold leading-5 text-green-800 bg-green-200">Aprobado</span>)
                break;
            default:
                result = (<span className="inline-flex px-2 text-sm font-semibold leading-5 text-gray-800 bg-gray-200">Pendiente</span>)
                break;
        }
        return (result);
    }
    return(
    <div className="bg-white">
        <div className="p-4 sm:p-8">
        {
            loader?
                <Spinner />
            :
                <div>
                    <div>Mis Ganancias</div>
                    <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200">
                        {
                            balances.length > 0 ?
                            balances.map((balance, id) => (
                                <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {balance.subject}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {moment(balance.createdAt).format('DD/MM/YYYY')}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        S/ {balance.mount}
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {onStatusView(balance.status)}
                                    </td>
                                </tr>
                            ))
                            :
                            <div className='flex content-center mt-11 '>No hay Ganancias</div>
                        }

                        </tbody>
                    </table>
                </div>
        }
        </div>
    </div>
    )
}

export default UserGain