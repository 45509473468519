import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}api/balances`,
    headers: {
        'content-type': 'application/json',
    }
})

export const BalanceRegister = async (data, token) => {
    const result = await api.post('/register', data, {
            headers: {
                'x-access-token': token
            }
        })
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const BalanceListByUser = async (token, type, isAdmin=false) => {
    const result = await api.get(`/list/user?movement_type=${type}&isAdmin=${isAdmin}`, {
            headers: {
                'x-access-token': token
            }
        })
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const BalanceListPayment = async () => {
    const result = await api.get(`/list/payment`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}


export const BalanceUpdateStatus = async (data) => {
    const result = await api.put(`/status/${data.balanceId}`, data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}