import React, { useState, useEffect } from 'react';
import { FaAngleDown } from "react-icons/fa"
import moment from 'moment';
import { BalanceListPayment, BalanceUpdateStatus } from 'api/balance';
import Spinner from '../utilities/spinner'
import { ModalConfirm, ModalWithHtml } from 'components/Modal';

const PaymentAdmin = () => {
    const [balances,setBalance] = useState([])
    const [loader, setLoader] = useState(true)

    const status = [{
            value: 'REJECTED',
            name: 'Rechazado',
            className: 'rechazado'
        },
        {
            value: 'PENDING',
            name: 'Pendiente',
            className: 'enproceso'
        },
        {
            value: 'APPROVED',
            name: 'Aprobado',
            className: 'enviado'
        },
    ]
    const PaymentStatus = async(id, value, balancesNew)=> {
        const result = await BalanceUpdateStatus({balanceId: id, status: value})
        if(result.balanceId){
            setBalance(balancesNew);
            return true
        } else {
            return false
        }
    } 

    const onChangeStatus= async(id, value) => {
        const paymentIndex = balances.findIndex(balance => balance._id === id);
        const balancesNew = [...balances];
        balancesNew[paymentIndex].status = value;
        await ModalConfirm(() => PaymentStatus(id, value, balancesNew), 'Seguro de cambiar el status de la solicitud de pago', 'Status cambiado exitosamente', 'Cambio no realizado')
    }

    const viewDescriptionPayment = async(user, mount,description) => {
        const html = (
            <div>
                <div>usuario: {user}</div>
                <div>Monto de retiro: {mount}</div>
                <div>{description}</div>
            </div>
        )
        await ModalWithHtml(html)
    }

    const StatusPayment = (id) => {
        const balance = balances.find(balance => balance._id === id);
        const statuSelected = status.find(data => data.value === balance.status)
        return(
            <ul className="status">
                <li className={statuSelected.className}>
                    <span> {statuSelected.name} </span> <FaAngleDown className='inline-block'/>

                    <ul>
                        {
                            status.map((data) => (
                                <li>
                                    <button onClick={() =>{ onChangeStatus(id, data.value)}} className={`btn ${data.className} ${data.value === statuSelected.value? 'active': ''}`}>
                                        {data.name}
                                    </button>
                                </li>
                            ))
                        }    
                    </ul>
                </li>
            </ul>
        )
    }

    useEffect(() => {
        const InitialData = async() => {
            setLoader(true)
            const result = await BalanceListPayment();
            if(result.balances.length > 0){
                setBalance(result.balances)
            }
            setLoader(false)
        }
        InitialData();
    },[])
    return(
    <div className="bg-white">
        <div className="p-4 sm:p-8">
        {
            loader?
                <Spinner />
            :
            <div>
                <div>Pagos solicitados</div>
                <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                    <tbody className="bg-white divide-y divide-gray-200">
                    {
                        balances.length > 0?
                        balances.map((balance, id) => (
                            <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {balance.subject}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {balance.user.name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {moment(balance.createdAt).format('DD/MM/YYYY')}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    S/ {balance.mount}
                                </td>

                                <td className="px-6 py-4 whitespace-nowrap">
                                    {StatusPayment(balance._id)}
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                    <button className="flex items-center space-x-3" onClick={() => viewDescriptionPayment(balance.user.name, balance.mount, balance.description)}>
                                        <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded" href="#">Detalle</span>
                                    </button>
                                </td>
                            </tr>
                        ))
                        :
                        <div className='flex content-center mt-11 '>No hay solicitud de pagos</div>
                    }

                    </tbody>
                </table>
            </div>
        }
        </div>
    </div>
    )
}

export default PaymentAdmin