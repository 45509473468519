import React from 'react'

import MetaTags from 'react-meta-tags'

const Privacidad = () => {
  return (
    <>
      <MetaTags>
        <title>Política de privacidad</title>
        <meta name='description' content='' />
      </MetaTags>

      <section class='text-gray-700 body-font border-t border-gray-200'>
        <div class='container px-5 py-24 mx-auto'>
          <div class='flex flex-wrap w-full mb-20 flex-col  '>
            <h1 class='sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 text-center'>Política de privacidad</h1>
            <br />
            <small>Última actualización: 13 de Diciembre de 2021</small>
            <br />
            <p class='leading-relaxed text-base mb-4'>AVISOS VIP INTERNACIONAL S.A.C. con RUC 20532882860 en adelante ganecom es la empresa titular del sitio web Ganecom.com</p>

            <p className='mb-4'>Ganecom protege los datos de carácter personal que recaba y trata, respetando las exigencias legales en materia de protección de datos.</p>

            <p className='mb-4'>
              <strong>Recogida y finalidades de tratamiento de los datos recogidos a través del formulario de registro</strong>
            </p>

            <p className='mb-4'>La información que facilites deberá ser veraz. A la hora de facilitar los datos de registro garantizas la autenticidad de dichos datos. De lo contrario serás responsable del daño que puedas causar a Ganecom o a terceros por no facilitar datos auténticos.</p>

            <p className='mb-4'>Antes de facilitar tus datos para registrarte como usuario debes conocer que:</p>
            <p className='mb-4'>Los datos que nos facilites a través del formulario de “registro” del Sitio, serán tratados con la finalidad de darte acceso a la plataforma de usuarios para que puedas gestionar tus compras y ganancias.</p>
            <p className='mb-4'>Igualmente, te mandaremos información comercial de ofertas y promociones ofrecidas por los Negocios aliados a ganecom. </p>
            <p className='mb-4'>Al enviarnos el formulario de registro, estarás dando tu consentimiento expreso a Ganecom para tratar tus datos con las finalidades mencionadas. Y para incorporarlos en un fichero de titularidad de Ganecom, </p>

            <h3 className='font-bold text-xl'>Comunicaciones</h3>
            <p className='mb-4'>Ganecom pone a disposición del usuario el servicio de este Sitio.</p>
            <p className='mb-4'>Fuera del supuesto comentado los datos personales que nos facilites a través del Sitio web no serán comunicados a terceros en ningún caso, salvo en los casos legalmente previstos.</p>

            <h3 className='font-bold text-xl'>Seguridad</h3>
            <p className='mb-4'>El Titular del Sitio web ha adoptado las medidas de índole técnicas y organizativas necesarias para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos de carácter personal que se tratan en los ficheros de su titularidad y de acuerdo al tipo de datos que se tratan. Se aplican las medidas de seguridad que la Ley Orgánica de Protección de Datos y su Reglamento de desarrollo especifican para dicho tipo de datos.</p>

            <h3 className='font-bold text-xl'>Enlaces</h3>
            <p className='mb-4'>Desde este Sitio puede haber enlaces a sitios webs de terceros. En esos casos, no nos responsabilizamos de las políticas de privacidad que dichos terceros puedan llevar a cabo sobre los datos de carácter personal que recaban. Te recomendamos que leas las políticas de privacidad de dichos sitios.</p>

            <h3 className='font-bold text-xl'>Terceros prestadores de servicios</h3>
            <p className='mb-4'>Con la finalidad de garantizar las adecuadas medidas de seguridad técnica, Ganecom contrata proveedores externos para dar soporte a los servicios del Sitio. Dichos proveedores son organizaciones de reconocido prestigio para alojar el Sitio en sus sistemas y locales. Al aceptar las presentes condiciones autorizas expresamente, a que los datos que nos facilites a través del Sitio sean alojados en sistemas propios o en sistemas de los proveedores contratados por Ganecom, al efecto Dichos proveedores podrán variar por razones técnicas o de necesidades organizativas.</p>
            <p className='mb-4'>En cualquier caso, Ganecom, se asegurará de garantizar con dichos proveedores que cumplen las adecuadas medidas técnicas y organizativas para la salvaguarda de tus datos.</p>

            <h3 className='font-bold text-xl'>Ganecom no será responsable por la información revelada, en los siguientes casos:</h3>

            <p className='mb-4'>a) En los casos en que, en virtud de órdenes judiciales, o de regulaciones legales, nos veamos compelidos a revelar información a las autoridades o terceras partes bajo ciertas circunstancias especiales.</p>

            <p className='mb-4'>b) En los casos que terceras personas puedan interceptar o acceder a cierta información o transmisiones de datos de manera indebida (vulneración del sistema).</p>

            <h3 className='font-bold text-xl'>Derechos de los titulares de los datos</h3>
            <p className='mb-4'>Los titulares de los datos de carácter personal pueden ejercitar sus derechos de acceso, rectificación, cancelación y oposición en los términos recogidos en la Ley Orgánica de Protección de Datos de Carácter Personal y su normativa de desarrollo. Dicho ejercicio puede llevarse a cabo enviando, por cualquier medio que permita acreditar el envío y recepción de la solicitud a la atención del área de protección de datos o a la siguiente dirección de e-mail: HYPERLINK "mailto:atencion.cliente@ganecom.com" atencion.cliente@ganecom.com </p>

            <h3 className='font-bold text-xl'>Vigencia y modificación de la presente política de protección de datos</h3>
            <p className='mb-4'>La presente política de privacidad está vigente desde el 01 de Enero de 2014. El titular del Sitio se reserva el derecho de modificar su política de privacidad en el supuesto de que exista un cambio de la legislación vigente, doctrina jurisprudencial o por criterios propios empresariales. Si se introdujese algún cambio en esta política el nuevo texto, se publicará en esta misma dirección.</p>

            <br />

            <i className='text-center'>
              Ganecom <strong>"gane comprando, gane compartiendo"</strong>
            </i>
          </div>
        </div>
      </section>
    </>
  )
}

export default Privacidad
