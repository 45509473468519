import React, { useContext } from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Home from 'components/Home'
import Search from 'components/Home/Search'
import StoreSearch from 'components/StoreSearch'
import Detail from 'components/Detail'

import Profile from 'components/Profile'
import PageHome from 'components/Page'

import PageCreate from 'components/Page/PageCreate'
import Login from 'components/Auth/Login'
import Register from 'components/Auth/Register'
import Admin from 'components/Admin'
import EmailConfirm from 'components/Auth/emailConfirm'
import ForgotPassword from 'components/Auth/forgotPassword'
import RecoveryPass from 'components/Auth/changePassword'

import Test from 'components/Test'
import Checkout from 'components/Checkout'

import Header from 'components/Header'
import Footer from 'components/Footer'
import PrivateRoute from 'components/utilities/privateRoute'
import { ShoppingCartContext } from 'context/shoppingCartContext'

import QuienesSomos from 'pages/Quienes-somos'
import Privacidad from 'pages/Politica-de-privacidad'
import Tos from 'pages/Terminos-y-condiciones'
import Objetivo from 'pages/Objetivo'
import Comofunciona from 'pages/Como-funciona'

function Main() {
  const { openCart, setOpen } = useContext(ShoppingCartContext)
  return (
    <div
      onClick={() => {
        if (openCart) {
          setOpen(false)
        }
      }}
    >
      <Router>
        <Header />
        <Switch>
          <Route path='/detalle/:name'>
            <Detail />
          </Route>
          <Route exact path='/emailConfirm/:token'>
            <EmailConfirm />
          </Route>
          <PrivateRoute exact path='/profile'>
            <Profile />
          </PrivateRoute>
          <PrivateRoute exact path='/create/page'>
            <PageCreate />
          </PrivateRoute>
          <PrivateRoute exact path='/page/:pageID'>
            <PageHome />
          </PrivateRoute>
          <PrivateRoute exact path='/admin'>
            <Admin />
          </PrivateRoute>
          <Route exact path='/login'>
            <Login />
          </Route>

          <Route exact path={['/register', '/register/:code']}>
            <Register />
          </Route>
          <Route exact path='/recovery-password'>
            <ForgotPassword />
          </Route>
          <Route exact path='/changePassword/:token'>
            <RecoveryPass />
          </Route>
          <Route exact path='/test'>
            <Test />
          </Route>
          <Route exact path='/checkout'>
            <Checkout />
          </Route>
          <Route exact path={['/search/:textKey', '/search/:category/:subCategory']}>
            <Search />
          </Route>
          <Route exact path='/tienda/:slug'>
            <StoreSearch />
          </Route>
          <Route exact path='/'>
            <Home />
          </Route>

          <Route exact path='/quienes-somos'>
            <QuienesSomos />
          </Route>
          <Route exact path='/politica-de-privacidad'>
            <Privacidad />
          </Route>
          <Route exact path='/terminos-y-condiciones'>
            <Tos />
          </Route>
          <Route exact path='/objetivo'>
            <Objetivo />
          </Route>
          <Route exact path='/como-funciona'>
            <Comofunciona />
          </Route>
        </Switch>
      </Router>

      <Footer />
    </div>
  )
}

export default Main
