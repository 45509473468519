import React, { useContext, useEffect, useState } from 'react'
import MetaTags from 'components/MetaTags'
import SimilarProduct from 'components/Detail/SimilarProduct'
import ProductStore from 'components/Detail/ProductStore'
import Spinner from 'components/utilities/spinner'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ProductGet } from 'api/product'
import { ShoppingCartContext } from 'context/shoppingCartContext'

const Detail = () => {
  const [product, setProduct] = useState({ page: {}, qt: 1 })
  const [loaded, setLoaded] = useState(true)
  const history = useHistory()
  const { name } = useParams()
  const { onAddProduct } = useContext(ShoppingCartContext)

  useEffect(() => {
    const DetailData = async () => {
      if (history.location.state) {
        setLoaded(true)
        const result = await ProductGet(history.location.state.productId)
        console.log(result)
        if (result._id) {
          setProduct({
            ...result,
            featured_image: result.featured_images.length > 0 ? result.featured_images[0] : 'https://picsum.photos/id/10/50/50',
            price: Number.parseFloat(result.price).toFixed(2),
            total: Number.parseFloat(result.descount ? result.price_descount : result.price).toFixed(2),
            mount_commission: Number.parseFloat((result.commission_product * 40) / 100).toFixed(2),
            qt: 1,
          })
          setLoaded(false)
        }
      }
    }
    DetailData()
  }, [history.location.state])
  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)
    if (!new RegExp('[0-9]').test(keyValue)) event.preventDefault()
    return
  }
  const onChangeQt = ({ target: { value } }) => {
    setProduct({
      ...product,
      qt: value,
    })
  }

  const AddCart = async () => {
    const cart = { productId: product._id, name: product.name, description: product.description, priceUnit: product.total, price: Number.parseFloat(product.total * product.qt).toFixed(2), image: product.featured_image || 'images/affiliate-12.jpg', qt: Number.parseInt(product.qt), userId: localStorage.getItem('user'), pageId: product.page._id }
    console.log(cart)
    onAddProduct(cart)
  }
  return (
    <>
      {loaded ? (
        <Spinner />
      ) : (
        <>
          <MetaTags titlePage={name.replace(/-/g, ' ')} content='Descripcion' />

          <div className='featured-item featured-item-inner bg-gray-100'>
            <div className='container mx-auto px-4'>
              <div className='sm:grid sm:grid-cols-4 sm:gap-6'>
                <div className='left-area bg-white sm:col-span-3'>
                  <div className='md:flex'>
                    <div className='thumb w-full relative mb-4'>
                      <img className='w-full h-full object-cover' src={product.featured_image} alt='' />
                    </div>
                    <div className='info p-6 xl:p-8 relative'>
                      {product.offer && (
                        <div className='ribbon ribbon-top-right'>
                          <span className='bg-yellow-500'>Orferta</span>
                        </div>
                      )}

                      <h3 className=' font-bold text-2xl leading-none mb-3'>{product.name}</h3>
                      {/* <p className='text-gray-500 mb-4'>{product.description}</p> */}

                      <div className='price flex items-center justify-between mb-4'>
                        <div className='left'>
                          <strong className='price text-lg xl:text-2xl'>S/ {product.total}</strong>
                          {product.commission && (
                            <strong className='block text-sm xl:text-lg whitespace-nowrap'>
                              Gane <span className='text-yellow-500'>S/ {product.mount_commission}</span>
                            </strong>
                          )}
                        </div>

                        <div className='right flex pl-5 items-end'>
                          <div className='cants pr-5'>
                            <label className='mb-1 block'>Cantidad</label>
                            <input className='rounded bg-gray-200 cursor-pointer p-2 w-full' aria-label='Cantidad' id='number' type='number' min='1' name='qt' value={product.qt} onChange={onChangeQt} onKeyPress={onKeyPressEvent} />
                          </div>
                          <button onClick={AddCart} className='bg-red-500 hover:bg-red-600 text-white py-2 px-6 border-b-4 border-red-700 hover:border-red-500 rounded transition duration-300 ease-in-out ml-2'>
                            Comprar
                          </button>
                        </div>
                      </div>

                      <div className='values bg-gray-200 rounded px-5 py-3 flex items-center justify-between text-center uppercase mb-5'>
                        <div className='item'>
                          <strong className='block lg:text-lg'>S/ {product.price}</strong> <small className='block text-gray-500'>Precio</small>
                        </div>
                        {product.descount && (
                          <div className='item'>
                            <strong className='block lg:text-lg'>
                              {product.type_descount} {product.mount_descount}
                            </strong>{' '}
                            <small className='block text-gray-500'>Descuento</small>
                          </div>
                        )}
                        {product.descount && (
                          <div className='item'>
                            <strong className='block lg:text-lg'>S/ {Number.parseFloat(product.price - product.total).toFixed(2)}</strong> <small className='block text-gray-500'>Ahorro</small>
                          </div>
                        )}
                      </div>

                      {/* product.offer &&
                        <div className='count text-center'>
                          <h5 className='uppercase text-sm lg:text-base mb-2 text-gray-500'>Date prisa, solo quedan algunas ofertas</h5>

                          <div className='time flex items-center justify-center'>
                            <img className='mr-2' width='20' height='20' src='images/icons/reloj.svg' alt='' />
                            <div className='text-xl'>
                              8 <span className='text-gray-400'>días /</span> 20 <span className='text-gray-400'>min. /</span> 58 <span className='text-gray-400'>sec.</span>
                            </div>
                          </div>
                        </div> */}
                    </div>
                  </div>
                  <div className='description p-6 pt-0'>
                    <h3 className='font-bold text-2xl mb-4'>Descripcion</h3>
                    <p>{product.description}</p>
                  </div>
                </div>

                <div className='seller-info pt-3'>
                  <strong>Producto ofrecido por:</strong>
                  <h3>{product.page.business_name}</h3>
                  <br />
                  <strong>Dirección</strong>
                  <address>{product.page.business_address}</address>
                  <strong>Informes:</strong>
                  <span>{product.page.business_phone}</span> <br />
                  <strong>Email:</strong>
                  <span>{product.page.business_email}</span>
                  <div className='cbtn-area text-center pt-5 mb-5'>
                    <span className='block mb-3'>Ver todos los productos de esta tienda</span>

                    <Link to={`/tienda/${product.page.slug}`} className='more-details block bg-gray-200 hover:bg-gray-600 hover:text-white text-gray-700 py-2 px-3 border-b-4 border-red-700 hover:border-gray-500 rounded transition duration-300 ease-in-out'>
                      Ver Más
                    </Link>
                  </div>
                  <div className='payment-option'>
                    <strong>Paga con Tarjetas</strong>

                    <div className='cards flex justify-evenly text-4xl'>
                      <i className='lni lni-visa'></i>
                      <i className='lni lni-mastercard'></i>
                      <i className='lni lni-amex'></i>
                      <i className='lni lni-paypal'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* similar Product Component */}
          <SimilarProduct name={product.name} page={product.page._id} />

          {/* product store component */}
          <ProductStore page={product.page._id} productId={product._id} slug={product.page.slug} />
        </>
      )}
    </>
  )
}

export default Detail
