import React, { useContext, useEffect, useState } from 'react';
import MetaTags from 'components/MetaTags'
import PanelAdmin from 'components/panel';
import AdminInit from './init';
import AdminUser from './user';
import AdminPage from './page';
import AdminAffiliate from './affiliate';
import AdminCredit from './credit'
import OrdersAdmin from './orders';
import PaymentAdmin from './paymentRequests'
import AdminConfig from  './config'
import { UserGet } from 'api/user'
import { useHistory } from "react-router-dom"
import { AuthContext } from 'context/authContext';

const Admin = () => {
  const {onLogout} = useContext(AuthContext)
  const [user, setUser] = useState({name: "", gainAdmin: 0 });
  const [loaded, setLoaded] = useState(true)
 
  const history = useHistory()

  useEffect(() => {
    const logoutApp = async() =>{
      await localStorage.clear();
      await history.replace('/');
      await onLogout()
    }

    const InitialData = async() =>  {
        setLoaded(true);
        const token = await localStorage.getItem('token')
        const result = await UserGet(token);
        if(!result.message){
          if(result.type_user !== "ADMIN" ){
            history.replace('/')
          } else {
            console.log(result.gainAdmin)
            setUser({...result, name: "Admin GANECOM"});
            setLoaded(false)
          }
        } else {
          logoutApp();
        }
    }
    InitialData()
  },[history])

  const options = [
    {
        name: 'init',
        title: 'Inicio',
        option: 'Inicio',
        component:(<AdminInit gain={user.gainAdmin} />)
    },
    {
        name: 'user',
        title: 'Usuarios',
        option: 'Usuarios',
        component:(<AdminUser />)
    },
    {
        name: 'page',
        title: 'Negocios',
        option: 'Negocios',
        component:(<AdminPage />)
    },
    {
        name: 'affiliate',
        title: 'Afiliados',
        option: 'Afiliados',
        component:(<AdminAffiliate />)
    },
    {
      name: 'order',
      title: 'Pedidos',
      option: 'Pedidos',
      component:(<OrdersAdmin />)
  },
    {
      name: 'payment',
      title: 'Pagos',
      option: 'Solicitud de Pagos',
      component: ( <PaymentAdmin /> )
    },
    {
        name: 'credit',
        title: 'Creditos',
        option: 'Creditos',
        component:(<AdminCredit />)
    },
    {
        name: 'config',
        title: 'Configruacion',
        option: 'Configuracion',
        component:(<AdminConfig />)
    },
  ]
  const optionSelected = options[0]

    return(
        <>
            <MetaTags titlePage='Admin' content='Descripcion' />
            {
              !loaded &&
              <PanelAdmin file={user.profile} name={user.name} menus={options} initialState={optionSelected} loaded={loaded} />
            }
        </>
    )
}

export default Admin