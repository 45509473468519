import React, { useState } from 'react';
import Toast from 'components/Toast';
import { BalanceRegister } from 'api/balance';

const AddBalance = ({mount, onFinish}) => {
    const [balance, setBalance] = useState({subject: 'Solicitud de pago',mount, description:'', movement_type:'WITHDRAWAL' })
    const token = localStorage.getItem('token')
    const onAlert = async (icon, msg) => {
        Toast(icon, msg);
      }


    const onchangeData = ({target: {name, value}}) => setBalance({...balance, [name]: value})
    const onSubmitData = async(e) => {
        e.preventDefault();
        if(balance.subject === '' ||  balance.mount === ''){
            console.log('data not complete');
            await onAlert('warning', 'Complete los datos por favor');
            return;
          }
          if(mount < balance.mount){
               console.log('mount not limint');
               await onAlert('warning', 'Monto ingresado es mayor al que posee');
               return;
          }
        const result = await BalanceRegister(balance, token)
        console.log(result)
        if(result.balanceId){
            await onAlert('success', 'Registro de solicitud de pago exitoso');
            setTimeout(() => {
                onFinish()
            }, [4800])
          } else {
            await onAlert('error', result.message)
          }
    }
    return (
        <div className='featured-item featured-item-inner bg-gray-100'>
            <div className='container mx-auto px-4'>
                <div className='sm:grid sm:grid-cols-4 sm:gap-6'>
                    <div className='left-area bg-white sm:col-span-3'>
                        <div className='grid grid-flow-col gap-4'>
                        {
                            <form className='p-10'>
                                <h1 className='sm:text-3xl text-2xl font-medium title-font mb-6'>Registrar Solicitud de Pago</h1>
                                <div className='flex flex-wrap -mx-3 mb-6'>
                                    <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='nombre-p'> Asunto  </label>
                                        <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='nombre-p' type='text' placeholder='Jane' name='subject' value={balance.subject} onChange={onchangeData} />
                                        <p className='text-red-500 text-xs italic'>Requerido.</p>
                                    </div>
                                    <div className='w-full md:w-1/2 px-3'>
                                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='precio'> Monto </label>
                                        <input className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' id='precio' type='number' name='mount' value={balance.mount} onChange={onchangeData}/>
                                        <p className='text-red-500 text-xs italic'>Requerido.</p>
                                    </div>
                                </div>

                                <div className='flex flex-wrap -mx-3 mb-6'>
                                    <div className='w-full px-3'>
                                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='grid-password'> Mensaje </label>
                                        <textarea rows='10' className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' name='description' value={balance.description} onChange={onchangeData}> </textarea>
                                    </div>
                                        <div className='flex justify-between w-full px-3'>
                                        <button onClick={onSubmitData} className='bg-red-500 text-white font-bold py-2 px-4 w-full rounded hover:bg-red-600 border-b-4 border-red-700'>Publicar Ahora</button>
                                    </div>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
}

export default AddBalance;