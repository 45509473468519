import { FaShoppingCart, FaWindowClose, FaRegTrashAlt } from "react-icons/fa"

const SidebarCart = () => {
  return (
    <div class='acart w-full fixed z-50 right-0 top-0'>
      <div class='overlay fixed bg-gray-700 h-full w-full opacity-50 top-0 left-0'></div>
      <div class='acart-box w-full relative pb-0 text-gray-800 bg-white shadow-lg ml-auto'>
        <div class='flex flex-col p-4 h-screen overflow-auto'>
          <div class='acart-top overflow-auto flex-1 pt-10'>
            <div class='acart-header bg-white absolute w-full left-0 top-0 z-10 px-4 py-2 flex items-center'>
              <button class='close inline-flex text-xl  hover:text-red-700 mr-2'>
                <FaWindowClose />
              </button>

              <h2 class='inline-flex text-xl font-bold p-2 items-center space-x-3'>
                <span>Carrito de compras</span> <FaShoppingCart />
              </h2>

              <hr />
            </div>

            <table class='w-full text-sm' cellspacing='0'>
              <thead>
                <tr class='h-10 uppercase text-left'>
                  <th class=''></th>
                  <th class=''>Producto</th>
                  <th class=''>
                    <span class='' title='Cantidad'>
                      Cant.
                    </span>
                  </th>
                  <th class='pl-2'>Total</th>
                  <th class=''></th>
                </tr>
              </thead>
              <tbody class=''>
                <tr>
                  <td class='pb-3'>
                    <a href='()'>
                      <img src='https://portal-next.s3.amazonaws.com/5_cca0007e4c.jpg' class='w-10 h-10 rounded object-cover' alt='Thumbnail' />
                    </a>
                  </td>
                  <td class='pl-1 pr-1 pb-3'>
                    <a href='()'>
                      <div class='acart-pname'> Lorem ipsum dolor. </div>
                    </a>
                  </td>
                  <td class='pb-3'>
                    <div class='w-11'>
                      <div class='relative flex flex-row w-full h-8'>
                        <input type='number' value='1' class='w-full font-semibold text-center text-gray-700 bg-gray-200 outline-none' />
                      </div>
                    </div>
                  </td>

                  <td class='pl-2 pb-3'>
                    <span class='text-sm font-medium whitespace-nowrap'>S/. 20.00</span>
                  </td>

                  <td class='text-center pl-2 pb-3'>
                    <button class='text-sm hover:text-red-700'>
                      <FaRegTrashAlt />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class='buttom'>
            <div class='pt-3'>
              <div class='text-sm '>
                <div class='p-4 bg-gray-100 rounded-full'>
                  <h1 class='ml-2 font-bold uppercase'>Detalles del Orden</h1>
                </div>
                <div class='p-4 '>
                  <div class='flex justify-between font-bold border-b'>
                    <div class='m-2 text-gray-800'>Subtotal</div>
                    <div class='m-2 text-gray-900'>S/. 150,00</div>
                  </div>

                  <div class='flex justify-between font-bold border-b'>
                    <div class='m-2 text-gray-800'>Total</div>
                    <div class='m-2 text-lg text-gray-900'>S/. 17,859</div>
                  </div>

                  <hr />

                  <button class='flex items-center justify-center w-full px-10 py-3 mt-6 font-medium text-white uppercase bg-gray-800 rounded-full shadow item-center hover:bg-gray-700 focus:shadow-outline focus:outline-none'>
                    <svg aria-hidden='true' data-prefix='far' data-icon='credit-card' class='w-8' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'>
                      <path fill='currentColor' d='M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z' />
                    </svg>
                    <span class='ml-3  mt-5px'>Realizar Pedido</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidebarCart
