import React, { useState } from "react"
import Spinner from "components/utilities/spinner"

const PanelAdmin = ({file, loaded, initialState, name, menus }) => {
  const [optionSelected, setOptionSelected] = useState(initialState)
  const options = menus.map((menu) => ({
    name: menu.name,
    title: menu.title,
    menu: menu.option,
    condition: optionSelected.name === menu.name,
    action: () => {
      menu.action ? menu.action() : onChangeOption(menu.name)
    },
    component: menu.component,
  }))
  const onChangeOption = (name) => {
    console.log(options)
    console.log(name)
    const option = options.find((option) => option.name === name)
    console.log(option)
    setOptionSelected(option)
  }

  return (
    <>
      <div className='user-panel bg-gray-100'>
        {loaded ? (
          <Spinner />
        ) : (
          <div className='container mx-auto px-4'>
            <div className='sm:flex'>
              <div className='flex-shrink-0 sm:w-64 bg-gray-100'>
                <div className='flex items-center justify-center border-b-2 border-gray-300 pt-4'>
                  <div className='slogo text-center w-full pb-4'>
                    <img width='100' height='100' className='rounded-full w-16 h-16 border-2 border-white m-auto' loading='lazy' src={file?file:'https://www.ganecom.com/demo/images/affiliate-3.jpg'} alt='' />

                    <h3 className='font-bold text-gray-500'>{name}</h3>
                  </div>
                </div>

                <div className='pb-10'>
                  <ul className='mt-3 sm:text-right lateral-menu-user'>
                    {options.map((option, key) => (
                        option.menu !== '' && 
                          <li className={option.condition ? "active" : ""} key={key}>
                          <span onClick={option.action} className={`block cursor-pointer text-gray-700 py-2 px-3 transition duration-300 ease-in-out bg-gradient-to-r ${option.condition ? "from-gray-100 via-white to-white bg-white" : "hover:from-gray-100 hover:to-white"}`}>
                            {" "}
                            {option.menu}{" "}
                          </span>
                        </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='flex-grow flex flex-col pb-8 sm:pb0'>
                <div className='relative flex-shrink-0'>
                  <div className='flex justify-between items-center h-16'>
                    <div className='relative w-64'>
                      <h2 className='font-bold uppercase'>{optionSelected.title}</h2>
                    </div>

                    <div className='flex items-center'>
                      <button className='back bg-gray-200 hover:bg-gray-600 hover:text-white text-gray-700 py-2 px-3 border-b-4 border-red-700 hover:border-gray-500 rounded transition duration-300 ease-in-out'>Volver</button>
                    </div>
                  </div>
                </div>

                <div className='flex-grow flex flex-col'>
                  <div className='flex-grow'>{optionSelected.component}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default PanelAdmin
