import React from "react"
import { Link } from "react-router-dom"

const UserInit = ({ affiliates, pages, gain }) => {
  console.log(affiliates)
  return (
    <>
      <div className='bg-white'>
        <div className='p-4 sm:p-8'>
          <div className='grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mb-8'>
            <div className='p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-green-500 text-white'>
              <div className='flex items-start justify-between'>
                <div className='flex flex-col space-y-2'>
                  <strong className='text-xl font-bold'>S/ {parseFloat(gain).toFixed(2)}</strong>
                  <span className='text-sm'>Mis Ganancias</span>
                </div>
                <div className='p-8 bg-gray-200 rounded-md'></div>
              </div>
            </div>
            <div className='p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-gray-100'>
              <div className='flex items-start justify-between'>
                <div className='flex flex-col space-y-2'>
                  <strong className='text-xl font-bold text-green-600'>{affiliates.length}</strong>
                  <span className='text-sm'>Afiliados</span>
                </div>
                <div className='p-8 bg-gray-300 rounded-md'></div>
              </div>
            </div>
            <div className='p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-gray-100'>
              <div className='flex items-start justify-between'>
                <div className='flex flex-col space-y-2'>
                  <strong className='text-xl font-bold text-blue-700'>0</strong>
                  <span className='text-sm'>Descripcion</span>
                </div>
                <div className='p-8 bg-gray-300 rounded-md'></div>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3'>
            <div className='p-4 transition-shadow border rounded-lg shadow-sm col-span-2'>
              <div className='flex-grow flex flex-col'>
                <div className='flex-grow'>
                  <div className='actions bg-white flex items-center justify-between p-4'>
                    <div className='flex items-center space-x-2'>
                      <h3 className='font-bold text-gray-500'>ULTIMOS AFILIADOS</h3>
                    </div>
                    <div className='right'>
                      <button className='bg-green-600 text-white hover:bg-red-600 rounded py-2 px-6 md:px-12'>Ver Todo</button>
                    </div>
                  </div>
                  <div className='overflow-x-auto'>
                    <div className='inline-block min-w-full pb-2 align-middle'>
                      <div className='border-b border-gray-200 rounded-md'>
                        <table className='min-w-full overflow-x-scroll divide-y divide-gray-200'>
                          <tbody className='bg-white divide-y divide-gray-200'>
                            {affiliates.map((affiliate, key) => (
                              <tr className='transition-all hover:bg-gray-100 hover:shadow-lg' key={key}>
                                <td className='px-6 py-4 whitespace-nowrap'>
                                  <div className='flex items-center'>
                                    <div className='flex-shrink-0 w-10 h-10'>
                                      <img className='w-10 h-10' src={affiliate.user.profile ? affiliate.user.profile : "https://avatars0.githubusercontent.com/u/57622665?s=460&amp;u=8f581f4c4acd4c18c33a87b3e6476112325e8b38&amp;v=4"} alt='' />
                                    </div>
                                    <div className='ml-4'>
                                      <div className='text-sm font-medium text-gray-900'>
                                        {affiliate.user.name} {affiliate.user.last_name}
                                      </div>
                                      <div className='text-sm text-gray-500'>ciudad</div>
                                    </div>
                                  </div>
                                </td>

                                <td className='px-6 py-4 whitespace-nowrap'>
                                  <span className='inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full'> {affiliate.user.active ? "Activo" : "Inactivo"} </span>
                                </td>
                                <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                                  <div className='flex items-center space-x-3'>
                                    <a className='bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded' href='{#}'>
                                      Ver
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-gray-100'>
              {pages.length > 0 ? (
                <div>
                  <span>Paginas</span>
                  {pages.map((page, key) => (
                    <Link key={key} to={`/page/${page._id}`}>
                      {" "}
                      {page.business_name}
                    </Link>
                  ))}
                </div>
              ) : (
                <>
                  <h2 className='font-bold text-center mb-4'>¿Tienes productos para vender?</h2>
                  <p className='text-center mb-6'>Crea una pagina para vender sus productos totalmente GRÁTIS</p>
                  <button className='block w-full bg-red-600 text-white hover:bg-red-700 rounded py-2 px-6 md:px-6'>
                    <Link to='/create/page'>Crear página</Link>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserInit
