import React, { useEffect, useState } from 'react';
import Spinner from 'components/utilities/spinner';
import { PageList } from 'api/page';
import CreditAdd from './AddCredit';

const AdminCredit = () => {
    const [pages, setPages] = useState([])
    const [loaded, setLoaded] = useState(true)
    const [action, setAction] = useState(false)

    const InitialData = async() => {
        setLoaded(true)
        const result = await PageList();
        if(result.pages){
            setPages(result.pages);
        }
        setLoaded(false)
    }

    useEffect(() => {
        const InitialData = async() => {
            setLoaded(true)
            const result = await PageList();
            if(result.pages){
                setPages(result.pages);
            }
            setLoaded(false)
        }
        InitialData()
    },[])

    return(
        <div className="bg-white">
        <div className="p-4 sm:p-8">
        <div className="actions bg-white flex items-center justify-between p-4">
                    <div className="flex items-center space-x-2">
                        <h3 className="font-bold text-gray-500">Buscar</h3>
                        <input aria-label="Buscar" className="bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2" placeholder="Buscar Negocio" type="search" />
                    </div>
                    <div className="right">
                    <button className="bg-green-600 text-white hover:bg-red-600 rounded py-2 px-6 md:px-12" onClick={()=>{setAction(true);}}>Añadir credito</button>
                    </div>
                </div>
        {
            loaded?
            <Spinner />
            :
            action?
            <CreditAdd onFinish={() => {setAction(false); InitialData()}}/>
            :
            <div>
                
                {
                    <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                    <tbody className="bg-white divide-y divide-gray-200">
                    {
                          pages.map((page, id) => (
                       <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 w-10 h-10">
                              <img className="w-10 h-10" src="https://avatars0.githubusercontent.com/u/57622665?s=460&amp;u=8f581f4c4acd4c18c33a87b3e6476112325e8b38&amp;v=4" alt="" />
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">{page.business_name}</div>
                              <div className="text-sm text-gray-500">Ciudad</div>
                            </div>
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                        <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">S/. {page.credit} </span> 
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {/* <div className="flex items-center space-x-3">
                            <a className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded" href="#">Afiliados</a>
                          </div> */}
                        </td>
                      </tr>
                        ))
                      }

                    </tbody>
                  </table>
                   
                }
            </div>
        }
        </div>
    </div>
    )
}

export default AdminCredit;