import React, { useState } from 'react'
// import { Link } from 'react-router-dom'
import UpdateChangePass from './changePass'

const UserSetting = ({ code }) => {
  const [option, setOption] = useState(false)

  return (
    <>
      {option ? (
        <UpdateChangePass onFinish={() => setOption(false)} />
      ) : (
        <div className='bg-white p-4 sm:p-8'>
          <h1 className='font-bold text-xl mb-4'>Configuracion</h1>

          <p className='mb-4'>
            <strong>Url:</strong> <br />{' '}
            <span>
              https://ganecom.com/{process.env.REACT_APP_URL_FRONT}register/{code}
            </span>
          </p>

          <button className='bg-green-600 text-white hover:bg-red-600 rounded py-2 px-6 md:px-12' onClick={() => setOption(true)}>
            Cambiar Contraseña
          </button>
        </div>
      )}
    </>
  )
}

export default UserSetting
