import 'components/utilities/loader.css'
const Spinners = () => {
  return (
    <>
      <div className='op-loading'></div>
    </>
  )
}

export default Spinners
