import React, {useEffect, useState} from 'react';
import CardProduct from 'components/Product/CardProduct';
import Spinner from 'components/utilities/spinner';
import { ProductListBySimilars } from 'api/product';

const SimilarProduct = ({page, name}) => {

    const [products, setProduct] = useState([])
    const [loaded, setLoaded] = useState(true)

    useEffect(() => {
      const InitialData = async() => {
        setLoaded(true)
        const result = await ProductListBySimilars(page, name );
        if(result.products){
          setProduct(result.products.map(product => ({
            featured_image:product.featured_images[0]?product.featured_images[0]:"https://picsum.photos/id/10/50/50",
            ...product,
            price: Number.parseFloat(product.price).toFixed(2).split("."),
            total: Number.parseFloat(product.descount?product.price_descount:product.price).toFixed(2).split("."),
            mount_commission: Number.parseFloat((product.commission_product * 40) / 100).toFixed(2),
          })).slice(0,4))
        }
        setLoaded(false)
      }
      InitialData();
    },[page, name])  


    return(
      <div className='offers bg-gray-100 col-span-3'>
        <div className='container mx-auto p-8'>
          <div className='text-center mb-6'>
            <h2 className='sm:text-3xl text-2xl font-medium title-font text-yellow-600'>Productos Similares</h2>
            <p className='text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s'>He aquí nuestras nuevas ofertas que tenemos para usted.</p>
            <div className='flex mt-4 justify-center'>
              <span className='w-16 h-1 rounded-full bg-yellow-500 inline-flex'></span>
            </div>
          </div>

          <div className='items sm:grid sm:grid-cols-2 sm:gap-6 lg:grid-cols-4'>

            {/* product with offer */}
            {

                loaded?
                <Spinner />
                :
                products.map((product, key) => (
                  <CardProduct product={product} key={key} />
                ))
            }

          </div>
        </div>
      </div>
    )
}

export default SimilarProduct;