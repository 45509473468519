import React from 'react';

const AdminInit = ({ gain }) => {
    console.log(gain)
    return (
      <>
        <div className='bg-white'>
          <div className='p-4 sm:p-8'>
            <div className='grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mb-8'>
              <div className='p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-green-500 text-white'>
                <div className='flex items-start justify-between'>
                  <div className='flex flex-col space-y-2'>
                    <strong className='text-xl font-bold'>S/ {parseFloat(gain).toFixed(2)}</strong>
                    <span className='text-sm'>Mis Ganancias</span>
                  </div>
                  <div className='p-8 bg-gray-200 rounded-md'></div>
                </div>
              </div>
              <div className='p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-gray-100'>
                <div className='flex items-start justify-between'>
                  <div className='flex flex-col space-y-2'>
                    <strong className='text-xl font-bold text-green-600'>0</strong>
                    <span className='text-sm'>Descripcion</span>
                  </div>
                  <div className='p-8 bg-gray-300 rounded-md'></div>
                </div>
              </div>
              <div className='p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-gray-100'>
                <div className='flex items-start justify-between'>
                  <div className='flex flex-col space-y-2'>
                    <strong className='text-xl font-bold text-blue-700'>0</strong>
                    <span className='text-sm'>Descripcion</span>
                  </div>
                  <div className='p-8 bg-gray-300 rounded-md'></div>
                </div>
              </div>
            </div>
  
            <div className='grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3'>
              <div className='p-4 transition-shadow border rounded-lg shadow-sm col-span-2'>
                <div className='flex-grow flex flex-col'>
                  <div className='flex-grow'>
                    <div className='actions bg-white flex items-center justify-between p-4'>
                      <div className='flex items-center space-x-2'>
                        <h3 className='font-bold text-gray-500'>Descripcion</h3>
                      </div>
                      <div className='right'>
                        <button className='bg-green-600 text-white hover:bg-red-600 rounded py-2 px-6 md:px-12'>Ver Todo</button>
                      </div>
                    </div>
                    <div className='overflow-x-auto'>
                      <div className='inline-block min-w-full pb-2 align-middle'>
                        <div className='border-b border-gray-200 rounded-md'>
                          <table className='min-w-full overflow-x-scroll divide-y divide-gray-200'>
                            <tbody className='bg-white divide-y divide-gray-200'>
                              
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-gray-100'>
              Descripcion
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

export default AdminInit;