import React from 'react'

import Logo from 'images/logo-ganecom.png'

const Footer = () => {
  return (
    <footer className='bg-gray-800 text-white'>
      <div className='container mx-auto py-8 px-4'>
        <div className='md:flex md:flex-wrap md:-mx-4 py-6 md:pb-12'>
          <div className='footer-info lg:w-1/3 md:px-4'>
            <img className='mb-8 filter brightness-0 invert max-w-xs' src={Logo} alt='' />
            <h4 className='text-white text-2xl mb-4'>19K usuarios utilizan GaneCom y les facilitan la vida.</h4>
            <p className='text-gray-400'>Hemos elaborado cuidadosamente las ofertas para satisfacer las necesidades de todos.</p>
            <div className='mt-4'>
              <button className='bg-facebook py-2 px-4 text-white rounded mt-2 transition-colors duration-300'>
                <span className='fab fa-facebook-f mr-2'></span> Dale me gusta
              </button>
              <button className='bg-twitter py-2 px-4 text-white rounded ml-2 mt-2 transition-colors duration-300'>
                <span className='fab fa-twitter mr-2'></span> Siguenos @ganecom
              </button>
            </div>
          </div>

          <div className='md:w-2/3 lg:w-2/3 md:px-4 xl:pl-16 mt-12 lg:mt-0'>
            <div className='sm:flex'>
              <div className='sm:flex-1'>
                <h6 className='text-base font-medium text-white uppercase mb-2'>Servicio al Cliente</h6>
                <div>
                  <a href='https://ganecom.com/quienes-somos' className='text-gray-400 py-1 block hover:underline'>
                    ¿Quiénes somos?
                  </a>

                  <a href='https://ganecom.com/como-funciona' className='text-gray-400 py-1 block hover:underline'>
                    ¿Cómo funciona?
                  </a>
                  <a href='https://ganecom.com/objetivo' className='text-gray-400 py-1 block hover:underline'>
                    Objetivo
                  </a>
                </div>
              </div>
              <div className='sm:flex-1 mt-4 sm:mt-0 xl:pl-16'>
                <h6 className='text-base font-medium text-white uppercase mb-2'>Contáctanos</h6>
                <div>
                  <div className='mb-3'>
                    Teléfono: (01) 123-4567 <br />
                    Email: info@ganecom.com
                  </div>

                  <p className='text-gray-400 mb-3'>Al contrario del pensamiento popular, el texto de Lorem Ipsum no es simplemente texto aleatorio. Tiene sus raices en una pieza cl´sica de la literatura del Latin, que data del año 45 antes de Cristo, haciendo que este adquiera mas de 2000 años</p>

                  <button className='bg-indigo-600 text-white hover:bg-indigo-700 rounded py-2 px-6 md:px-12 transition-colors duration- mt-3'>Explorar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='border-t border-solid border-gray-900 mt-4 py-4'>
        <div className='container px-4 mx-auto'>
          <div className='md:flex md:-mx-4 md:items-center'>
            <div className='md:flex-1 md:px-4 text-center md:text-left'>
              <p className='text-white'>
                &copy; <strong>GANECOM </strong> 2021
              </p>
            </div>
            <div className='md:flex-1 md:px-4 text-center md:text-right'>
              <a href='https://ganecom.com/terminos-y-condiciones' className='py-2 px-4 text-white inline-block hover:underline'>
                Términos y Condiciones
              </a>
              <a href='https://ganecom.com//politica-de-privacidad' className='py-2 px-4 text-white inline-block hover:underline'>
                Política de privacidad
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
