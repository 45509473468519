import React, { useEffect, useState } from 'react'
import Toast from 'components/Toast'
import Spinner from 'components/utilities/spinner'
import { CategoryGet, CategoryRegister, CategoryUpdate } from 'api/category'

const CategoryAdd = ({ onFinish, data}) => {
  const [category, setCategory] = useState({categoryId: '',name: '', slug: ''})
  const [loaded, setLoaded] = useState(true)
  
  useEffect(() => {
    const getCategory = async() => {
      await setLoaded(true)
      const result = await CategoryGet(data.categoryId);
      if(result.categoryId){
        setCategory({
          ...result,
          categoryId: data.categoryId,
        })
      }
      await setLoaded(false)
    }
    if(data.action === "update"){
      getCategory();
    } else {
      setLoaded(false);
    }
  }, [data])

  const onAlert = async (icon, msg) => {
    Toast(icon, msg);
  }

  const onChangeData = ({target: {value, name}}) => {
    setCategory({
      ...category,
      [name]: value
    })
  }
  const onSubmitData = async(e) => {
    e.preventDefault();
    try {
      if(category.name === '' || category.slug === ''){
        await onAlert('warning', 'Complete los datos requeridos por favor');
        return;
      }

      if(data.action === "update"){
        const result = await CategoryUpdate(category);
        if(result.categoryId) {
          await onAlert('success', 'categoria actualizada con exito');
          setTimeout(() => {
            onFinish();
          }, [4800])
        } else {
          await onAlert('error', result.message)
        }
      } else {
        const result = await CategoryRegister(category);
        if(result.categoryId) {
          await onAlert('success', 'categoria creada con exito');
          setTimeout(() => {
            onFinish();
          }, [4800])
        } else {
          await onAlert('error', result.message)
        }
      }

    } catch (error) {
      console.log(error)
    }
  }
  
  return (
    <>
      <div className='featured-item featured-item-inner bg-gray-100'>
        <div className='container mx-auto px-4'>
          <div className='sm:grid sm:grid-cols-4 sm:gap-6'>
            <div className='left-area bg-white sm:col-span-3'>
             
           
            <div className='grid grid-flow-col gap-4'>
              {
                loaded?
                <Spinner/>
                :
                <form className='p-10'>
                <h1 className='sm:text-3xl text-2xl font-medium title-font mb-6'>{data.action==="update"?"Actualizar":"Publicar"} Categoria</h1>
                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' forLabel='nombre-p'> Nombre de Categoria </label>
                    <input className='appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='nombre-p' type='text' placeholder='Jane' name='name' value={category.name} onChange={onChangeData} />
                    <p className='text-red-500 text-xs italic'>Requerido.</p>
                  </div>
                  <div className='w-full md:w-1/2 px-3'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' forLabel='precio'>  SLUG (Url)  </label>
                    <input className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' id='precio' type='text' placeholder='Doe' name='slug' value={category.slug} onChange={onChangeData}/>
                  </div>
                </div>


                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full px-3'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' forLabel=''> Icono </label>
                    <input aria-label='imagen' className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' id='grid-email' type='file' placeholder=''/>
                  </div>
                </div>

                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='flex justify-between w-full px-3'>
                    <button onClick={onSubmitData} className='bg-red-500 text-white font-bold py-2 px-4 w-full rounded hover:bg-red-600 border-b-4 border-red-700'>{data.action==="update"?"Actualizar":"Publicar"} Ahora</button>
                  </div>
                </div>
              </form>
              }
            </div>


            </div>
          </div>
        </div>
      </div>
     
    </>
  )
}

export default CategoryAdd
