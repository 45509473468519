import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const Toast = (icon, msg) => {
    const toast = MySwal.mixin({
        toast: true,
        position: 'center',
        timer: 4500,
        timerProgressBar: true,
    });
    toast.fire({icon, text: msg, showConfirmButton: false})
}

export default Toast