import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}api/pages`,
    headers: {
        'content-type': 'application/json',
    }
})

export const PageRegister = async(data, token) => {
    const result = await api.post('/register', data, {headers: {
        'x-access-token': token
    }})
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const PageListByUser = async(token) => {
    const result = await api.get('/list-by-user', {headers: {
        'x-access-token': token
    }})
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const PageList = async(page) => {
    const result = await api.get(`/list`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const PageGet = async(page) => {
    const result = await api.get(`/${page}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const PageBySlugGet = async(page) => {
    const result = await api.get(`/name/${page}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const PageAddCredit = async(data) => {
    const result = await api.put('/add-credit', data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const PageVerify = async(data) => {
    const result = await api.put('/verify', data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const PageUpdate = async(data, pageId) => {
    const result = await api.put(`/${pageId}`, data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}