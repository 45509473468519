import React, { useEffect, useState, Fragment } from 'react';
import { CategoryDelete, CategoryList } from 'api/category';
import Spinner from 'components/utilities/spinner';
import CategoryAdd from './addCategory';
import DepartamentAdd from './addDepartament';
import SubCategoryAdd from './addSubcategory';
import { DepartamentDelete, DepartamentList } from 'api/departament';
import { ModalConfirm } from 'components/Modal';
import { SubCategoryDelete } from 'api/subCategory';

const AdminConfig = () => {
    const [categories,setCategories] = useState([]);
    const [departaments,setDepartaments] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [option, setOption] = useState({isActive: false, action: '', name: ''})

    const InitialData = async() => {
        setLoaded(true)
        const result = await CategoryList();
        if(result.categories) {
            setCategories(result.categories);
        }
        const result2 = await DepartamentList();
        if(result2.departaments){
            setDepartaments(result2.departaments)
        }
        setLoaded(false)
    }

    useEffect(() => {
        const InitialData = async() => {
            setLoaded(true)
            const result = await CategoryList();
            if(result.categories) {
                setCategories(result.categories);
            }
            const result2 = await DepartamentList();
            if(result2.departaments){
                setDepartaments(result2.departaments)
            }
            setLoaded(false)
        }
        InitialData();
    },[])
    
    const onDeleteCategory = async(id) => {
        const result = await CategoryDelete(id)
        if(result.deleted){
            await InitialData();
            return true
        }   else {
            return false
        }
    }

    const onDeleteSubCategory = async(id) => {
        const result = await SubCategoryDelete(id)
        if (result.deleted) {
            await InitialData();
            return true
        } else {
            return false
        }
    }

    const onDeleteDepartament = async (id) => {
        const result = await DepartamentDelete(id)
        if (result.deleted) {
            await InitialData();
            return true
        } else {
            return false
        }
    }

    const OnDelete = (action, msg) => ModalConfirm(() => action(), msg)

    return(
        <div className="bg-white">
            {
                loaded?
                <Spinner />
                :
                option.isActive?
                option.name === "category"?
                <CategoryAdd onFinish={()=>{setOption({isActive: false, action: '', name: ''}); InitialData()}} data={option}/>
                :
                option.name === "departament"?
                <DepartamentAdd onFinish={()=>{setOption({isActive: false, action: '', name: ''}); InitialData()}} data={option}/>
                :
                option.name === "subCategory" &&
                <SubCategoryAdd onFinish={()=>{setOption({isActive: false, action: '', name: ''}); InitialData()}} data={option} />
                :
                
                <div className="p-4 sm:p-8">
                    <div>Configuracion general</div>
                    <div>Categorias </div>
                        <div className="actions bg-white flex items-center justify-between p-4">
                            <div className="flex items-center space-x-2">
                                <h3 className="font-bold text-gray-500">Buscar</h3>
                                <input aria-label="Buscar" className="bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2" placeholder="Buscar Negocio" type="search" />
                            </div>
                            <div className="right">
                                <button className="bg-green-600 text-white hover:bg-red-600 rounded py-2 px-6 md:px-12"onClick={() => {setOption({isActive: true, action: 'create', name: 'category'})}}>Añadir categoria</button>
                            </div>
                    </div>
                    <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200">
                        {
                            categories.map((category, id) => (
                                <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {category.name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                    </td>
                                    <td className="flex px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                        <button className="flex items-center space-x-3" onClick={() => {setOption({isActive: true, action: 'update', name: 'category', categoryId: category._id})}}>
                                            <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded">Editar</span>
                                        </button>
                                        <button className="flex items-center pl-1 space-x-3" onClick={() => {OnDelete(() =>onDeleteCategory(category._id),'¿Esta seguro de eliminar esta  categoria?')}}>
                                            <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded">Eliminar</span>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                    <hr />
                    <div>SubCategorias </div>
                    <div className="actions bg-white flex items-center justify-between p-4">
                        <div className="flex items-center space-x-2">
                            <h3 className="font-bold text-gray-500">Buscar</h3>
                            <input aria-label="Buscar" className="bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2" placeholder="Buscar Negocio" type="search" />
                        </div>
                        <div className="right">
                            <button className="bg-green-600 text-white hover:bg-red-600 rounded py-2 px-6 md:px-12"onClick={() => {setOption({isActive: true, action: 'create', name: 'subCategory'})}}>Añadir subCategoria</button>
                        </div>
                    </div>
                    <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200">
                        {
                            categories.map((category, id) => (
                                <Fragment key={id}>
                                    {
                                        category.subCategories.map((subCategory, key) => (
                                            <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={key}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {category.name}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {subCategory.name}
                                                </td>
                                                <td className="flex px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                    <button className="flex items-center space-x-3" onClick={() => {setOption({isActive: true, action: 'update', name: 'subCategory', subCategoryId: subCategory._id})}}>
                                                        <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded">Editar</span>
                                                    </button>
                                                    <button className="flex items-center pl-1 space-x-3" onClick={() => {OnDelete(() =>onDeleteSubCategory(subCategory._id),'¿Esta seguro de eliminar esta  subcategoria?')}}>
                                                        <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded">Eliminar</span>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </Fragment>
                            ))
                        }
                        </tbody>
                    </table>
                    <hr />
                    <div>Departamentos </div>
                    <div className="actions bg-white flex items-center justify-between p-4">
                        <div className="flex items-center space-x-2">
                            <h3 className="font-bold text-gray-500">Buscar</h3>
                            <input aria-label="Buscar" className="bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2" placeholder="Buscar Negocio" type="search" />
                        </div>
                        <div className="right">
                            <button className="bg-green-600 text-white hover:bg-red-600 rounded py-2 px-6 md:px-12"onClick={() => {setOption({isActive: true, action: 'create', name: 'departament'})}}>Añadir Departamento</button>
                        </div>
                    </div>
                    <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200">
                        {
                            departaments.map((departament, id) => (
                                <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {departament.name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                    </td>
                                    <td className="flex px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                        <button className="flex items-center space-x-3" onClick={() => {setOption({isActive: true, action: 'update', name: 'departament', departamentId: departament._id})}}>
                                            <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded">Editar</span>
                                        </button>
                                        <button className="flex items-center pl-1 space-x-3" onClick={() => {OnDelete(() =>onDeleteDepartament(departament._id),'¿Esta seguro de eliminar este  departamento?')}}>
                                            <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded">Eliminar</span>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default AdminConfig;