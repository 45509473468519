import React from 'react'

import MetaTags from 'react-meta-tags'

const Objetivo = () => {
  return (
    <>
      <MetaTags>
        <title>Objetivo</title>
        <meta name='description' content='El objetivo principal de Ganecom es crear una nueva forma de comprar y/o consumir productos y/o servicios y poder ahorrar como también tener ingresos por referir los productos sin necesidad de vender' />
      </MetaTags>

      <section class='text-gray-700 body-font border-t border-gray-200'>
        <div class='container px-5 py-24 mx-auto'>
          <div class='flex flex-wrap w-full mb-20 flex-col  '>
            <h1 class='sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 text-center'>Objetivo</h1>
            <br />
            <small>Última actualización: 13 de Diciembre de 2021</small>
            <br />
            <p class='leading-relaxed text-base mb-4'>El objetivo principal de Ganecom es crear una nueva forma de comprar y/o consumir productos y/o servicios y poder ahorrar como también tener ingresos por referir los productos sin necesidad de vender, más bien haciendo lo que siempre hicimos, comprando y recomendando, obteniendo por ello una ganancia de todas tus compras y de las compras de tus referidos.</p>

            <p className='mb-4'>Vender incluso tus propios productos y/o servicios, si tienes un Negocio que reúne los requisitos para formar parte de la red de Negocios Aliadas a Ganecom, ¿te imaginas? Podrás vender a los miles de miembros de la Red Ganecom. </p>

            <br />
            <i className='text-center'>
              Ganecom <strong>"gane comprando, gane compartiendo"</strong>
            </i>
          </div>
        </div>
      </section>
    </>
  )
}

export default Objetivo
