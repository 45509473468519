import React, { useEffect, useState } from 'react'
import Toast from 'components/Toast'
import Spinner from 'components/utilities/spinner'
import { SubCategoryGet, SubCategoryRegister, SubCategoryUpdate } from 'api/subCategory'
import { CategoryList } from 'api/category'

const SubCategoryAdd = ({ onFinish, data}) => {
  const [subCategory, setSubCategory] = useState({category: '',name: '', subCategoryId: ''})
  const [categories, setCategories] = useState([])
  const [loaded, setLoaded] = useState(true)
  
  useEffect(() => {
    const dataInitial = async() => {
      await setLoaded(true)
        const result = await CategoryList();
        if(result.categories){
            setCategories(result.categories);
          }
        await setLoaded(false)
    }
    const getSubCategory = async() => {
      await setLoaded(true)
      const result = await SubCategoryGet(data.subCategoryId);
      if(result.subCategoryId){
        setSubCategory({
          ...result,
          subCategoryId: data.subCategoryId,
        })
      }
      dataInitial();
    }

    
    if(data.action === "update"){
      getSubCategory();
    } else {
      dataInitial()
    }
    
  }, [data])

  const onAlert = async (icon, msg) => {
    Toast(icon, msg);
  }

  const onChangeData = ({target: {value, name}}) => {
    setSubCategory({
      ...subCategory,
      [name]: value
    })
  }
  const onSubmitData = async(e) => {
    e.preventDefault();
    try {
      if(subCategory.name === '' || subCategory.category === ''){
        await onAlert('warning', 'Complete los datos requeridos por favor');
        return;
      }

      if(data.action === "update"){
        const result = await SubCategoryUpdate(subCategory);
        if(result.subCategoryId) {
          await onAlert('success', 'sub-categoria actualizada con exito');
          setTimeout(() => {
            onFinish();
          }, [4800])
        } else {
          await onAlert('error', result.message)
        }
      } else {
        const result = await SubCategoryRegister(subCategory);
        if(result.subCategoryId) {
          await onAlert('success', 'sub-categoria creada con exito');
          setTimeout(() => {
            onFinish();
          }, [4800])
        } else {
          await onAlert('error', result.message)
        }
      }

    } catch (error) {
      console.log(error)
    }
  }
  
  return (
    <>
      <div className='featured-item featured-item-inner bg-gray-100'>
        <div className='container mx-auto px-4'>
          <div className='sm:grid sm:grid-cols-4 sm:gap-6'>
            <div className='left-area bg-white sm:col-span-3'>
             
           
            <div className='grid grid-flow-col gap-4'>
              {
                loaded?
                <Spinner/>
                :
                <form className='p-10'>
                <h1 className='sm:text-3xl text-2xl font-medium title-font mb-6'>{data.action==="update"?"Actualizar":"Publicar"} Subcategoria</h1>
                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' forLabel='nombre-p'> Nombre de Subcategoria </label>
                    <input className='appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='nombre-p' type='text' placeholder='Jane' name='name' value={subCategory.name} onChange={onChangeData} />
                    <p className='text-red-500 text-xs italic'>Requerido.</p>
                  </div>
                  <div className='w-full md:w-1/2 px-3'>
                    <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' forLabel='precio'>  Categoria  </label>
                    <select className='block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='' name='category' value={subCategory.category} onChange={onChangeData}>
                      <option className='' value="">Seleccione Categoría</option>
                      {
                        categories.map((category, key) => (
                          <option value={category._id} key={key} className=''>{category.name}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='flex justify-between w-full px-3'>
                    <button onClick={onSubmitData} className='bg-red-500 text-white font-bold py-2 px-4 w-full rounded hover:bg-red-600 border-b-4 border-red-700'>{data.action==="update"?"Actualizar":"Publicar"} Ahora</button>
                  </div>
                </div>
              </form>
              }
            </div>


            </div>
          </div>
        </div>
      </div>
     
    </>
  )
}

export default SubCategoryAdd
