import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Logo from "images/logo-ganecom.png"
import ShoppingCart from "components/shoppingCart"
import { AuthContext } from "context/authContext"
import { ShoppingCartContext } from "context/shoppingCartContext"
import { CategoryList } from "api/category"
import { DepartamentList } from "api/departament"

const Header = () => {
  const [displayCategory, setDisplayCategory] = useState(false);
  const { isLoggedIn } = useContext(AuthContext);
  const {ProductsCount, openCart, setOpen} = useContext(ShoppingCartContext)
  const [categories, setCategories] = useState([])
  const [departaments, setDepartaments] = useState([])
  const [departament, setDepartament] = useState('')
  const [search, setSearch] = useState('')

  useEffect(() => {
    const InitialData = async() => {
      const result = await CategoryList();
      console.log(result.categories)
      if(result.categories){
        setCategories(result.categories.slice(0,7));
      }
    }
    const InitialDat2 = async() => {
      const result = await DepartamentList();
      console.log(result.departaments)
      if(result.departaments){
        setDepartaments(result.departaments);
      }
    }
    InitialData();
    InitialDat2();
  },[])

  const onChangeDepartament = ({target: {value}}) => setDepartament(value);

  const onChangeSearch = ({target: {value}}) => setSearch(value)

  return (
    <>
      <header className='sticky top-0 z-50'>
          <div className='top-bar bg-gray-600'>
            <div className='container mx-auto flex items-center justify-between px-4 py-2'>
              <Link to='/' className='logo block mr-6'>
                {" "}
                <img width='150' src={Logo} alt='' /> <span className='hidden'>Logo</span>
              </Link>

              <button className='hamburger bg-white py-2 px-2 flex rounded transition duration-300 ease-in-out hover:text-yellow-500 mr-2' onClick={() =>{setDisplayCategory(!displayCategory)}}>
                <i></i>
                <span className='ml-2'>Categorías</span>
              </button>

              <form className='quick-search rounded flex-auto relative bg-white flex' action=''>
                <select className='hidden lg:block rounded-l bg-gray-200 cursor-pointer px-2' onChange={onChangeDepartament} name='' value={departament}>
                  <option value=''>Selecciona Ciudad</option>
                  <option value={departaments.map((departament => (departament._id)))}>Todas las Ciudades</option>
                  {
                    departaments.map((departament, key) => (
                      <option key={key} value={departament._id}>{departament.name}</option>
                    ))
                  }
                </select>
                <input className='hidden lg:block w-full py-2 px-5 pr-10 rounded' type='search' name='' aria-label='Buscar' placeholder='Buscar producto' onChange={onChangeSearch} value={search} />
                <Link to={{pathname: `/search/${search}`,state: {departamentId: departament}}} className='hidden lg:block bg-white w-16 hover:bg-red-500 rounded-r text-2xl transition duration-300 ease-in-out hover:text-white'>
                  <i className='lni lni-search-alt'></i>
                </Link>
              </form>

              <div className='links flex items-center text-white ml-1'>
                <button className='text-white text-xl lg:hidden'>
                  <i className='lni lni-search-alt'></i>
                </button>
                <div className='relative'>
                  <span className='cursor-pointer flex items-center mx-3 md:mx-8 transition duration-300 ease-in-out hover:text-yellow-500' onClick={() =>{setOpen(!openCart)}}>
                    <div className='text-xl'>
                      <i className='lni lni-cart'></i>
                    </div>
                    {
                      ProductsCount > 0 &&
                      <div className='counts w-4 h-4 bg-red-500 text-white text-xs flex items-center justify-center rounded-full'>{ProductsCount}</div>
                    }
                    <span className='hidden xl:block ml-1'>Mi Carrito</span>
                  </span>
                  <ShoppingCart isOpen={openCart} />
                </div>
                {
                  isLoggedIn?

                  <>              
                    <Link to='/profile' className='flex items-center transition duration-300 ease-in-out hover:text-yellow-500'>
                      <div className='text-xl'>
                        <i className='lni lni-user'></i>
                      </div>
                      <span className='hidden xl:block ml-1'>Mi Cuenta</span>
                    </Link>
                  </>
                  :
                  <>
                  <Link to='/login' className='flex justify-center transition duration-300 ease-in-out hover:text-yellow-500'>
                      {/* <div className='text-xl'>
                        <i className='lni lni-user'></i>
                      </div> */}
                      <div className='px-4'>Entrar</div>
                    </Link>
                   </>
                }
              </div>
            </div>
          </div>
          {displayCategory && (
          <nav className='hidden xl:block relative bg-white border-b-2 border-gray-300 text-gray-900'>
            <div className='container mx-auto px-4'>
              <ul className='flex divide-x divide-emerald-500 w-full justify-between text-center'>
                {
                  categories.map((category, key) => (
                    <li className='group w-full' key={key}>
                      <button className='outline-none focus:outline-none border py-3 px-3 flex items-center min-w-12rem'>
                        <span className='pr-1 flex-1'>{category.name}</span>
                        <span>
                          <svg className='fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                            <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                          </svg>
                        </span>
                      </button>
                      <ul className='bg-gray-600 text-white border rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-12rem divide-y divide-gray-500 text-left'>
                        {
                          category.subCategories.map((subCategory, id) => (
                            <li className='rounded-sm hover:bg-gray-800 transition duration-300 ease-in-out' key={id}>
                              <Link to={{pathname:`/search/${category.name.replace(/ /g,'-')}/${subCategory.name.replace(/ /g,'-')}`, state: {categoryId: category._id, subCategoryId: subCategory._id}}} className='py-2 px-3 block'>
                                {subCategory.name}
                              </Link>
                            </li>
                          ))
                        }
                        
                      </ul>
                    </li>
                  ))
                }
                {/* 
                <li className='w-full hoverable hover:bg-gray-600 hover:text-white transition duration-300 ease-in-out'>
                  <a href='#()' className='w-full relative block py-3 px-4 text-sm lg:text-base font-bold text-center'>
                    Hover
                  </a>
                  <div className='p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-gray-600'>
                    <div className='container w-full flex flex-wrap justify-between mx-auto'>
                      <div className='w-full text-white mb-8'>
                        <h2 className='font-bold text-2xl'>Mensaje principal para la sección del menú</h2>
                        <p>Mensaje de subhéroe, ni demasiado largo ni demasiado corto. ¡Hágalo bien!</p>
                      </div>
                      <ul className='px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 border-b sm:border-r lg:border-b-0 pb-6 pt-6 lg:pt-3'>
                        <div className='flex items-center'>
                          <svg className='h-8 mb-3 mr-3 fill-current text-white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                            <path d='M3 6c0-1.1.9-2 2-2h8l4-4h2v16h-2l-4-4H5a2 2 0 0 1-2-2H1V6h2zm8 9v5H8l-1.67-5H5v-2h8v2h-2z' />
                          </svg>
                          <h3 className='font-bold text-xl text-white text-bold mb-2'>Heading 1</h3>
                        </div>

                        <p className='text-gray-100 text-sm'>Las ventas trimestrales están en su punto más bajo para crear espacios para explorar la charla responsable y los vampiros ciegos.</p>
                        <div className='flex items-center py-3'>
                          <svg className='h-6 pr-3 fill-current text-blue-300' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                            <path d='M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z' />
                          </svg>
                          <a href='#()' className='text-white bold border-b-2 border-blue-300 hover:text-blue-300'>
                            Saber más...
                          </a>
                        </div>
                      </ul>
                      <ul className='px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 border-b sm:border-r-0 lg:border-r lg:border-b-0 pb-6 pt-6 lg:pt-3'>
                        <div className='flex items-center'>
                          <svg className='h-8 mb-3 mr-3 fill-current text-white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                            <path d='M4.13 12H4a2 2 0 1 0 1.8 1.11L7.86 10a2.03 2.03 0 0 0 .65-.07l1.55 1.55a2 2 0 1 0 3.72-.37L15.87 8H16a2 2 0 1 0-1.8-1.11L12.14 10a2.03 2.03 0 0 0-.65.07L9.93 8.52a2 2 0 1 0-3.72.37L4.13 12zM0 4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4z' />
                          </svg>
                          <h3 className='font-bold text-xl text-white text-bold mb-2'>Heading 2</h3>
                        </div>
                        <p className='text-gray-100 text-sm'>Priorice estas líneas de pedido, el plan de juego, dibuje una línea en la arena, elabore algo de venta ascendente de UX digno de mención.</p>
                        <div className='flex items-center py-3'>
                          <svg className='h-6 pr-3 fill-current text-blue-300' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                            <path d='M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z' />
                          </svg>
                          <a href='#()' className='text-white bold border-b-2 border-blue-300 hover:text-blue-300'>
                            Saber más...
                          </a>
                        </div>
                      </ul>
                      <ul className='px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 border-b sm:border-b-0 sm:border-r md:border-b-0 pb-6 pt-6 lg:pt-3'>
                        <div className='flex items-center'>
                          <svg className='h-8 mb-3 mr-3 fill-current text-white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                            <path d='M2 4v14h14v-6l2-2v10H0V2h10L8 4H2zm10.3-.3l4 4L8 16H4v-4l8.3-8.3zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z' />
                          </svg>
                          <h3 className='font-bold text-xl text-white text-bold mb-2'>Heading 3</h3>
                        </div>
                        <p className='text-gray-100 text-sm'>Esta propuesta es una situación en la que todos ganan que provocará un cambio de paradigma estelar, toquemos la base fuera de línea antes de lanzar la nueva experiencia de UX.</p>
                        <div className='flex items-center py-3'>
                          <svg className='h-6 pr-3 fill-current text-blue-300' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                            <path d='M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z' />
                          </svg>
                          <a href='#()' className='text-white bold border-b-2 border-blue-300 hover:text-blue-300'>
                            Saber más...
                          </a>
                        </div>
                      </ul>
                      <ul className='px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 pb-6 pt-6 lg:pt-3'>
                        <div className='flex items-center'>
                          <svg className='h-8 mb-3 mr-3 fill-current text-white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                            <path d='M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z' />
                          </svg>
                          <h3 className='font-bold text-xl text-white text-bold mb-2'>Heading 4</h3>
                        </div>
                        <p className='text-gray-100 text-sm'>Esta es una obviedad para lavarse la cara, o necesitamos preparar para el futuro esta granularidad de palabras clave de alto rendimiento.</p>
                        <div className='flex items-center py-3'>
                          <svg className='h-6 pr-3 fill-current text-blue-300' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                            <path d='M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z' />
                          </svg>
                          <a href='#()' className='text-white bold border-b-2 border-blue-300 hover:text-blue-300'>
                            Saber más...
                          </a>
                        </div>
                      </ul>
                    </div>
                  </div>
                </li>
            */}
              </ul>
            </div>
          </nav>
          )}
        </header>
    </>
  )
}

export default Header
