import React, { useEffect, useState } from "react"
import { FaAngleDown, FaAngleUp, FaPaperclip } from "react-icons/fa"
import moment from 'moment'
import { OrderListByUser } from "api/order";
import Spinner from "components/utilities/spinner";
import { ModalImage } from "components/Modal";

const UserPedidos = () => {
    const [orders,setOrder] = useState([])
    const [loaded, setLoaded] = useState(true);
    const [detailActive, setDetail] = useState(null);
    const token = localStorage.getItem('token');
    const status = [
        {
            value: 'REJECTED',
            name: 'Rechazado',
            className: 'rechazado'
        },
         {
            value: 'APPROVED',
            name: 'Aceptado',
            className: 'aceptado'
        },
        {
            value: 'PENDING',
            name: 'En proceso',
            className: 'enproceso'
        },
    ]

    const StatusOrder = (id) => {
        const order = orders.find(order => order._id === id);
        const statuSelected = status.find(data => data.value === order.status)
        return(
            <ul className="status">
                <li className={statuSelected.className}>
                    <span> {statuSelected.name} </span>

                    <ul>
                        {
                            status.map((data) => (
                                <li>
                                    <button className={`btn ${data.className} ${data.value === statuSelected.value? 'active': ''}`}>
                                        {data.name}
                                    </button>
                                </li>
                            ))
                        }    
                    </ul>
                </li>
            </ul>
        )
    }
    const onReferenceView = (id, url) => {
        ModalImage(`Referencia de orden de compra ${id}`, url);
    }

    useEffect(() => {
        const InitialData = async() => {
            setLoaded(true)
            const result = await OrderListByUser(token);
            if(result.orders){
                setOrder(result.orders)
            }
            setLoaded(false)
        }

        InitialData();
    },[token])
  return (
    <div className='bg-white p-4 sm:p-8'>
        {
            loaded?
            <Spinner />
            :
            orders.map((order,key) => (
                <div className='items_lists' key={key}>
                    <div className={`item ${detailActive === key? 'active': ''}`}>
                        <div className="who">
                            <div className="cols picture">
                                <img src={order.page.cover?order.page.cover:"/images/affiliate-3.jpg"} alt="" />
                            </div>
                            <div className="cols dets">
                                <h3>{order.page.business_name}</h3>
                                <button className="lets-chat">Chatear</button>
                                <address>
                                    {order.page.business_address}
                                </address>
                            </div>
                        </div>
        
                        <div className="cbtns">
                            <div className="timer">
                            {moment(order.createdAt).format('DD/MM/YYYY - hh:mm a')}
                            </div>

                            <button className="notes" aria-label="notas" onClick={()=>onReferenceView(order._id, order.reference)}>
                                <span>notas</span> <FaPaperclip className='inline-block'/>
                            </button>
        
                            <button className="view-details" onClick={() => {setDetail(detailActive === key?null: key)}}>
                                Ver detalle {key === detailActive? <FaAngleUp className='inline-block mr-2'/> : <FaAngleDown className='inline-block mr-2'/>}
                            </button>
        
                            <div className="more-opts">
                                <div className="price">
                                    <small>Total</small>
                                    <strong><span>S/.</span> {Number.parseFloat(order.total).toFixed(2)}</strong>
                                </div>
                                {
                                    StatusOrder(order._id)
                                }
                            </div>
                        </div>
        
                        <div className="more-details">
                            <div className="full-info">
                                <div className="left">
                                    <strong>{order.client.name}</strong>
                                    <address>
                                        {order.client.address}
                                    </address>
                                </div>
                                <div className="right">
                                    <button className="btn phone">
                                        <i className="fas fa-phone"></i>
                                        {order.client.phone}
                                    </button>
                                </div>
                            </div>
                            <div className="list-items">
                               {
                                   order.orders.map((product, item) => (
                                    <div className="list-item" key={item}>
                                        <img
                                            src={product.product.featured_images[0]?product.product.featured_images[0]:"https://picsum.photos/id/10/50/50"}
                                            alt=""
                                            className="pic"
                                        />
                                        <h4>{product.product.name} - ({product.qt})</h4>
                                        <div className="prices">
                                            <small>S/.</small>
                                            <strong>{Number.parseFloat(product.total).toFixed(2)}</strong>
                                        </div>
                                    </div>
                                   ))
                               }
                            </div>
                        </div>
                    </div>   
                </div>
            ))
        }
    </div>
  )
}

export default UserPedidos
