import React from 'react'

import MetaTags from 'react-meta-tags'

const QuienesSomos = () => {
  return (
    <>
      <MetaTags>
        <title>Quienes Somos</title>
        <meta name='description' content='anecom es la primera Red de Clientes en el mundo donde el Cliente y los Negocios Aliados son los más beneficiados con los planes de ganancia por tus compras y las compra de todos sus referidos.' />
      </MetaTags>

      <section class='text-gray-700 body-font border-t border-gray-200'>
        <div class='container px-5 py-24 mx-auto'>
          <div class='flex flex-wrap w-full mb-20 flex-col items-center text-center'>
            <h1 class='sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900'>¿Quiénes somos?</h1>
            <br />
            <small>Última actualización: 13 de Diciembre de 2021</small>
            <br />
            <p class='lg:w-1/2 w-full leading-relaxed text-base'>Ganecom es la primera Red de Clientes en el mundo donde el Cliente y los Negocios Aliados son los más beneficiados con los planes de ganancia por tus compras y las compra de todos sus referidos.</p>
            <br />

            <i>
              Ganecom <strong>"gane comprando, gane compartiendo"</strong>
            </i>
          </div>
        </div>
      </section>
    </>
  )
}

export default QuienesSomos
