import React, { useEffect, useState } from 'react';
import { UserList } from 'api/user';
import Spinner from 'components/utilities/spinner';
import { ModalWithHtml } from 'components/Modal';

const AdminAffiliate = () => {
    const [users, setUsers] = useState([])
    const [loaded, setLoaded] = useState(true)

    useEffect(() => {
        const InitialData = async() => {
            setLoaded(true)
            const result = await UserList();
            if(result.users){
                setUsers(result.users);
            }
            setLoaded(false)
        }
        InitialData()
    },[])

    const ViewAffiliate = async(id) => {
      const user = await users.find(user => user._id === id);
      const html = (
        <div>
            <div>Afiliados de {user.name}</div>
            <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
              <tbody className="bg-white divide-y divide-gray-200">
                {
                  user.affiliates.length > 0?
                  user.affiliates.map((affiliate, key) =>(
                    <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={key}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-10 h-10">
                            <img className="w-10 h-10" src={affiliate.user.profile?affiliate.user.profile:"https://avatars0.githubusercontent.com/u/57622665?s=460&amp;u=8f581f4c4acd4c18c33a87b3e6476112325e8b38&amp;v=4"} alt="" />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{affiliate.user.name}</div>
                            <div className="text-sm text-gray-500">{affiliate.user.email}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                        <button className="flex items-center space-x-3" onClick={() => {ViewAffiliateLvl2(affiliate.user._id, user.name)}}>
                          <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded" href="#">Afiliados</span>
                        </button>
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td>No tiene afiliados</td>
                  </tr>
                }
              </tbody>
            </table>
        </div>
      )
      await ModalWithHtml(html)
    }

    const ViewAffiliateLvl2 = async(id, name) => {
      const user = await users.find(user => user._id === id);
      const html = (
        <div>
            <div>Segundo nivel de afiliados de {name}</div>
            <div>Afiliados de {user.name}</div>
            <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
              <tbody className="bg-white divide-y divide-gray-200">
                {
                  user.affiliates.length > 0?
                  user.affiliates.map((affiliate, key) =>(
                    <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={key}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-10 h-10">
                            <img className="w-10 h-10" src={affiliate.user.profile?affiliate.user.profile:"https://avatars0.githubusercontent.com/u/57622665?s=460&amp;u=8f581f4c4acd4c18c33a87b3e6476112325e8b38&amp;v=4"} alt="" />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{affiliate.user.name}</div>
                            <div className="text-sm text-gray-500">{affiliate.user.email}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                        <button className="flex items-center space-x-3" onClick={() => {ViewAffiliateLvl2(affiliate.user._id, user.name)}}>
                          <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded" href="#">Afiliados</span>
                        </button>
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td>No tiene afiliados</td>
                  </tr>
                }
              </tbody>
            </table>
        </div>
      )
      await ModalWithHtml(html)
    }

    return(
        <div className="bg-white">
        <div className="p-4 sm:p-8">
            {
                loaded?
                <Spinner />
                :
                <div>
                    <div>Afiliados</div>
                    {
                        <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200">
                        {
                             users.map((user, id) => (
                           <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={id}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 w-10 h-10">
                                  <img className="w-10 h-10" src={user.profile?user.profile:"https://avatars0.githubusercontent.com/u/57622665?s=460&amp;u=8f581f4c4acd4c18c33a87b3e6476112325e8b38&amp;v=4"} alt="" />
                                </div>
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">{user.name}</div>
                                  <div className="text-sm text-gray-500">{user.email}</div>
                                </div>
                              </div>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                            {/* <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"> Verificado </span> */}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <button className="flex items-center space-x-3" onClick={() => {ViewAffiliate(user._id)}}>
                                <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded" href="#">Afiliados</span>
                              </button>
                            </td>
                          </tr>
                            ))
                          }

                        </tbody>
                      </table>
                        
                       /*  users.map((user, id) => (
                            
                            <div key={id}>
                                User:
                                <br />
                                name: {user.name} - email: {user.email}
                                {
                                    user.affiliates.length > 0 &&
                                    <div>
                                    Afiliados Nivel 1:
                                    {
                                        user.affiliates.map((affiliate, key) => (
                                            <div key={key}>
                                                name: {affiliate.user.name} - email: {affiliate.user.email}
                                               {
                                                   affiliate.user.affiliates.length > 0 &&
                                                   <div>
                                                   Afiliados Nivel 2:
                                                       {
                                                           affiliate.user.affiliates.map((affiliatet, key) => (
                                                               <div key={key}>
                                                                   name: {affiliatet.user.name} - email: {affiliatet.user.email}
                                                                   <div>
                                                                   </div>
                                                               </div>
                                                           ))
                                                       }
                                                   </div>
                                               }
                                            </div>
                                        ))
                                    }
                                </div>
                                }
                                <hr />
                            </div>
                        )) */
                    }
                </div>
            }
        </div>
      </div>
    )
}

export default AdminAffiliate;