import React from 'react';

const FeaturedBussines = () => {
    return(
        <div className='partners bg-white'>
            <div className='container mx-auto py-8 px-4 text-center'>
                <h2 className='sm:text-3xl text-2xl font-medium title-font text-gray-600'>Negocios Destacados</h2>
                <div className='flex mt-4 justify-center mb-6'>
                    <span className='w-16 h-1 rounded-full bg-gray-500 inline-flex'></span>
                </div>

                <div className='logos flex gap-12 items-center justify-center'>
                    <div className='item text-6xl text-blue-600'>
                        <i className='lni lni-tshirt'></i>
                    </div>
                    <div className='item text-6xl text-blue-600'>
                        <i className='lni lni-tshirt'></i>
                    </div>
                    <div className='item text-6xl text-blue-600'>
                        <i className='lni lni-tshirt'></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeaturedBussines;