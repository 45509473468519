import React from "react"

import MetaTags from "react-meta-tags"

import SidebarCart from "../components/SidebarCart"

const Test = () => {
  return (
    <>
      <MetaTags>
        <title>Test</title>
        <meta name='description' content='Descripcion Test' />
      </MetaTags>

      <SidebarCart />

      <div>
        <h1 className='text-center py-80 text-5xl'>Test 2</h1>
      </div>
    </>
  )
}

export default Test
