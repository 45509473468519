import React, { useState} from "react"
// eslint-disable-next-line
import { Link, useHistory, useParams  } from "react-router-dom"
import MetaTags from 'components/MetaTags'
import ImageAuth from 'components/Auth/Image';
import Toast from 'components/Toast';
import { UserChangePass } from "api/user";

const RecoveryPass = () => {
  const [user, setUser] = useState({password: '', confirm_password: ''});
  const {token} = useParams();

  const history = useHistory();


  const onChangeData = ({target: {value, name}}) => {
    setUser({
      ...user,
      [name]: value
    })
  }

  const onAlert = async (icon, msg) => {
    Toast(icon, msg);
  }

  const onSubmitData = async(e) => {
    try {
      e.preventDefault();
      if(user.password === '' || user.confirm_password === ''){
        console.log('data not complete');
        await onAlert('warning', 'Complete los datos por favor');
        return;
      }
      if(user.password !== user.confirm_password){
        console.log('password not match');
        await onAlert('warning', 'La Contraseña no coinciden');
        return;
      }
      const result = await UserChangePass(user, token);
      console.log(result)
      if(result.userId){
        await onAlert('success', 'Cambio de contraseña exitoso');
        setTimeout(() => {
          history.push('/')
        }, [4800])
      } else {
        await onAlert('error', result.message)
      }
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <>
      <MetaTags titlePage='Cambiar tu Contraseña' content='Cambiar Contraseña' />

      <div className='py-12 container mx-auto px-4'>
        <div className='flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto '>
          <ImageAuth />
          <div className='w-full p-8 lg:w-1/2'>
            <div className='mt-4 flex items-center justify-between'>
              <span className='border-b w-1/5 lg:w-1/4'></span>
              <h1 className='font-bold text-2xl text-center text-gray-500 '>Cambiar contraseña</h1>
              <span className='border-b w-1/5 lg:w-1/4'></span>
            </div>
            <div className='mt-4'>
                <label className='block text-gray-700 text-sm font-bold mb-2'>Contraseña</label>
              <input aria-label='Contraseña' className='bg-gray-100 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none' type='password' name='password' value={user.password} onChange={onChangeData} />
            </div>
        
            <div className='mt-4'>
                <label className='block text-gray-700 text-sm font-bold mb-2'>Confirmar Contraseña</label>
              <input aria-label='Contraseña' className='bg-gray-100 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none' type='password'name='confirm_password' value={user.confirm_password} onChange={onChangeData} />
            </div>

            <div className='mt-8'>
              <button onClick={onSubmitData} className='bg-red-500 text-white font-bold py-2 px-4 w-full rounded hover:bg-red-600 border-b-4 border-red-700'>Cambiar contraseña</button>
            </div>
            <div className='mt-4 flex items-center justify-between'>
                <span className='border-b w-1/5 md:w-1/4'></span>
                <Link to='/login' className='text-xs text-gray-500 uppercase font-bold'>
                  Iniciar sesion
                </Link>
                <span className='border-b w-1/5 md:w-1/4'></span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RecoveryPass;
