import React, { createContext, useEffect, useState } from "react";

export const AuthContext = createContext({
    isLoggedIn: false,
    login: () => {},
    logout: () => {}
});

export const AuthProvider = ({children}) => {
    const token = localStorage.getItem("token")? true: false
    const [isLoggedIn, setLoggedIn] = useState(token);

    useEffect(() => {
        const checkAuth = async() => {
            const token = await localStorage.getItem("token");
            console.log(token)
            if(token){
                setLoggedIn(true);
            }
          }
          checkAuth();
    })

    const onLogin = () => {console.log('user login');setLoggedIn(true)};
    const onLogout = () => setLoggedIn(false);

    return (
        <AuthContext.Provider value={{ isLoggedIn, onLogin, onLogout }}>
            {children}
        </AuthContext.Provider>
    )
}