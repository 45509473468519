import { UserGet, UserUpdate } from 'api/user';
import React, { useEffect, useState } from 'react';
import Spinner from 'components/utilities/spinner';
import Toast from 'components/Toast';

const UpdateProfile = ({onFinish}) => {
    const [loaded, setLoaded] = useState(true);
    const [user, setUser] = useState({})

    useEffect(() => {
        const InitialData = async() => {
            setLoaded(true)
            const token = await localStorage.getItem('token')
            const result = await UserGet(token);
            if(result){
                setUser(result);
            }
            setLoaded(false)
        }
        InitialData()
    },[])
    const onAlert = async (icon, msg) => {
        Toast(icon, msg);
      }


    const onchangeData = ({target: {name, value}}) => setUser({...user, [name]: value})
    const onChangeFile = ({target: {files}}) => setUser({...user, profile: files[0]});
    const onSubmitData = async(e) => {
        e.preventDefault();
        if(user.name === "" || user.mobile === ""){
            await onAlert('warning', 'Complete los datos requeridos por favor');
            return;
        }
        const token = await localStorage.getItem('token')
        const form = new FormData();
        for (const key in user) {
           form.append(key, user[key]);
        }
        const result = await UserUpdate(form, token);
        if(result.userId){
            await onAlert('success', 'Actualizcion de perfil exitoso');
            setTimeout(() => {
                onFinish()
            }, [4800])
          } else {
            await onAlert('error', result.message)
          }
    }
    return (
        <div className='featured-item featured-item-inner bg-gray-100'>
            <div className='container mx-auto px-4'>
                <div className='sm:grid sm:grid-cols-4 sm:gap-6'>
                    <div className='left-area bg-white sm:col-span-3'>
                        <div className='grid grid-flow-col gap-4'>
                        {
                            loaded?
                            <Spinner />
                            :
                            <form className='p-10'>
                                <h1 className='sm:text-3xl text-2xl font-medium title-font mb-6'>ACtualiza tu perfil</h1>
                                <div className='flex flex-wrap -mx-3 mb-6'>
                                    <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='nombre-p'> Nombre  </label>
                                        <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='nombre-p' type='text' placeholder='Jane' name='name' value={user.name} onChange={onchangeData} />
                                        <p className='text-red-500 text-xs italic'>Requerido.</p>
                                    </div>
                                    <div className='w-full md:w-1/2 px-3'>
                                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='precio'> Apellido </label>
                                        <input className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' id='precio' type='text' placeholder='Doe' name='last_name' value={user.last_name} onChange={onchangeData}/>
                                    </div>
                                </div>

                                <div className='flex flex-wrap -mx-3 mb-6'>
                                    <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='text'> Celular </label>
                                        <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='text' type='text' placeholder='' name='mobile' value={user.mobile} onChange={onchangeData}/>
                                        <p className='text-red-500 text-xs italic'>Requerido.</p>
                                    </div>
                                    <div className='w-full md:w-1/2 px-3'>
                                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='phone'> Teléfono </label>
                                        <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='phone' type='text' placeholder='' name='phone' value={user.phone} onChange={onchangeData}/>
                                    </div>
                                </div>

                                <div className='flex flex-wrap -mx-3 mb-6'>
                                    <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='direccion'> Dirección </label>
                                        <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='direccion' type='text' placeholder='' name='address' value={user.address} onChange={onchangeData}/>
                                    </div>
                                </div>
                        

                                <div className='flex flex-wrap -mx-3 mb-6'>
                                    <div className='w-full px-3'>
                                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for=''> Perfil </label>
                                        <input aria-label='imagen' className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' id='grid-email' type='file' placeholder='' onChange={onChangeFile} name='profile'/>
                                    </div>
                                </div>
                                <div className='flex justify-between w-full px-3'>
                                    <button onClick={onSubmitData} className='bg-red-500 text-white font-bold py-2 px-4 w-full rounded hover:bg-red-600 border-b-4 border-red-700'>Actualizar Ahora</button>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
}

export default UpdateProfile