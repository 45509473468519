import React, { useEffect, useState } from 'react';
import Spinner from 'components/utilities/spinner';
import { PageList, PageVerify } from 'api/page';
import { ModalCheck, ModalWithHtml } from 'components/Modal';
import Toast from 'components/Toast';
import ProductCommission from './updateCommission';

const AdminPage = () => {
    const [pages, setPages] = useState([])
    const [loaded, setLoaded] = useState(true)
    const [commssion, setCommission] = useState({action:false, id: null})
  
    const InitialData = async() => {
      setLoaded(true)
      const result = await PageList();
      if(result.pages){
          setPages(result.pages);
      }
      setLoaded(false)
  }
    useEffect(() => {
        const InitialData = async() => {
            setLoaded(true)
            const result = await PageList();
            if(result.pages){
                setPages(result.pages);
            }
            setLoaded(false)
        }
        InitialData()
    },[])


    const viewDetail = async(id) => {
        const page = await pages.find(page => page._id === id);
        const html = (
            <div>
            <div>Productos de {page.business_name}</div>
            <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
              <tbody className="bg-white divide-y divide-gray-200">
                {
                  page.products.length > 0?
                  page.products.map((product, key) =>(
                    <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={key}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-10 h-10">
                            <img className="w-10 h-10" src={product.featured_images[0]?product.featured_images[0]:"https://avatars0.githubusercontent.com/u/57622665?s=460&amp;u=8f581f4c4acd4c18c33a87b3e6476112325e8b38&amp;v=4"} alt="" />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{product.name}</div>
                            <div className="text-sm text-gray-500">S/ {product.price}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                       <button className="flex items-center space-x-3" onClick={()=>{setCommission({action: true, id: product._id})}}>
                          <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded">Comision</span>
                        </button>
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td>No tiene Productos</td>
                  </tr>
                }
              </tbody>
            </table>
        </div>
        )
        await ModalWithHtml(html);
    }

    const onAlert = async (icon, msg) => {
      Toast(icon, msg);
    }

    const onSubmitVerify = async(value) => {
      try {
        const result = await PageVerify(value);
        if(result.pageId){
          await onAlert('success', 'Verificacion actualizada con exito');
          setTimeout(() => {
            InitialData()
          }, [4800])
        } else {
          await onAlert('error', result.message)
        }
      } catch (error) {
        console.log(error)
      }
    }


    const verifyPage = async(id) => {
      
      const page = await pages.find(page => page._id === id);
      await ModalCheck(`Verificar pagina ${page.business_name}`, page.verify?1: 0,'Verificar pagina', 'Verificar Ahora', (value) =>{onSubmitVerify({pageId:id, verify: value===1?true: false})});
    }

    return(
        <div className="bg-white">
            <div className="p-4 sm:p-8">
                {
                    loaded?
                    <Spinner />
                    :
                    <div>
                      {
                        commssion.action?
                        <ProductCommission productid={commssion.id} onFinish={() =>{setCommission({action:false, id: null})}} />
                        :
                        <>
                          <div>Negocios</div>
                          <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                              <tbody className="bg-white divide-y divide-gray-200">
                              {
                                  pages.map((page, id) => (
                                      <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={id}>
                                          <td className="px-6 py-4 whitespace-nowrap">
                                              <div className="flex items-center">
                                                  <div className="flex-shrink-0 w-10 h-10">
                                                  <img className="w-10 h-10" src={page.cover?page.cover:"https://avatars0.githubusercontent.com/u/57622665?s=460&amp;u=8f581f4c4acd4c18c33a87b3e6476112325e8b38&amp;v=4"} alt="" />
                                                  </div>
                                                  <div className="ml-4">
                                                  <div className="text-sm font-medium text-gray-900">{page.business_name}</div>
                                                  <div className="text-sm text-gray-500">{page.business_address}</div>
                                                  </div>
                                              </div>
                                          </td>

                                          <td className="px-6 py-4 whitespace-nowrap">
                                              <span className={ `inline-flex px-2 text-xs font-semibold leading-5 ${page.verify?'text-green-800 bg-green-100': 'text-red-800 bg-red-100'} rounded-full`}> {page.verify? 'Verificado': 'No Verificado'} </span>
                                          </td>
                                          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                            <button className="flex items-center space-x-3" onClick={()=>{verifyPage(page._id)}}>
                                              <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded">Verificar negocio</span>
                                            </button>
                                            <button className="flex items-center space-x-3" onClick={()=>{viewDetail(page._id)}}>
                                                  <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded">Detalle</span>
                                            </button>
                                          </td>
                                      </tr>
                                  ))
                              }
                              </tbody>
                          </table>
                        </>
                      }
                        
                    </div>
                }
            </div>
        </div>
    )
}

export default AdminPage;