import React, { useState } from 'react';
import AddAffiliate from './addAffiliate';

const UserAffiliate = ({affiliates, code, onDataInitial}) => {
    const [actionAdd, setAction] = useState(false)

    return(
    <div className="bg-white">
        <div className="p-4 sm:p-8">
            <div className="actions bg-white flex items-center justify-between p-4">
                <div className="flex items-center space-x-2">
                    <h3 className="font-bold text-gray-500">Buscar</h3>
                    <input aria-label="Buscar" className="bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2" placeholder="Buscar Afiliado" type="search" />
                </div>
                <div className="right">
                    <button className="bg-green-600 text-white hover:bg-red-600 rounded py-2 px-6 md:px-12" onClick={()=>{setAction(true)}}>Añadir Afiliado</button>
                </div>
            </div>
        {
            actionAdd?
            <AddAffiliate code={code} onFinish={()=>{setAction(false); onDataInitial()}} />
            :
            <div>
                 <div>Afiliados</div>
                <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                    <tbody className="bg-white divide-y divide-gray-200">
                    {
                        affiliates.map((affiliate, id) => (
                            <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 w-10 h-10">
                                            <img className="w-10 h-10" src={affiliate.user.profile?affiliate.user.profile:"https://avatars0.githubusercontent.com/u/57622665?s=460&amp;u=8f581f4c4acd4c18c33a87b3e6476112325e8b38&amp;v=4"} alt="" />
                                        </div>
                                        <div className="ml-4">
                                            <div className="text-sm font-medium text-gray-900">{affiliate.user.name}</div>
                                            <div className="text-sm text-gray-500">{affiliate.user.email}</div>
                                        </div>
                                    </div>
                                </td>

                                <td className="px-6 py-4 whitespace-nowrap">
                                    {/* <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"> Verificado </span> */}
                                </td>
                                {/* <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                    <button className="flex items-center space-x-3" onClick={() => {ViewAffiliate(user._id)}}>
                                        <span className="bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1 rounded" href="#">Afiliados</span>
                                    </button>
                                </td> */}
                            </tr>
                        ))
                      }

                    </tbody>
                </table>
            </div>
        }
        </div>
    </div>
    )
}

export default UserAffiliate