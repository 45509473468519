import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './Main.css'
import Main from './Main'
import { AuthProvider } from './context/authContext'
import { ShoppingCartProvider } from 'context/shoppingCartContext'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ShoppingCartProvider>
        <Main />
      </ShoppingCartProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
