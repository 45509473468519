import React, {useState} from "react"
import MetaTags from 'components/MetaTags'
import Categories from 'components/Home/Categories'
import FeaturedBussines from 'components/Home/FeaturedBussines'
import FeaturedProduct from 'components/Home/FeaturedProducts'
import RecentProduct from 'components/Home/RecentProducts'
import ProductOfferRandom from "./ProductOfferRandom"

const Home = () => {
  const [category, SetCategory] = useState("");
  const [subCategory, SetSubCategory] = useState("");

  const onChangeCategory = (value) => {
    SetCategory(value); 
    if(category !== value){
      SetSubCategory("");
    }
  }
  const onChangeSubCategory = (value) => SetSubCategory(value)


  return (
    <>
      <MetaTags titlePage='GaneCom' content='Bienvenido a GaneCom' />
      <ProductOfferRandom />
      <div className='info-bar1 bg-gray-200'>
        <div className='container mx-auto py-8 px-4 flex justify-between flex-col sm:flex-row sm:gap-5'>
          <div className='item flex items-center leading-4'>
            <div className='icon text-3xl mr-3'>
              <i className='lni lni-restaurant text-red-500'></i>
            </div>
            <span> Tiendas oficiales </span>
          </div>

          <div className='item flex items-center leading-4'>
            <div className='icon text-3xl mr-3'>
              <i className='lni lni-car text-red-500'></i>
            </div>
            <span> Llegamos a todo el país </span>
          </div>

          <div className='item flex items-center leading-4'>
            <div className='icon text-3xl mr-3'>
              <i className='lni lni-credit-cards text-red-500'></i>
            </div>
            <span>Paga con cualquier forma de pago </span>
          </div>

          <div className='item flex items-center leading-4'>
            <div className='icon text-3xl mr-3'>
              <i className='lni lni-phone text-red-500'></i>
            </div>
            <span>
              {" "}
              Fonocompras <strong>(01) 500-7899</strong>{" "}
            </span>
          </div>
        </div>
      </div>
      {/* featured bussines components */}
      <FeaturedBussines />

      {/* featured products components */}
      <FeaturedProduct />

      <div className='products bg-gray-100' id="productArea">
        <div className='container mx-auto py-8 px-4 sm:grid sm:grid-cols-4 sm:gap-6'>

          {/* categories components */}
          <Categories onChangeCategory={onChangeCategory} onChangeSubCategory={onChangeSubCategory} />
          {/* recent products components */}
          <RecentProduct category={category} subCategory={subCategory} />
        </div>
      </div>
    </>
  )
}

export default Home
