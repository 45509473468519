import { PageGet } from "api/page"
import { ShoppingCartContext } from "context/shoppingCartContext"
import React, { Fragment, useContext, useEffect, useState } from "react"

import MetaTags from "./MetaTags"
import Spinner from "./utilities/spinner"
import { Link, useHistory } from "react-router-dom"
import { UserGet } from "api/user"
import Toast from "./Toast"
import { OrderRegister } from "api/order"
import { AuthContext } from "context/authContext"

const Checkout = () => {
  const {onLogout} = useContext(AuthContext)
  const [page, setPage] = useState({})
  const [loaded, setLoaded] = useState(true)
  const { products, total, onDeleteProduct, onChangeQtProduct, onDeleteAllCart } = useContext(ShoppingCartContext)
  const [payment, setPayment] = useState({})
  const [dataPay, setDataPay] = useState("")
  const [pagoefectivo, setPagoefectivo] = useState(false)
  const [reference, setReference] = useState(null)
  const [delivery, setDelivery] = useState({ name: "", phone: "", address: "" })
  const token = localStorage.getItem("token")
  const history = useHistory()


  const onChangeData = ({ target: { value, name } }) => {
    setPayment({ ...payment, [name]: value })
    setPagoefectivo(false)
  }
  const onChangeDataMetodoPago = () => {
    setPayment({ ...payment, payment_method: "0"})
    setPagoefectivo(true)
  }
  const onChangeFile = ({ target: { files } }) => setReference(files[0])

  const onChangeDeli = ({ target: { value, name } }) => setDelivery({ ...delivery, [name]: value })

  useEffect(() => {
    const initialPage = async () => {
      setLoaded(true)
      if (products.length > 0) {
        const result = await PageGet(products[0].pageId)
        if (result) {
         
          setPage(result)
          setPayment({ payment_method: "0", delivery_method: "shopping" })
          setDataPay(result.payments[0].data)
         
        }

        const resultUs = await UserGet(token)
        if (!resultUs.message) {
          setDelivery({ name: resultUs.name, phone: resultUs.phone, address: resultUs.address })
        } else {
          await localStorage.removeItem('token');
          await localStorage.removeItem('user')
          onLogout()
        }
      }
      setLoaded(false)
    }
    initialPage()
  }, [token])

  useEffect(() => {
    if (!loaded && page.payments) {

      if(pagoefectivo){
        setDataPay("")
      }else{
        const findPayment = page.payments.find((pay) => pay._id === payment.payment_method)
        setDataPay(findPayment.data)
      }
    }
  }, [payment.payment_method])


  const onAlert = (icon, msg) => {
    Toast(icon, msg)
  }
  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)
    if (!new RegExp('[0-9]').test(keyValue)) event.preventDefault()
    return
  }
  const onSubmitData = async (e) => {
    e.preventDefault()
    try {
      if (!token) {
        history.push("/login", { page: "checkout" })
        return
      }
      if (payment.delivery_method === "delivery" && (delivery.name === "" || delivery.phone === "" || delivery.address === "")) {
        onAlert("warning", "Complete los datos para el Delivery")
        return
      }
      if(payment.payment_method !== "0"){
        if(!reference){
          onAlert('warning', 'Debes adjuntar el Baucher de Pago');
          return
        }
      }
      
      const data = {
        page: products[0].pageId,
        total: total.price,
        items: total.qt,
        products,
        delivery_method: payment.delivery_method,
        payment_method: payment.payment_method,
        reference,
        delivery: payment.delivery_method === "delivery" ? JSON.stringify(delivery) : null,
        sale_type: "ONLINE",
        status: "PENDING",
      }
      const form = new FormData()
      for (const key in data) {
        if (key === "products") {
          for (const item of data[key]) {
            form.append(key, JSON.stringify(item))
          }
        } else {
          form.append(key, data[key])
        }
      }
      const result = await OrderRegister(form, token)
      if (result.orderId) {
        await onAlert("success", "Compra realizada con exito")
        setTimeout(() => {
          onDeleteAllCart()
          history.push("/")
        }, [4800])
      } else {
        await onAlert("error", result.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <MetaTags titlePage='Carrito de compras' content='Listado de compras' />

      {loaded ? (
        <Spinner />
      ) : (
        <div className='bg-gray-100 py-10'>
          <div className='container mx-auto'>
            <div className='lg:flex shadow-md '>
              <div className='lg:w-3/4 bg-white px-10 py-10'>
                <div className='flex justify-between border-b pb-8'>
                  <h1 className='font-semibold text-2xl'>Carrito de Compra</h1>
                  <h2 className='font-semibold text-2xl'>{products.length} Items</h2>
                </div>
                <div className='flex mt-5 mb-5 '>
                  <h3 className='font-semibold text-gray-600 text-xs uppercase w-2/5'>Detalles del producto</h3>
                  <h3 className='font-semibold text-center text-gray-600 text-xs uppercase w-1/5 '>Cantidad</h3>
                  <h3 className='font-semibold text-center text-gray-600 text-xs uppercase w-1/5 '>Precio</h3>
                  <h3 className='font-semibold text-center text-gray-600 text-xs uppercase w-1/5 '>Total</h3>
                </div>

                {products.length === 0 ? (
                  <div className='flex content-center mt-11'>
                    <div>No hay productos en tu carrito</div>
                  </div>
                ) : (
                  products.map((product, key) => (
                    <div className='flex items-center hover:bg-gray-100 -mx-8 px-6 py-5 border-t-2 border-gray-200 '>
                      <div className='flex w-2/5'>
                        <div className='w-20'>
                          <img className='h-24' src={product.image ? product.image : "https://drive.google.com/uc?id=18KkAVkGFvaGNqPy2DIvTqmUH_nk39o3z"} alt='' />
                        </div>
                        <div className='flex flex-col justify-between ml-4 flex-grow'>
                          <span className='font-bold text-sm'>{product.name}</span>
                          <span className='text-red-500 text-xs'>{page.business_name}</span>
                          <button onClick={() => onDeleteProduct(key)} className='w-5 h-5 text-center bg-red-500 hover:bg-red-600 p-0 text-sm text-white'>
                            x
                          </button>
                        </div>
                      </div>
                      <div className='flex justify-center w-1/5'>
                        <button
                          className='p-2 hover:bg-gray-300'
                          onClick={() => {
                            onChangeQtProduct("-", key)
                          }}
                        >
                          <svg className='fill-current text-gray-600 w-3' viewBox='0 0 448 512'>
                            <path d='M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z' />
                          </svg>
                        </button>
                        <input className='mx-2 border text-center w-8' type='text' value={product.qt} />

                        <button
                          className='p-2 hover:bg-gray-300'
                          onClick={() => {
                            onChangeQtProduct("+", key)
                          }}
                        >
                          <svg className='fill-current text-gray-600 w-3' viewBox='0 0 448 512'>
                            <path d='M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z' />
                          </svg>
                        </button>
                      </div>
                      <span className='text-center w-1/5 font-semibold text-sm'>S/ {product.priceUnit}</span>
                      <span className='text-center w-1/5 font-semibold text-sm'>S/ {product.price}</span>
                    </div>
                  ))
                )}

                <Link to='/' className='flex font-semibold text-indigo-600 text-sm mt-10 '>
                  <svg className='fill-current mr-2 text-indigo-600 w-4' viewBox='0 0 448 512'>
                    <path d='M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z' />
                  </svg>
                  Continúa comprando
                </Link>
              </div>

              <div id='summary' className='lg:w-1/2 px-8 py-10 bg-blue-100'>
                <h2 className='font-semibold text-2xl border-b lg:pb-8'>Resumen del orden</h2>
                {page._id && products.length > 0 && (
                  <>
                    <div className='flex justify-between mt-5 mb-5 pb-5 border-b-2 border-gray-500 border-opacity-25'>
                      <span className='font-semibold text-sm uppercase'>Productos <strong> {products.length}</strong></span>
                      <span className='font-semibold text-sm'>S/ {Number.parseFloat(total.price).toFixed(2)}</span>
                    </div>

                    <h3 className='font-bold mb-3'>Método de pago</h3>

                    <div className='fields font-semibold text-sm mb-4 pb-4 space-y-3 border-b-2 border-gray-500 border-opacity-25'>
                    <input type='radio' name='payment_method' value="0" className='transform scale-150 mr-1' checked={payment.payment_method ==="0"} onClick={onChangeDataMetodoPago} id="pce" />
                          <label htmlFor='pce'> Pago contra Entrega</label><br />
                      {page.payments.map((pay, key) => (
                        <Fragment key={key}>
                          <input type='radio' name='payment_method' id={pay._id} value={pay._id} className='transform scale-150 mr-1' checked={payment.payment_method === pay._id} onChange={onChangeData} />
                          <label htmlFor={pay._id}> {pay.name_method}</label>
                          {key < page.payments.length - 1 && <br />}
                        </Fragment>
                      ))}
                      <div>
                        Datos a pagar: <br />
                        {dataPay}
                      </div>
                    </div>

                    <h3 className='font-bold mb-3'>Método de entrega</h3>

                    <div className='fields font-semibold text-sm mb-4  space-x-4  flex'>
                      <div className='field'>
                        <input type='radio' id='shopping' name='delivery_method' value='shopping' className='transform scale-150 mr-1' checked={payment.delivery_method === "shopping"} onChange={onChangeData} />
                        <label htmlFor='shopping'>en la Tienda</label>
                      </div>

                      <div className='field'>
                        <input type='radio' id='delivery' name='delivery_method' value='delivery' className='transform scale-150 mr-1' checked={payment.delivery_method === "delivery"} onChange={onChangeData} />
                        <label htmlFor='delivery'>Delivery</label>
                      </div>
                    </div>

                    {payment.delivery_method === "delivery" && (
                      <>
                        <div className='fields py-1 flex gap-4 border-b-2 border-gray-500 border-opacity-25 mb-4 pb-4'>
                          <div className='field'>
                            <label htmlFor='name' className='font-semibold inline-block mb-2 text-sm '>
                              Nombre del que recibe
                            </label>
                            <input type='text' name='name' value={delivery.name} onChange={onChangeDeli} placeholder='Name' className='p-2 text-sm w-full' />
                          </div>
                          <div className='field'>
                            <label htmlFor='phone' className='font-semibold inline-block mb-2 text-sm '>
                              Celular
                            </label>
                            <input type='text' name='phone' value={delivery.phone} onChange={onChangeDeli} placeholder='Cellphone' className='p-2 text-sm w-full' />
                          </div>
                        </div>
                        <div className='fields py-1 flex gap-4 border-b-2 border-gray-500 border-opacity-25 mb-4 pb-4'>
                          <div className='field'>
                            <label htmlFor='address' className='font-semibold inline-block mb-2 text-sm '>
                              Dirección
                            </label>
                            <input type='text' name='address' value={delivery.address} onChange={onChangeDeli} placeholder='Cellphone' className='p-2 text-sm w-full' />
                          </div>
                        </div>
                      </>
                    )}
                     {payment.payment_method !== "0" && (
                     <div className='comprobante'>
                     <label htmlFor='myfile' className='font-semibold inline-block mb-2 text-sm '>
                       Enviar comprobante de pago
                     </label>
                     <input type='file' name='myfile' className='p-2 text-sm w-full bg-white' onChange={onChangeFile} />
                   </div>)}
                    <div className='border-t mt-2'>
                      <div className='flex font-semibold justify-between py-4 text-sm uppercase'>
                        <span>Costo Total </span>
                        <span>S/ {Number.parseFloat(total.price).toFixed(2)}</span>
                      </div>

                      <button onClick={onSubmitData} className='bg-blue-500 border-blue-600 font-semibold text-white hover:bg-blue-600 py-2 px-3 border-b-4 hover:border-blue-500 rounded transition duration-300 ease-in-out w-full'>
                        Pagar
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Checkout
