import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ProductList } from 'api/product'
import Spinner from 'components/utilities/spinner'

const RecetProduct = ({ category, subCategory }) => {
  const [products, setProduct] = useState([])
  const [loaded, setLoaded] = useState(true)

  const [isActive, setIsActive] = useState(false)

  const handleClick = () => {
    setIsActive(!isActive)
  }

  useEffect(() => {
    const InitialData = async () => {
      setLoaded(true)
      const result = await ProductList(false, 50, category, subCategory)
      if (result.products) {
        console.log('product=> ', result.products)
        setProduct(
          result.products.map((product) => ({
            featured_image: product.featured_images[0] ? product.featured_images[0] : 'https://picsum.photos/id/10/50/50',
            ...product,
            price: Number.parseFloat(product.price).toFixed(2).split('.'),
            total: Number.parseFloat(product.descount ? product.price_descount : product.price)
              .toFixed(2)
              .split('.'),
            mount_commission: Number.parseFloat((product.commission_product * 40) / 100).toFixed(2),
          }))
        )
      }
      setLoaded(false)
    }
    InitialData()
  }, [category, subCategory])

  return (
    <div className='products-area col-span-3'>
      <div className='p-3 filter-bar bg-gray-200 flex items-center justify-between'>
        <h3 className='lg:text-xl font-bold'>Productos Recientes</h3>

        <div className='filter-links flex items-center space-x-1'>
          <span className='mr-2'>Vista →</span>
          <button onClick={handleClick} className={isActive ? 'bg-red-500 text-white px-2 py-1 rounded' : 'px-2 py-1 rounded hover:bg-white'}>
            <i className='lni lni-list'></i>
          </button>

          <button onClick={handleClick} className={isActive ? 'px-2 py-1 rounded hover:bg-white' : 'px-2 py-1 rounded bg-red-500 text-white'}>
            <i className='lni lni-grid'></i>
          </button>
        </div>
      </div>

      <div className={isActive ? 'items list-view sm:grid sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 mb-4 ' : 'items sm:grid sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 mb-4'}>
        {loaded ? (
          <Spinner />
        ) : (
          products.map((product, key) => (
            <div className='item w-full py-4 relative' key={key}>
              {product.descount && (
                <span className='in-offer flex items-center justify-center font-bold w-14 h-14 text-xl text-white border-dashed border-2 bg-blue-500 rounded-full absolute -left-3 top-1 z-10'>
                  -{product.mount_descount}
                  {product.type_descount}
                </span>
              )}
              <div className='bg-white shadow-xl rounded-lg overflow-hidden'>
                <Link to={{ pathname: `/detalle/${product.name.replace(/ /g, '-')}`, state: { productId: product._id } }} className='thumb block overflow-hidden'>
                  <img className='pimage w-full relative transform transition duration-300 ease-in-out' src={product.featured_image} alt='thum' />
                  <span className='hidden'>View</span>
                </Link>

                <p className='p-4 border-l-4 border-green-500 tracking-wide text-sm text-gray-700'>{product.name}</p>
                <div className='price p-4 flex items-center justify-between'>
                  <div className='left leading-none'>
                    {product.descount && (
                      <s className='text-2x1 text-gray-400 whitespace-nowrap'>
                        <small>S/</small> {product.price[0]}
                        <small>.{product.price[1]}</small>
                      </s>
                    )}
                    {product.commission && (
                      <strong className='block text-sm xl:text-lg whitespace-nowrap'>
                        Gane <span className='text-yellow-500'>S/ {product.mount_commission}</span>
                      </strong>
                    )}
                  </div>

                  <p className='text-3xl text-blue-500 whitespace-nowrap font-bold'>
                    <small>S/</small>
                    {product.total[0]}
                    <small>.{product.total[1]}</small>
                  </p>
                </div>

                <div className='p-3 border-t border-gray-300 bg-gray-100'>
                  <div className='flex items-center justify-between'>
                    {
                      <div className='time flex items-center'>
                        <img className='mr-2' width='40' height='40' src={product.page.cover ? product.page.cover : '/images/company.png'} alt='' />
                        <div className='text-sm truncate block pr-3'>
                          <span>{product.page.business_name}</span>
                        </div>
                      </div>
                    }
                    <Link to={{ pathname: `/detalle/${product.name.replace(/ /g, '-')}`, state: { productId: product._id } }} className='ml-auto bg-blue-500 border-blue-600 text-white hover:bg-blue-600 py-2 px-3 border-b-4 hover:border-blue-500 rounded transition duration-300 ease-in-out text-xs flex'>
                      Ver <span className='md:hidden xl:inline-flex ml-2'> Detalle</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default RecetProduct
