import React, { useEffect, useState } from "react"
import MetaTags from "components/MetaTags"
import { useParams, Link, useHistory } from "react-router-dom"
import { ProductList } from "api/product"
import Spinner from "components/utilities/spinner"
import { CategoryList } from "api/category"

const Search = () => {
  const [search, setSearch] = useState("")
  const [products, setProduct] = useState([])
  const [openSubCategories, setOpen] = useState(null)
  const [categories, setCategories] = useState([])
  const [loaded, setLoaded] = useState(true)
  const { textKey, category, subCategory } = useParams()
  const history = useHistory()

  useEffect(() => {
    const InitialData = async () => {
      setLoaded(true)
      const name = textKey ? textKey.replace(/-/g, " ") : ""
      const { departamentId } = history.location.state
      setSearch(name)
      const result = await ProductList("", 200, "", "", name, departamentId)
      if (result.products) {
        setProduct(
          result.products.map((product) => ({
            featured_image: product.featured_images[0] ? product.featured_images[0] : "https://picsum.photos/id/10/50/50",
            ...product,
            price: Number.parseFloat(product.price).toFixed(2).split("."),
            total: Number.parseFloat(product.descount ? product.price_descount : product.price)
              .toFixed(2)
              .split("."),
            mount_commission: Number.parseFloat((product.commission_product * 40) / 100).toFixed(2),
          }))
        )
      }
      setLoaded(false)
    }
    if (textKey) {
      InitialData()
    }
  }, [textKey, history.location.state])

  useEffect(() => {
    const InitialData = async () => {
      setLoaded(true)
      let categoryId = "",
        subCategoryId = ""
      if (category && subCategory) {
        categoryId = history.location.state.categoryId
        subCategoryId = history.location.state.subCategoryId
        setSearch(`Categoria: ${category}, SubCategoria: ${subCategory}`)
      }
      const result = await ProductList("", 200, categoryId, subCategoryId, "")
      if (result.products) {
        setProduct(
          result.products.map((product) => ({
            featured_image: product.featured_images[0] ? product.featured_images[0] : "https://picsum.photos/id/10/50/50",
            ...product,
            price: Number.parseFloat(product.price).toFixed(2).split("."),
            total: Number.parseFloat(product.descount ? product.price_descount : product.price)
              .toFixed(2)
              .split("."),
            mount_commission: Number.parseFloat((product.commission_product * 40) / 100).toFixed(2),
          }))
        )
      }
      const resultCat = await await CategoryList()
      if (resultCat.categories) {
        const data = resultCat.categories.map((category) => ({
          id: category._id,
          name: category.name,
          subCategories: category.subCategories.map((subCategory) => ({
            id: subCategory._id,
            name: subCategory.name,
          })),
        }))
        setCategories(data)
        const findCategory = resultCat.categories.findIndex((category) => category._id === categoryId)
        setOpen(findCategory)
      }
      setLoaded(false)
    }
    if (category && subCategory) {
      InitialData()
    }
  }, [category, subCategory, history.location.state])

  return (
    <>
      <MetaTags titlePage='GaneCom' content='Bienvenido a GaneCom' />

      {loaded ? (
        <Spinner />
      ) : (
        <div className='products bg-gray-100' id='productArea'>
          <div className='container mx-auto py-8 px-4 sm:grid sm:grid-cols-4 sm:gap-6'>
            <div className='aside mb-8'>
              <ul className='leading-none mb-5'>
                <h3 className='bg-gray-200 p-3 py-4 lg:text-xl mb-3 font-bold'>Filtros</h3>
                {category &&
                  subCategory &&
                  categories.map((category, key) => (
                    <li className='bg-gray-200 p-3 mb-2' key={key}>
                      <div
                        className='flex items-center cursor-pointer'
                        onClick={() => {
                          setOpen(openSubCategories !== key ? key : null)
                        }}
                      >
                        <i className='lni lni-gift mr-3'></i> <span> {category.name} </span> <i className={`lni ml-auto ${category.subCategories.length > 0 && openSubCategories === key ? "lni-chevron-left" : "lni-chevron-right"}`}></i>
                      </div>
                      {category.subCategories.length > 0 && (
                        <ul className={`pl-5 pt-3 ${category.subCategories.length > 0 && openSubCategories === key ? "block" : "hidden"}`}>
                          {category.subCategories.map((subCategory, index) => (
                            <li className='mb-1' key={index}>
                              <Link to={{ pathname: `/search/${category.name.replace(/ /g, "-")}/${subCategory.name.replace(/ /g, "-")}`, state: { categoryId: category.id, subCategoryId: subCategory.id } }} className={`block border-gray-400 px-3 py-2 border-l-2 transition duration-300 ease-in-out transform  ${history.location.state.subCategoryId === subCategory.id ? "text-red-500 translate-x-1" : "hover:text-red-500 hover:translate-x-1"}`}>
                                {subCategory.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
              </ul>
              <div className='sample-box bg-gray-400 h-48 flex text-white items-center justify-center'>box</div>
            </div>

            {/* product search area init */}
            <div className='products-area col-span-3'>
              <div className='p-3 filter-bar bg-gray-200 flex items-center justify-between'>
                <h3 className='lg:text-xl font-bold'>
                  Resultados para <strong>{search}</strong>
                </h3>

                <div className='filter-links flex items-center space-x-1'>
                  <span className='mr-2'>Vista →</span>
                  <button className='px-2 py-1 rounded hover:bg-white'>
                    <i className='lni lni-list'></i>
                  </button>
                  <button className='px-2 py-1 rounded bg-red-500 text-white'>
                    <i className='lni lni-grid'></i>
                  </button>
                </div>
              </div>

              <div className='items sm:grid sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 mb-4'>
                {products.map((product, key) => (
                  <div className='item w-full py-4 relative' key={key}>
                    {product.descount && (
                      <span className='in-offer flex items-center justify-center font-bold w-14 h-14 text-xl text-white border-dashed border-2 bg-blue-500 rounded-full absolute -left-3 top-1 z-10'>
                        -{product.mount_descount}
                        {product.type_descount}
                      </span>
                    )}

                    {product.offer && (
                      <div className='ribbon ribbon-top-right'>
                        <span className='bg-blue-500'>Orferta</span>
                      </div>
                    )}
                    <div className='bg-white shadow-xl rounded-lg overflow-hidden'>
                      <Link to={{ pathname: `/detalle/${product.name.replace(/ /g, "-")}`, state: { productId: product._id } }} className='thumb block overflow-hidden'>
                        <img className='pimage w-full relative transform transition duration-300 ease-in-out' src={product.featured_image} alt='thum' />
                        <span className='hidden'>View</span>
                      </Link>

                      <p className='p-4 border-l-4 border-green-500 tracking-wide text-sm text-gray-700'>{product.name}</p>
                      <div className='price p-4 flex items-center justify-between'>
                        <div className='left leading-none'>
                          {product.descount && (
                            <s className='text-2x1 text-gray-400 whitespace-nowrap'>
                              <small>S/</small> {product.price[0]}
                              <small>.{product.price[1]}</small>
                            </s>
                          )}

                          {product.commission && (
                            <strong className='block text-sm xl:text-lg whitespace-nowrap'>
                              Gane <span className='text-yellow-500'>S/ {product.mount_commission}</span>
                            </strong>
                          )}
                        </div>

                        <p className='text-3xl text-blue-500 whitespace-nowrap font-bold'>
                          <small>S/</small>
                          {product.total[0]}
                          <small>.{product.total[1]}</small>
                        </p>
                      </div>

                      <div className='p-3 border-t border-gray-300 bg-gray-100'>
                        <div className='flex items-center justify-between'>
                          <div className='time flex items-center'>
                            <img className='mr-2' width='40' height='40' src='/images/company.png' alt='' />
                            <div className='text-sm'>
                              <span>{product.page.business_name}</span>
                            </div>
                          </div>
                          <Link to={{ pathname: `/detalle/${product.name.replace(/ /g, "-")}`, state: { productId: product._id } }} className='ml-auto bg-blue-500 border-blue-600 text-white hover:bg-blue-600 py-2 px-3 border-b-4 hover:border-blue-500 rounded transition duration-300 ease-in-out'>
                            Ver <span className='md:hidden xl:inline-flex'> Detalle</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* product search are end */}
          </div>
        </div>
      )}
    </>
  )
}

export default Search
