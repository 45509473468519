import { ProductDelete, ProductListByPage } from 'api/product';
import React, { useEffect, useState } from 'react';
import ProductAdd from './ProductAdd';
import Spinner from 'components/utilities/spinner';
import { ModalConfirm } from 'components/Modal';

const ProductsPage = ({ page }) => {
    const [products, setProduct] = useState([]);
    const [productAdd, setProductadd] = useState({isActive:false, action: ''});
    const [loaded, setLoaded] = useState(true)
    const [name, setName] = useState("")

    const InitialData = async () => {
        setLoaded(true)
        console.log(page)
        const result = await ProductListByPage(page, name, "","", true);
        if (result.products) {
            setProduct(result.products);
        }
        setLoaded(false)
    }

    useEffect(() => {
        const InitialData = async () => {
            setLoaded(true)
            console.log(page)
            const result = await ProductListByPage(page, name);
            if (result.products) {
                setProduct(result.products);
            }
            setLoaded(false)
        }
        InitialData()
    },[page, name])

    const onChangeData  = ({target: {value}}) => setName(value);

    const onDeleteProduct = async(id) => {
        const result = await ProductDelete({productId: id, page})
        if(result.deleted){
            await InitialData();
            return true
        } else {
            return false
        }
    }

    const onDeleteConfirm = (id) => ModalConfirm(()=>{onDeleteProduct(id)}, '¿Esta seguro de eliminar este producto?')

    return (
        <>
        {
            productAdd.isActive?
            <ProductAdd pageID={page} data={productAdd} onFinish={()=>{setProductadd({isActive:false, action: ''});InitialData()}}   />
            :
            <>
            
                <div class="actions bg-white flex items-center justify-between p-4">
                    <div class="flex items-center space-x-2">
                        <h3 class="font-bold text-gray-500">Buscar</h3>
                        <input aria-label="Buscar" onChange={onChangeData} value={name} class="bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2" placeholder="Buscar Producto" type="search" />
                    </div>
                    <div class="right">
                        <button class="bg-green-600 text-white hover:bg-red-600 rounded py-2 px-6 md:px-12" onClick={() => { setProductadd({isActive:true, action: 'create'}) }}>Crear</button>
                    </div>
                </div>
               {
                   loaded?
                   <Spinner />
                   :
                   <div class="overflow-x-auto">
                   <div class="inline-block min-w-full pb-2 align-middle">
                       <div class="border-b border-gray-200 rounded-md shadow-md">
                           <table class="min-w-full overflow-x-scroll divide-y divide-gray-200">
                               <thead class="bg-gray-50">
                                   <tr>
                                       <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Nombre</th>
                                       <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">En</th>
                                       <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Estado</th>
                                       <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Opciones</th>
                                   </tr>
                               </thead>
                               <tbody class="bg-white divide-y divide-gray-200">
                                   {
                                       products.map((product, key) => (
                                           <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={key}>
                                               <td className="px-6 py-4 whitespace-nowrap">
                                                   <div className="flex items-center">
                                                       <div className="flex-shrink-0 w-10 h-10">
                                                           <img alt="prodyct" className="w-10 h-10" src={product.featured_images[0]?product.featured_images[0]:"/images/affiliate-3.jpg"} />

                                                       </div>
                                                       <div className="ml-4">
                                                           <div className="text-sm font-medium text-gray-900"> {product.name} - S/{product.price} </div>
                                                           <div className="text-sm text-gray-500">Breve descripcion</div>
                                                       </div>
                                                   </div>
                                               </td>
                                               <td className="px-6 py-4 whitespace-nowrap">
                                                   <div className="text-sm text-gray-900">{product.offer ? "En oferta" : ""}</div>
                                               </td>
                                               <td className="px-6 py-4 whitespace-nowrap">
                                                   <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"> {product.active? "Activo": "Inactivo"} </span>
                                               </td>
                                               <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                   <div className="flex items-center space-x-3">
                                                       <span className="cursor-pointer bg-gray-400 text-white inline-flex items-center justify-center px-3 py-1" onClick={() =>{setProductadd({isActive:true, action: 'update', productid: product._id})}}>Editar</span>
                                                       <pan className="cursor-pointer bg-red-600 text-white inline-flex items-center justify-center px-3 py-1" onClick={()=>{onDeleteConfirm(product._id)}}>x</pan>
                                                   </div>
                                               </td>
                                           </tr>
                                       ))
                                   }
                               </tbody>
                           </table>
                       </div>
                   </div>
               </div>
               }
            </>
        }
        </>
    )
}

export default ProductsPage
