import React, { useState } from 'react';
import Toast from 'components/Toast';
import { UserChangePass } from 'api/user';

const UpdateChangePass = ({onFinish}) => {
    const [user, setUser] = useState({password: '', confirm_password: ''})

    const onAlert = async (icon, msg) => {
        Toast(icon, msg);
      }


    const onchangeData = ({target: {name, value}}) => setUser({...user, [name]: value})
    const onSubmitData = async(e) => {
        e.preventDefault();
        if(user.password === "" || user.confirm_password === ""){
            await onAlert('warning', 'Complete los datos requeridos por favor');
            return;
        }
        if(user.password !== user.confirm_password){
            console.log('password not match');
            await onAlert('warning', 'La Contraseña no coinciden');
            return;
        }
        const token = await localStorage.getItem('token')
        const result = await UserChangePass(user, token);
        if(result.userId){
            await onAlert('success', 'Actualizcion de contraseña exitoso');
            setTimeout(() => {
              onFinish()
            }, [4800])
          } else {
            await onAlert('error', result.message)
          }
    }
    return (
        <div className='featured-item featured-item-inner bg-gray-100'>
            <div className='container mx-auto px-4'>
                <div className='sm:grid sm:grid-cols-4 sm:gap-6'>
                    <div className='left-area bg-white sm:col-span-3'>
                        <div className='grid grid-flow-col gap-4'>
                            <form className='p-10'>
                                <h1 className='sm:text-3xl text-2xl font-medium title-font mb-6'>Actualiza tu contraseña</h1>
                                <div className='flex flex-wrap -mx-3 mb-6'>
                                    <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='nombre-p'> Contraseña  </label>
                                        <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' type='password' name='password' value={user.password} onChange={onchangeData} />
                                        <p className='text-red-500 text-xs italic'>Requerido.</p>
                                    </div>
                                    <div className='w-full md:w-1/2 px-3'>
                                        <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' for='precio'> Confirmar Contraseña </label>
                                        <input className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' type='password' name='confirm_password' value={user.confirm_password} onChange={onchangeData}/>
                                        <p className='text-red-500 text-xs italic'>Requerido.</p>
                                    </div>
                                </div>
                                <div className='flex justify-between w-full px-3'>
                                    <button onClick={onSubmitData} className='bg-red-500 text-white font-bold py-2 px-4 w-full rounded hover:bg-red-600 border-b-4 border-red-700'>Actualizar Ahora</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateChangePass