import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}api/orders`,
    headers: {
        'content-type': 'application/json',
    }
})

export const OrderRegister = async(data, token) => {
    const result = await api.post('/register', data ,{headers: {
        'x-access-token': token
    }})
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const OrderRegisterSale = async (data) => {
    const result = await api.post('/registerSale', data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const OrderList = async() => {
    const result = await api.get(`/list`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const OrderTotalByPage = async (pageId) => {
    const result = await api.get(`/total/${pageId}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const OrderListByPage = async(pageId) => {
    const result = await api.get(`/list/page/${pageId}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const OrderListSaleByPage = async (pageId) => {
    const result = await api.get(`/list/sales/${pageId}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const OrderListByUser = async(token) => {
    const result = await api.get('/list/user',{headers: {
        'x-access-token': token
    }})
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const OrderUpdateStatus = async (data) => {
    const result = await api.put(`/status/${data.orderId}`, data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

/* 

export const DepartamentGet = async(departament) => {
    const result = await api.get(`/${departament}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const DepartamentUpdate = async(data) => {
    const result = await api.put(`/${data.departamentId}`, data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}
 */

