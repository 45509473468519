import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}api/products`,
    headers: {
        'content-type': 'application/json',
    }
})

export const ProductRegister = async(data) => {
    const result = await api.post('/register', data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const ProductList = async(offer="",limit=50,category="", subCategory="",name="",departamentId="") => {
    const result = await api.get(`/list?offer=${offer}&limit=${limit}&category=${category}&subCategory=${subCategory}&name=${name}&departamentId=${departamentId}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const ProductListByPage = async(pageId,name="", active="",productId="",panel="false") => {
    const result = await api.get(`/list/${pageId}?name=${name}&active=${active}&productId=${productId}&panel=${panel}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const ProductListBySimilars = async(pageId,name="") => {
    const result = await api.get(`/similars?name=${name}&page=${pageId}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const ProductGet = async(id) => {
    const result = await api.get(`/${id}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const ProductGetOffer = async() => {
    const result = await api.get('/offers')
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const ProductUpdate = async(data, productId) => {
    const result = await api.put(`/${productId}`, data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const ProductUpdateCommission = async(data, productId) => {
    const result = await api.put(`/commission/${productId}`, data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const ProductDelete = async(data) => {
    const result = await api.delete(`/${data.productId}`, data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}
