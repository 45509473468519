import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}api/users`,
    headers: {
        'content-type': 'application/json',
    }
})

export const UserRegister = async(data) => {
    const result = await api.post('/register', data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const UserRecoveryPassword = async(data) => {
    const result = await api.post('/recovery-password', data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const UserRegisterAffiliate = async(data) => {
    const result = await api.post('/register-affiliate', data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const UserUpdate = async(data, token) => {
    const result = await api.put('/', data, {headers: {
        'x-access-token': token
    }})
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const UserChangePass = async(data, token) => {
    const result = await api.post('/change-password', data, {headers: {
        'x-access-token': token
    }})
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const SignIn = async(data) => {
    const result = await api.post('/login', data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const UserConfirm = async (token) => {
    const result = await api.get('/email-confirm', {
            headers: {
                'x-access-token': token
            }
        })
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const UserGet = async(token) => {
    const result = await api.get('/', {headers: {
        'x-access-token': token
    }})
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}

export const UserList = async (client="") => {
    const result = await api.get(`/list?client=${client}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.error(`'The error in the call route is:', ${error.message}`);
            return error.response.data;
        })
    return result;
}
