import React from 'react';

const ImageAuth = () => {

    return(
        <div className='hidden lg:block lg:w-1/2 bg-cover'>
            <img src='https://images.unsplash.com/photo-1546514714-df0ccc50d7bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80' alt='' />
        </div>
    )
}

export default ImageAuth;