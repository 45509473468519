import React from 'react'

import MetaTags from 'react-meta-tags'

const Tos = () => {
  return (
    <>
      <MetaTags>
        <title>Términos y Condiciones </title>
        <meta name='description' content='' />
      </MetaTags>

      <section class='text-gray-700 body-font border-t border-gray-200'>
        <div class='container px-5 py-24 mx-auto'>
          <div class='flex flex-wrap w-full mb-20 flex-col  '>
            <h1 class='sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 text-center'>Términos y Condiciones </h1>
            <br />
            <small>Última actualización: 13 de Diciembre de 2021</small>
            <br />
            <p class='leading-relaxed text-base mb-4'>AVISOS VIP INTERNACIONAL S.A.C. con RUC 20532882860 en adelante “Ganecom”, es el titular de este sitio web y de las presentes condiciones generales acceso y uso del Portal, en adelante.</p>
            <h3 className='font-bold text-xl'>El acceso, Registro y uso de este sitio web es voluntario para todo usuario.</h3>
            <p className='mb-4'>El portal está sujeto a las normas y condiciones que se especifican a continuación. El hecho de registrarse en el mismo implica que aceptas expresamente estas condiciones.</p>
            <p className='mb-4'>Por lo tanto se recomienda que sean leídas detenidamente antes de llevar a cabo algún tipo de acción, disponiendo a su disposición del usuario, en caso de tener alguna duda, la siguiente dirección de correo HYPERLINK "mailto:atencion.cliente@ganecom.com" atencion.cliente@ganecom.com</p>
            <h3 className='font-bold text-xl'>Veracidad y confidencialidad de los datos recopilados.</h3>
            <p className='mb-4'>Los usuarios expresan que la información proporcionada en el sitio web es verdadera.</p>
            <p className='mb-4'>Los datos recopilados serán tratados como información confidencial, conforme a las políticas de privacidad y confidencialidad de Ganecom.</p>
            <h3 className='font-bold text-xl'>Definiciones</h3>
            <p className='mb-4'>Sitio web: página alojada bajo el dominio HYPERLINK "http://www.ganecom.com" www.ganecom.com </p>
            <p className='mb-4'>Usuario: toda persona física que acceda y haga uso directo o indirecto del Sitio web, de acuerdo a las instrucciones previstas en las presentes condiciones de uso y vinculado, por las mismas.</p>
            <p className='mb-4'>Negocio: Organizaciones públicas o privadas, naturales o jurídicas, que ofertan sus productos y/o servicios a través del Sitio Web.</p>
            <p className='mb-4'>Oferta: Ofertas y promociones de productos realizadas por los Negocios aliados a Ganecom, que son divulgadas a través del sitio web, las que se encuentran bajo la responsabilidad de los mismas Negocios, exceptuando a ganecom de toda responsabilidad.</p>
            <p className='mb-4'>Ganancias: Cantidad de dinero que se acumula en tu estado Ganancias, como consecuencia del porcentaje obtenido por los consumos de productos y/o servicios (personalmente o por tus referidos) de acuerdo a las presentes condiciones generales. El cual alcanzado un monto mínimo produce una transferencia o giro a pedido de cada Usuario.</p>
            <p className='mb-4'>Compra confirmada: Compra realizada en los Negocios y que hayan sido validadas por estas en el sitio web consultando tus Ganancias.</p>
            <h3 className='font-bold text-xl'>Menores de edad</h3>
            <p className='mb-4'>Podrán ser usuarios de la Plataforma de Ganecom, y por ende participar en el proceso de registro y referir a sus conocidos a unirse a su Red, aquellos menores de edad que tengan igual o más de 14 años.</p>
            <p className='mb-4'>La identificación y validación de los menores de edad, al igual que el común de los usuarios, se hará por medio del formulario de registro para lo cual deberán registrar un apoderado (Usuario mayor de edad registrado) validando la aceptación con carácter de declaración jurada bajo responsabilidad de ambos usuarios.</p>
            <p className='mb-4'>Asimismo, recibirán los beneficios propios como cualquier usuario, las ganancias generadas por los consumos de su red de referidos.</p>
            <p className='mb-4'>Los cobros en caso soliciten los Usuarios menores de edad, se otorgara única y exclusivamente a su apoderado hasta que cumplan la mayoría de edad, en ese momento automáticamente quedan facultados como todos los Usuarios. </p>
            <p className='mb-4'>El Usuario que se registre en el sitio web a través de su cuenta personal, consiente expresamente a Ganecom que:</p>
            <p className='mb-4'>a) Tenga acceso, en cualquier momento, a la información básica contenida en su cuenta personal, (Información Personal, información sobre intereses, gustos, contactos y otros).</p>
            <p className='mb-4'>b) Incluya además en el muro de dicha cuenta, tales como: mensajes, fotografías, videos y cualquier otro tipo de contenido; y envíe al correo electrónico y/o teléfono celular del Usuario.</p>
            <p className='mb-4'>Ganecom recoge y almacena automáticamente cierta información sobre la actividad de los Usuarios y visitantes dentro de la plataforma. Entre la información recogida y almacenada se encuentran:</p>
            <p className='mb-4'>a) URL de la que provienen.</p>
            <p className='mb-4'>b) URL a la que acceden.</p>
            <p className='mb-4'>c) Navegador usado.</p>
            <p className='mb-4'>d) Dirección IP.</p>
            <p className='mb-4'>e) Perfiles visitados dentro de la plataforma.</p>
            <p className='mb-4'>f) Búsquedas realizadas en los motores de búsqueda de la plataforma.</p>
            <p className='mb-4'>g) Publicaciones.</p>
            <p className='mb-4'>h) Compras realizadas.</p>
            <p className='mb-4'>i) Mensajes enviados.</p>
            <p className='mb-4'>j) Otros de relevancia.</p>
            <p className='mb-4'>Si los usuarios nos envían correspondencia sean correos electrónicos o cartas, o si otros usuarios o terceros nos envían correspondencia sobre las actividades o sobre los mensajes de otros usuarios en nuestra plataforma, podemos recoger y almacenar tal Información Personal, incluyendo direcciones de e-mail.</p>
            <h3 className='font-bold text-xl'>Objeto del servicio</h3>
            <p className='mb-4'>El objeto del servicio es la promoción del ahorro a través de la acumulación de descuentos por la compra de productos y/o servicios ofertados a través del Sitio web por los Negocios, de acuerdo a las presentes condiciones de uso y darle la oportunidad de obtener ingresos extras por todas sus compras y las compras que sus referidos realicen.</p>
            <h3 className='font-bold text-xl'>Registro</h3>
            <p className='mb-4'>El Usuario se compromete a hacer un uso adecuado de los contenidos y servicios que se ofrecen en el presente Sitio web. Para poder usar los servicios del Sitio es necesario que el usuario se registre, llevando a cabo los siguientes pasos:</p>
            <p className='mb-4'>Completar el formulario de registro que se encuentra accesible desde la página principal del Sitio.</p>
            <p className='mb-4'>Leer y aceptar las presentes condiciones de uso antes de proceder al envío de sus datos personales para el alta en el servicio.</p>
            <p className='mb-4'>
              Leer y aceptar la{' '}
              <a className='underline' href='/politica-de-privacidad'>
                política de privacidad
              </a>{' '}
               del sitio, antes de proceder al envío de sus datos personales para el alta en el servicio.
            </p>
            <p className='mb-4'>Ingresar todos los datos requeridos, comprometiéndose a que los mismos sean actuales, veraces y lícitos.</p>
            <p className='mb-4'>Confirmar el registro, a través del correo electrónico que recibirá, para verificar el alta en el sitio, de acuerdo a los datos que haya ingresado en el formulario de registro.</p>
            <p className='mb-4'>Queda prohibido el registro en el Sitio web a menores de edad. Si personas menores de edad hacen uso de este sitio web con el consentimiento de sus padres o Apoderados estos últimos serán considerados responsables de los actos que lleven a cabo los menores a su cargo.</p>
            <p className='mb-4'>Tanto el acceso al Sitio como el uso o suministro no consentido de la información contenida en el mismo es responsabilidad exclusiva del usuario que lo realiza. Ganecom no es responsable de ningún daño o consecuencia que pueda derivarse de dicho uso, acceso o comunicación.</p>
            <p className='mb-4'>Los Usuarios, autorizan expresamente a difundir sus nombres, datos personales e imágenes, por los medios y en las formas que se consideren convenientes, con fines publicitarios y/o promocionales, sin derecho a compensación alguna, cediendo el uso de su imagen y de la propiedad intelectual, de ser el caso.</p>
            <h3 className='font-bold text-xl'>Beneficios del servicio</h3>
            <p className='mb-4'>Para poder acceder a los beneficios del Sitio se debe ingresar con su correo y su contraseña en su plataforma la cual es personal e intransferible. El Usuario será el único responsable de mantener en secreto la misma y de las consecuencias de su pérdida, quedando eximida Ganecom de cualquier responsabilidad que pudiera derivarse del uso indebido de su cuenta por terceros no autorizados.</p>
            <p className='mb-4'>Ganecom, a través de la plataforma del Usuario, pone a disposición de este, un apartado denominado “modificación de datos” para la actualización de sus datos de carácter personal. Es responsabilidad del Usuario la actualización de sus datos como titular.</p>
            <h3 className='font-bold text-xl'>Uso del servicio</h3>
            <p className='mb-4'>Ganecom pone a disposición del Usuario, a través del sitio web, ofertas y promociones de los Negocios aliados a Ganecom, a las cuales el usuario deberá acceder para su compra, a fin de que las compras queden debidamente registradas.</p>
            <p className='mb-4'>En el caso de que el usuario no realice sus compras correctamente, los ingresos de un porcentaje correspondientes a dichas compras no se verán reflejados en sus ganancias. Algunas promociones u ofertas sólo generan ingresos por la primera compra en un Negocio aliado a Ganecom. En estos casos se informará al usuario en el propio enlace de la oferta suministrada por el Negocio aliado a Ganecom y será bajo la responsabilidad de esta la publicación. Asimismo hay otras promociones u ofertas que solo generan descuento directo del importe de la compra, pero que no generan Ganancias.</p>
            <p className='mb-4'>Las compras realizadas por el usuario aparecerán reflejadas en su plataforma en tiempo real. Para las compras que finalmente no aparezcan reflejadas en su plataforma, los usuarios disponen de un formulario de reclamo, entrando en su plataforma, a través del cual pueden tramitar sus quejas.</p>
            <p className='mb-4'>Una vez acumulada una cantidad de dinero mínima, el usuario podrá solicitar la transferencia de su saldo acumulado ya sea en efectivo o en alguna cuenta quedando Ganecom exceptuado de todo cobro extra por transferencias de cada entidad (bancos, financieras, cajas, etc.) las cuales sean indicadas en sus datos personales, o también pueden donar el dinero a cualquier organización, en cualquiera de estos casos el tiempo de trasferencia será máximo 48 horas</p>
            <p className='mb-4'>De no registrarse una compra confirmada por el Negocio aliado a Ganecom, el usuario afectado deberá notificar empleando el enlace facilitado en el sitio web. Ganecom se pondrá en contacto con el Usuario con la finalidad de resolver el incidente en el menor tiempo posible.</p>
            <p className='mb-4'>Ganecom selecciona Negocios seguros y de confianza. No obstante,  Ganecom no se hace responsable de los posibles incumplimientos o incidencias que incurra cualquier Negocio aliado a Ganecom con los Usuarios, al tratarse de una relación comercial entre dicho Negocio aliado a Ganecom y el Usuario. Cada Negocio aliado a Ganecom dispone de sus propias condiciones de venta, por lo que recomendamos encarecidamente la lectura atenta de ellas antes de proceder a realizar cualquier operación. En caso de detectar cualquier posible mala práctica o abuso por parte de un Negocio aliado a Ganecom, rogamos a nuestros usuarios que nos lo comuniquen para investigarlo y llegado el caso, sacar su referencia de nuestro sitio web.</p>
            <p className='mb-4'>De igual forma, en caso de que el Usuario nos comunique cualquier incidencia, o tenga conocimiento directamente, de situaciones que provoquen incumplimientos por parte del Negocio aliado a Ganecom se dirigirá al mismo por vía amistosa para tratar de mediar en la situación a favor del Usuario.</p>
            <p className='mb-4'>Ganecom es un servicio a la que tú perteneces y en la que participas como usuario de dicha entidad. Por eso, como usuario, al hacer uso del Sitio web consientes que Ganecom intercambie información con dicha entidad, con el fin de que ésta tenga conocimiento estadístico a través del propio Sitio de los miembros que se han beneficiado de los referidos servicios. </p>
            <h3 className='font-bold text-xl'>Obligaciones del usuario</h3>
            <p className='mb-4'>El Usuario se compromete a utilizar el contenido facilitado en el Sitio web de conforme a las presentes condiciones.</p>
            <p className='mb-4'>El usuario se compromete a utilizar de manera adecuada los servicios y contenidos facilitados en el Sitio web y a no emplearlos para realizar actividades ilícitas o constitutivas de delito, que atenten contra los derechos de terceros y/o que infrinjan la regulación sobre propiedad intelectual e industrial, o cualesquiera otras normas del ordenamiento jurídico aplicable.</p>
            <h3 className='font-bold text-xl'>Baja voluntaria del usuario</h3>
            <p className='mb-4'>Si llegado un momento el usuario desea darse de baja de HYPERLINK "http://www.ganecom.com" www.ganecom.com es libre de hacerlo desde la propia plataforma accediendo con su correo y contraseña ("Modificar mis datos de usuario").</p>
            <h3 className='font-bold text-xl'>Protección de datos</h3>
            <p className='mb-4'>
              Los datos de carácter personal que el usuario facilite para el registro se tratan conforme y a las finalidades establecidas en la
              <a className='underline' href='/politica-de-privacidad'>
                política de privacidad
              </a>
            </p>
            <p className='mb-4'> Lea con atención dicho documento antes de proceder al registro en el Sitio.</p>

            <h3 className='font-bold text-xl'>Derecho de exclusión</h3>
            <p className='mb-4'>Ganecom se reserva el derecho a retirar o denegar el acceso al Sitio web, sin previo aviso, a aquellos usuarios que no cumplan las presentes condiciones.</p>
            <h3 className='font-bold text-xl'>Atención con el usuario</h3>
            <p className='mb-4'>En el Sitio web se pone a disposición del usuario un formulario para las quejas, reclamaciones y dudas que pueda tener este.</p>
            <h3 className='font-bold text-xl'>Propiedad intelectual y propiedad industrial</h3>
            <p className='mb-4'>El presente sitio se rige por la legislación Peruana en materia de propiedad intelectual e industrial.</p>
            <p className='mb-4'>El contenido mostrado en el Sitio se encuentra protegido por derechos de autor. Los textos, dibujos, fotografías y demás elementos audiovisuales contenidos en el Sitio web son titularidad exclusiva de Ganecom.</p>
            <p className='mb-4'>A Ganecom le corresponde el exclusivo ejercicio de los derechos de explotación del sitio web y sus contenidos, en especial, los derechos de reproducción, distribución, comunicación pública y transformación.</p>
            <p className='mb-4'>Ganecom no cede ningún derecho de reproducción, distribución, comunicación pública o transformación sobre el sitio web, su diseño o sus contenidos. Queda prohibido cualquier uso del sitio no acorde con su finalidad.</p>
            <p className='mb-4'>Los signos distintivos utilizados en el portal son titularidad de Ganecom y posee la correspondiente licencia o autorización sobre dichos signos para usarlos en el portal. No está permitida su utilización sin autorización previa y por escrito de Ganecom.</p>
            <p className='mb-4'>El acceso o navegación por el Sitio Web no implica, en ningún caso, una renuncia, transmisión, licencia o cesión de los signos distintivos de Ganecom o de terceros, (para los cuales cuenta con la oportuna autorización), a favor de otros.</p>
            <p className='mb-4'>No está permitido suprimir, manipular o eludir las presentes condiciones generales en lo relativo a los derechos de propiedad intelectual e industrial titularidad de Ganecom y cualesquiera otros datos de identificación de la titularidad de estos derechos incorporados a los contenidos, así como los dispositivos técnicos de protección, las marcas digitales u otros mecanismos de información o identificación que contengan los contenidos.</p>
            <p className='mb-4'>Está prohibido modificar, copiar, reutilizar, explotar, reproducir, comunicar públicamente, cargar archivos, enviar por correo, transmitir, usar, tratar o distribuir de cualquier forma la totalidad o parte de los contenidos incluidos en este sitio web para propósitos públicos o comerciales, si no cuenta con la autorización expresa y por escrito de Ganecom a exepsion de los iconos de transmisión de publicidad dentro del sitio web.</p>
            <p className='mb-4'>El uso no autorizado de los contenidos incluidos en este sitio web y, con carácter general, la lesión de los derechos de propiedad intelectual o industrial de Ganecom derivará en responsabilidades legales contra el infractor.</p>

            <h3 className='font-bold text-xl'>Ganecom no será responsable por la información revelada, en los siguientes casos:</h3>
            <p className='mb-4'>a) En los casos en que, en virtud de órdenes judiciales, o de regulaciones legales, nos veamos compelidos a revelar información a las autoridades o terceras partes bajo ciertas circunstancias especiales.</p>
            <p className='mb-4'>b) En los casos que terceras personas puedan interceptar o acceder a cierta información o transmisiones de datos de manera indebida (vulneración del sistema).</p>

            <h3 className='font-bold text-xl'>Garantía y responsabilidad</h3>
            <p className='mb-4'>Respecto a los servicios contratados por el usuario al tratarse de una relación comercial directa entre el Negocio y el Usuario , Ganecom no se hace responsable de los posibles incumplimientos o incidencias que incurra cualquier Negocio , sus productos o servicios con los Usuarios. Cada Negocio dispone de sus propias Politicas de venta, por lo que recomendamos encarecidamente la lectura atenta de ellas antes de proceder a realizar cualquier operación. En el caso de que los cambios efectuados por los Negocio aliados a Ganecom a la hora de solicitar los descuentos, por falta de plazas o stock de los productos o servicios, resulten diferentes a los ofertados a través de Ganecom este no se responsabiliza de estas variaciones.</p>
            <p className='mb-4'>Las incidencias relacionadas, directa o indirectamente, con un mal funcionamiento del servidor, Internet o correo electrónico, así como posibles manipulaciones o actuaciones ilícitas por parte de los usuarios o empleados, Ganecom no se responsabilizará de las mismas.</p>
            <p className='mb-4'>En caso de producirse por parte del usuario desistimiento de la compra o servicio contratado con el Negocio, Ganecom no acumulará importe alguno correspondiente en la plataforma del usuario.</p>

            <h3 className='font-bold text-xl'>Respecto al uso del sitio web HYPERLINK "http://www.ganecom.com" www.ganecom.com</h3>
            <p className='mb-4'>En caso de existir fallos de accesibilidad Ganecom llevará a cabo todas aquellas acciones tendentes a subsanarlos, aunque no garantiza la inexistencia de errores ni la disponibilidad absoluta y continúa del Sitio, que puede verse alterada por labores de mantenimiento y mejora.</p>
            <p className='mb-4'>Ganecom no se hace responsable de los daños que puedan causarse en el equipo informático del usuario o los ficheros o datos almacenados en el mismo, consecuencia de virus o cualquier tipo de código malicioso (malware), así como de la utilización inadecuada del dispositivo informático del usuario o el acceso con herramientas inapropiadas al Sitio Web.</p>
            <p className='mb-4'>El acceso al sitio web y el uso no consentido que se haga de la información contenida en él es responsabilidad exclusiva de quien lo lleva a cabo. Ganecom no se responsabiliza ni responde de ningún daño o perjuicio derivado de dicho uso o acceso.</p>
            <p className='mb-4'>Ganecom no se responsabiliza de posibles opiniones vertidas en páginas o foros de opinión ajenos a Ganecom que, refiriéndose a los servicios de la web y a otras empresas, puedan suponer un descrédito o menosprecio directo o indirecto a aquellas otras empresas.</p>
            <p className='mb-4'>Ganecom tampoco es responsable de los comentarios o contenidos que generen los usuarios haciendo uso de las herramientas puestas a su disposición en Ganecom. No obstante, de tenerse conocimiento por parte de la empresa de que alguno de esos comentarios puede perjudicar o denigrar de manera alguna a terceros, la empresa se reserva el derecho a retirarlos del sitio web.</p>
            <p className='mb-4'>En este sitio web el usuario puede encontrar enlaces a otras páginas web, principalmente de Negocios aliados a Ganecom, mediante banners, links u otro tipo de botones que son gestionados por terceros. Ganecom no tiene facultad para controlar los contenidos facilitados por otros sitios Web a los que se establecen enlaces desde este Sitio. Por lo tanto, Ganecom no asume responsabilidad alguna sobre cualquier aspecto relativo a dicha página Web a la que se enlaza desde este Sitio Web ni garantiza que dichos enlaces puedan estar exentos de virus u otros elementos que puedan producir daños en los sistemas informáticos o archivos del usuario.</p>
            <p className='mb-4'>Asimismo tampoco asume responsabilidad alguna por los contenidos de las páginas Web que puedan dirigir enlaces hacia este Sitio web o hacer referencia a el mismo que incluyan opiniones sobre el Sitio de Ganecom que puedan inducir a error a los usuarios de dichas páginas Web acerca de los servicios de Ganecom. Si cualquier persona Natural o Jurídica pretende establecer algún tipo de enlace con este Sitio  web deberá cumplir los siguientes requisitos:</p>
            <p className='mb-4'>El enlace se podrá dirigir al Índex de esta página</p>
            <p className='mb-4'>El enlace debe conducir directamente a la URL de este Sitio Web. Salvo que Ganecom lo autorice expresamente, el sitio Web que realiza el enlace no podrá incluir contenido de Ganecom como parte su Web o dentro de sus frames o crear un browser sobre cualquiera de las páginas de Ganecom.</p>
            <p className='mb-4'>Si la página Web desde la que se realiza el enlace pretende incorporar en su página Web cualquier signo distintivo titularidad de Ganecom, debe contar previamente con la autorización expresa de la empresa titular de este Sitio web. Ganecom no autoriza el establecimiento de enlaces a este sitio web desde páginas web que contengan contenidos ilícitos.</p>

            <h3 className='font-bold text-xl'>Ley aplicable y jurisdicción</h3>
            <p className='mb-4'>Las presentes condiciones generales se rigen por la legislación Peruana y cualquier controversia que pudiera suscitarse sobre la aplicación, cumplimiento o interpretación de estas condiciones, se resolvería, por la aceptación expresa de ambas partes, en los Juzgados y Tribunales de la ciudad de Tacna.</p>

            <h3 className='font-bold text-xl'>Modificaciones</h3>
            <p className='mb-4'>Ganecom se reserva la facultad de realizar las modificaciones que considere necesarias en el Sitio Web así como actualizaciones de la información contenida en la misma y de la configuración, presentación y condiciones de acceso, sin necesidad de avisar con carácter previo.</p>
            <p className='mb-4'>Su navegador de Internet puede generar una copia de esta página en la memoria caché de manera que, al acceder de nuevo a la misma, las páginas mostradas por su navegador no se correspondan con las más actuales sino con las almacenadas en la memoria caché. Con el fin de evitar estos problemas le recomendamos que actualice el Sitio en su navegador cuando acceda al mismo. </p>

            <h3 className='font-bold text-xl'>Cookies.</h3>
            <p className='mb-4'>El usuario y el visitante del sitio web, conoce y acepta que la mencionada, podrá utilizar un sistema de seguimiento mediante la utilización de cookies.</p>
            <p className='mb-4'>Las Cookies se utilizan con el fin de conocer los intereses, el comportamiento y la demografía de quienes visitan o son usuarios de nuestra plataforma y de esa forma, comprender mejor sus necesidades e intereses y darles un mejor servicio o proveerle información relacionada. También usaremos la información obtenida por intermedio de las Cookies para analizar las páginas navegadas por el visitante o usuario, las búsquedas realizadas, mejorar nuestras iniciativas comerciales y promocionales, mostrar publicidad o promociones, banners de interés, noticias sobre Ganecom, perfeccionar nuestra oferta de contenidos y artículos, personalizar dichos contenidos, presentación y servicio.</p>
            <p className='mb-4'>De igual manera podremos utilizar las Cookies para promover y hacer cumplir las reglas y seguridad del sitio.</p>
            <p className='mb-4'>Ganecom podrá agregar Cookies en los e-mails que envíe para medir la efectividad de las promociones.</p>
            <p className='mb-4'>Adicionalmente, se usaran las Cookies para que el usuario no tenga que introducir su clave de manera frecuente durante una sesión de navegación, para contabilizar y corroborar los registros, la actividad del usuario y otros conceptos siempre teniendo como objetivo el beneficio del usuario.</p>
            <p className='mb-4'>Se establece que la instalación, permanencia y existencia de las Cookies en el computador del usuario depende de su exclusiva voluntad y puede ser eliminada de su computador cuando el usuario así lo desee.</p>
            <p className='mb-4'>Se aclara expresamente que estas políticas cubre la utilización de Cookies por este sitio y no la utilización de Cookies por parte de anunciantes. Nosotros no controlamos el uso de Cookies por terceros.</p>

            <h3 className='font-bold text-xl'>Procedimiento para reportar registros indebidos.</h3>
            <p className='mb-4'>En los casos en que una persona natural intente registrarse en HYPERLINK "http://www.ganecom.com" www.ganecom.com y se encuentre con que ya existe un registro a su nombre el cual no ha sido realizado por el mismo, podrá reportar dicho incidente a fin de que se de baja el registro indebido si fuese el caso.</p>
            <p className='mb-4'>Para esto será necesario llenar un formulario informativo, con el cual reportara sobre el incidente a nuestras áreas pertinentes. Posteriormente, recibirá un correo informándole sobre el inicio del procedimiento.</p>
            <p className='mb-4'>Una vez recibido el correo mencionado, el agraviado tendrá 30 días calendario para hacernos llegar a la dirección de correo de este sitio web, los siguientes documentos:</p>
            <p className='mb-4'>1 Copia de DNI.</p>
            <p className='mb-4'>2 Declaración jurada (conforme al formato adjunto) con firma legalizada ante notario público.</p>
            <p className='mb-4'>3 Una vez recibido los mencionados documentos, procederemos a dar de baja el registro indebido, siendo posible al agraviado registrarse en HYPERLINK "http://www.ganecom.com" www.ganecom.com .</p>
            <p className='mb-4'>4 Con la baja de un registro indebido o falso, se pierde toda la información descuentos y ganancias acumuladas y demás características, no siendo posible la recuperación del mismo.</p>

            <h3 className='font-bold text-xl'>Responsabilidad de Ganecom </h3>
            <p className='mb-4'>Ganecom no es responsable por la información falsa o errónea que sus usuarios brinden, esto en base a que nos basamos en el principio de buena fe al momento de realizar los registros a nuestro sitio web.</p>

            <br />
            <i className='text-center'>
              Ganecom <strong>"gane comprando, gane compartiendo"</strong>
            </i>
          </div>
        </div>
      </section>
    </>
  )
}

export default Tos
